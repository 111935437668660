import BaseListPresenter from "../../base/BaseListPresenter";

class CreditInvestigatePresenter extends BaseListPresenter {
  init() {
    const user = this.view.getCurrentUser();
    this.limit = 20;
    this.where = {
      agent: user.name,
      status: { $in: ["Dispatched", "Pending"] },
    };
    this.search = {};
    this.filter = {};
    this.include = ["all"];
    this.keys = undefined; // if keys are specified, only those keys will be returned
    this.sort = { createdAt: -1 };
    this.progress = true;
    this.reset();
  }
  onClickAdd() {
    this.view.navigateTo("/endorsement-form");
  }

  onClickItem(index) {
    const object = this.objects[index];
    this.view.navigateTo("/endorsement-form/" + object.id);
  }

  onClickView(index, field) {
    const object = this.objects[index];
    this.view.navigateTo("/endorsement-form-view/" + object.id);
  }

  onClickItemAudit(index, field) {
    const object = this.objects[index];
    console.log("get object", object);

    switch (object.rType) {
      case "EMPLOYMENT VERIFICATION (EVR)":
        this.view.navigateTo(
          "/audit-form-e/" + object.id + "/" + index + "/" + object.auditId
        );
        break;
      case "BUSINESS VERIFICATION (BVR)":
        this.view.navigateTo(
          "/audit-form/" + object.id + "/" + index + "/" + object.auditId
        );
        break;
      case "RESIDENCE VERIFICATION (RVR)":
        this.view.navigateTo(
          "/audit-form-r/" + object.id + "/" + index + "/" + object.auditId
        );
        break;

      default:
        break;
    }
    // this.view.navigateTo(
    //   "/audit-form/" + object.id + "/" + index + "/" + object.auditId
    // );
  }
}

export default CreditInvestigatePresenter;
