import BaseListPresenter from "../../../base/BaseListPresenter";

class BusinessAuditPresenter extends BaseListPresenter {
  onClickAdd() {
    this.view.navigateTo("/business-audit-form");
  }

  onClickItem(index) {
    const object = this.objects[index];
    this.view.navigateTo("/business-audit-form/" + object.id);
  }
}

export default BusinessAuditPresenter;
