import BaseListPresenter from "../../base/BaseListPresenter";

class QualityAssurancePresenter extends BaseListPresenter {
  init() {
    const user = this.view.getCurrentUser();
    this.limit = 20;
    this.where = { agent: user.name, status: "On going" };
    this.search = {};
    this.filter = {};
    this.include = ["all"];
    this.keys = undefined; // if keys are specified, only those keys will be returned
    this.sort = { createdAt: -1 };
    this.progress = true;
    this.reset();
  }
  onClickAdd() {
    this.view.navigateTo("/endorsement-form");
  }

  onClickItem(index) {
    const object = this.objects[index];
    this.view.navigateTo("/endorsement-form/" + object.id);
  }

  onClickView(index, field) {
    const object = this.objects[index];
    this.view.navigateTo("/endorsement-form-view/" + object.id);
  }

  // onClickItemAudit(index, field) {
  //   const object = this.objects[index];

  //   switch (object.rType) {
  //     case "EMPLOYEE VERIFICATION (EVR)":
  //       this.view.navigateTo(
  //         "/audit-form-ci-2/" + object.id + "/" + index + "/" + object.auditId
  //       );
  //       break;
  //     case "BUSINESS VERIFICATION (BVR)":
  //       this.view.navigateTo(
  //         "/audit-form-ci/" + object.id + "/" + index + "/" + object.auditId
  //       );
  //       break;
  //     case "RESIDENCE VERIFICATION (RVR)":
  //       this.view.navigateTo(
  //         "/audit-form-ci/" + object.id + "/" + index + "/" + object.auditId
  //       );
  //       break;

  //     default:
  //       break;
  //   }
  // }
  onClickItemAudit(index, field) {
    const object = this.objects[index];
    console.log("get object", object);

    switch (object.rType) {
      case "EMPLOYMENT VERIFICATION (EVR)":
        this.view.navigateTo(
          "/audit-form-ci-evr/" +
            object.id +
            "/" +
            index +
            "/" +
            object.auditId +
            "/" +
            object.qaFormId
        );
        break;
      case "BUSINESS VERIFICATION (BVR)":
        this.view.navigateTo(
          "/audit-form-ci-bvr/" +
            object.id +
            "/" +
            index +
            "/" +
            object.auditId +
            "/" +
            object.qaFormId
        );
        break;
      case "RESIDENCE VERIFICATION (RVR)":
        this.view.navigateTo(
          "/audit-form-ci-rvr/" +
            object.id +
            "/" +
            index +
            "/" +
            object.auditId +
            "/" +
            object.qaFormId
        );
        break;

      default:
        break;
    }
    // this.view.navigateTo(
    //   "/audit-form/" + object.id + "/" + index + "/" + object.auditId
    // );
  }
}

export default QualityAssurancePresenter;
