import BaseListPresenter from "../../base/BaseListPresenter";

class DispatchPresenter extends BaseListPresenter {
  // init() {
  //   const user = this.view.getCurrentUser();
  //   console.log("user user", user);
  //   const endorsementIds = user.regions?.map((e) => e.name);
  //   const endorsementIds2 = user.provinces?.map((e) => e.name);

  //   this.limit = 20;
  //   this.where = {
  //     status: "For Dispatch",
  //     region: { $in: endorsementIds },
  //     province: { $in: endorsementIds2 },
  //   };
  //   // this.where = {};
  //   this.search = {};
  //   this.filter = {};
  //   this.include = ["all"];
  //   this.keys = undefined; // if keys are specified, only those keys will be returned
  //   this.sort = { createdAt: -1 };
  //   this.progress = true;
  //   this.reset();
  // }
  // init() {
  //   const user = this.view.getCurrentUser();
  //   console.log("user user", user);
  //   const endorsementIds = user.regions?.map((e) => e.name) || [];
  //   const endorsementIds2 = user.provinces?.map((e) => e.name) || [];

  //   this.limit = 20;

  //   // Initialize an empty where clause
  //   this.where = { status: "For Dispatch" };

  //   if (endorsementIds.length > 0) {
  //     this.where.region = { $in: endorsementIds };
  //   }

  //   if (endorsementIds2.length > 0) {
  //     this.where.province = { $in: endorsementIds2 };
  //   }

  //   this.search = {};
  //   this.filter = {};
  //   this.include = ["all"];
  //   this.keys = undefined; // if keys are specified, only those keys will be returned
  //   this.sort = { createdAt: -1 };
  //   this.progress = true;
  //   this.reset();
  // }
  init() {
    const user = this.view.getCurrentUser();
    console.log("user user", user);
    const endorsementIds = user.regions?.map((e) => e.name) || [];
    const endorsementIds2 = user.provinces?.map((e) => e.name) || [];

    this.limit = 20;

    // Initialize an empty where clause
    this.where = { status: "For Dispatch" };

    // Add region condition if endorsementIds is not empty
    if (endorsementIds.length > 0) {
      this.where.$or = [{ region: { $in: endorsementIds } }];
    }

    // Add province condition if endorsementIds2 is not empty
    if (endorsementIds2.length > 0) {
      if (!this.where.$or) {
        this.where.$or = [];
      }
      this.where.$or.push({ province: { $in: endorsementIds2 } });
    }

    this.search = {};
    this.filter = {};
    this.include = ["all"];
    this.keys = undefined; // if keys are specified, only those keys will be returned
    this.sort = { createdAt: -1 };
    this.progress = true;
    this.reset();
  }

  onClickAdd() {
    this.view.navigateTo("/endorsement-form");
  }

  onClickItem(index) {
    const object = this.objects[index];
    this.view.navigateTo("/endorsement-form/" + object.id);
  }
}

export default DispatchPresenter;
