import React from 'react';

const defaultProps = {}

function SignatureCanvas({setRef}) {

    React.useEffect(() => {
        const canvas = setRef.current;
        const ctx = canvas.getContext('2d');
        let isDrawing = false;

        let lastX = 0;
        let lastY = 0;


        function draw(x, y) {
            if (!isDrawing) return;
            // Set line dash to a solid line
            // ctx.setLineDash([]);
            // Draw a quadratic Bezier curve
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(lastX, lastY);
            const cpX = (lastX + x) / 2; // Control point X
            const cpY = (lastY + y) / 2; // Control point Y
            // ctx.quadraticCurveTo(lastX, lastY, cpX, cpY);
            ctx.lineTo(x, y);
            ctx.stroke();
            lastX = x;
            lastY = y;
        }

        function handlePointerDown(e) {
            isDrawing = true;
            const {clientX, clientY} = e;
            const {left, top} = canvas.getBoundingClientRect();
            lastX = clientX - left;
            lastY = clientY - top;
        }

        function handlePointerMove(e) {
            e.preventDefault();
            draw(e.clientX - canvas.getBoundingClientRect().left, e.clientY - canvas.getBoundingClientRect().top);
        }

        function handlePointerUp(e) {
            isDrawing = false;
        }

        canvas.addEventListener('pointerdown', handlePointerDown);
        canvas.addEventListener('pointermove', handlePointerMove);
        canvas.addEventListener('pointerup', handlePointerUp);
    }, []);

    function handleClear() {
        const canvas = setRef.current;
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);
    }

    function handleResize() {
        const canvas = setRef.current;
        const container = canvas.parentNode;
        canvas.width = container.offsetWidth;
        canvas.height = container.offsetHeight;
    }

    function handleSave() {
        const canvas = setRef.current;
        const link = document.createElement('a');
        link.download = 'signature.png';
        link.href = canvas.toDataURL('image/png');
        link.click();
    }

    React.useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const defaultStyle = {
        touchAction: 'none',
        userSelect: 'none',
        position: 'relative',
        width: '100%',
        height: '0',
        paddingTop: '50%'
    }
    return (
        <div style={defaultStyle}>
            <canvas
                ref={setRef}
                style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}
                onContextMenu={(e) => e.preventDefault()}
            />
        </div>
    );
}


SignatureCanvas.defaultProps = defaultProps;
export default SignatureCanvas;