import BaseFormPresenter from "../../../../base/BaseFormPresenter";
import {
  findObjectUseCase,
  saveObjectUseCase,
  upsertUseCase,
} from "../../../../usecases/object";

class QAComparisonBVRPresenter extends BaseFormPresenter {
  onChangeObject(object) {
    this.change = object;
    this.object = object;
  }

  componentDidMount() {
    this.init();
    this.getObject();
    this.getAnswer();
  }

  async getObject() {
    const collection = this.view.getCollectionName();
    const id = this.view.getParams().formId;
    if (id) {
      const params = {
        include: [
          "selectedClient",
          "categories",
          "categories.questions",
          "categories.questions.choices",
          "uploadRelation",
        ],
      };
      try {
        this.view.showProgress();
        this.object = await this.getObjectUseCase.execute(collection, id, {
          params,
        });
        this.view.hideProgress();
        // const answer = { answers: [] };
        // answer.form = { id: this.object.id };
        // console.log("answer", answer);
        // this.view.setAnswer(answer);
        console.log("get formssss", this.object);
        this.view.setObject(this.object);
      } catch (error) {
        this.view.hideProgress();
        this.view.showError(error);
      }
    }
  }

  async getAnswer() {
    const collectionMIS = "bvr_forms_answer_mis";
    const collectionQA = "bvr_forms_answer_qa";
    const id = this.view.getParams().id;
    if (id) {
      const params = {
        include: ["form", "answers", "answers.choices", "uploads"],
      };
      try {
        this.view.showProgress();
        const agentAnswer = await this.getObjectUseCase.execute(
          collectionMIS,
          id,
          {
            params,
          }
        );
        const qaAnswer = await this.getObjectUseCase.execute(collectionQA, id, {
          params,
        });
        this.view.hideProgress();
        console.log("ANSWER AGENT", agentAnswer);
        console.log("ANSWER QA", qaAnswer);
        this.view.setAgentAnswer(agentAnswer);
        this.view.setQaAnswer(qaAnswer);
      } catch (error) {
        this.view.hideProgress();
        this.view.showError(error);
      }
    }
  }
  async getObjectQA() {
    const collection = "bvr_forms_answer_qa";
    // const id = this.object.id || this.view.getObjectId();
    const id = this.view.getParams().id;
    console.log("params", id);
    if (id) {
      const params = {
        // include: [
        //   "categories",
        //   "categories.text",
        //   "questions",
        //   "questions.question_per_category",
        //   "questions.question_per_category.choices",
        //   "upload",
        // ],
        // include: [
        //   // "selectedClient",

        //   "categories",
        //   "categories.questions",
        //   "categories.questions.choices",
        //   "uploadRelation",
        // ],
        include: ["form", "answers", "answers.choices", "uploads"],
      };
      try {
        this.view.showProgress();
        this.object = await this.getObjectUseCase.execute(collection, id, {
          params,
        });
        this.view.hideProgress();
        this.view.setQaAnswer(this.object);
      } catch (error) {
        this.view.hideProgress();
        this.view.showError(error);
      }
    }
  }

  async onClickStatus(status) {
    const url = this.view.getParams();
    const user = this.view.getCurrentUser();

    const parts = url["*"].split("/");
    const secondPart = parts[1];
    const indexPart = parts[2];
    const query = {
      where: {
        id: secondPart,
      },
    };
    const getEndorsementById = await findObjectUseCase().execute(
      "endorsement",
      query
    );

    const object = getEndorsementById[indexPart];

    object.statusQA = status;

    if (status === "Approved") {
      const queryUser = {
        where: {
          name: object.agent,
        },
      };
      const findAgent = await findObjectUseCase().execute("users", queryUser);
      const findAgentIndex = findAgent[0];

      const dateTimeSubmitted = new Date(object.dateTimeSubmitted);
      const timeCompleted = this.convertToDateTime(
        object.timeCompleted,
        dateTimeSubmitted
      );
      // console.log("dts", dateTimeSubmitted, "tc", timeCompleted);

      if (dateTimeSubmitted < timeCompleted) {
        // findAgentIndex.rank = (findAgentIndex.rank || 0) + 1; // Add points, initialize if undefined
        findAgentIndex.countPoints = findAgentIndex.countPoints || [];
        findAgentIndex.countPoints.push({
          countPoint: 1,
          countEndorserment: 1,
          // createdAt: currentDate,
        });
        // findAgentIndex.countPoints =
        //   (findAgentIndex.numberEndorsement || 0) + 1;
      } else {
        findAgentIndex.countPoints = findAgentIndex.countPoints || 0; // Add points, initialize if undefined
        findAgentIndex.countPoints.push({
          countPoint: 0.5,
          countEndorserment: 1,
          // createdAt: currentDate,
        });
      }
      // console.log("findAgent", findAgent);
      await upsertUseCase().execute("users", findAgentIndex);
    }

    object.reason = this.view.state.reason || "";

    await upsertUseCase().execute("endorsement", object);
    // this.view.submissionSuccess();
    // this.view.showSuccessSnackbar("Successfully saved!");
    this.view.navigateBack();
  }

  convertToDateTime(timeCompleted, referenceDate) {
    const { hour, minute, period } = timeCompleted;

    let hours = parseInt(hour, 10);
    if (period === "PM" && hours !== 12) {
      hours += 12;
    } else if (period === "AM" && hours === 12) {
      hours = 0;
    }

    const completedDate = new Date(referenceDate);
    completedDate.setHours(hours);
    completedDate.setMinutes(parseInt(minute, 10));
    completedDate.setSeconds(0);
    completedDate.setMilliseconds(0);

    return completedDate;
  }

  async save() {
    const collectionEndorsement = "endorsement";
    const object = this.view.state.object;
    const url = this.view.getParams();
    const parts = url["*"].split("/");
    console.log("parts", parts);
    const secondPart = parts[1];
    const indexPart = parts[2];
    const query = {
      where: {
        id: secondPart,
      },
    };

    const findEndorsement = await findObjectUseCase().execute(
      collectionEndorsement,
      query
    );

    let endorsementQA = findEndorsement[indexPart];

    if ("Approved" === "Approved") {
      const queryUser = {
        where: {
          name: endorsementQA.agent,
        },
      };
      const findAgent = await findObjectUseCase().execute("users", queryUser);
      const findAgentIndex = findAgent[0];

      const dateTimeSubmitted = new Date(object.dateTimeSubmitted);
      const timeCompleted = this.convertToDateTime(
        endorsementQA.timeCompleted,
        dateTimeSubmitted
      );

      if (dateTimeSubmitted < timeCompleted) {
        // findAgentIndex.rank = (findAgentIndex.rank || 0) + 1; // Add points, initialize if undefined
        findAgentIndex.countPoints = findAgentIndex.countPoints || [];
        findAgentIndex.countPoints.push({
          countPoint: 1,
          countEndorserment: 1,
          // createdAt: currentDate,
        });
        // findAgentIndex.countPoints =
        //   (findAgentIndex.numberEndorsement || 0) + 1;
      } else {
        findAgentIndex.countPoints = findAgentIndex.countPoints || 0; // Add points, initialize if undefined
        findAgentIndex.countPoints.push({
          countPoint: 0.5,
          countEndorserment: 1,
          // createdAt: currentDate,
        });
      }
      console.log("findAgent", findAgentIndex);
      await upsertUseCase().execute("users", findAgentIndex);
    }

    endorsementQA.statusQA = "Approved";

    console.log("BVR_QA : ", object);
    console.log("Collection Endorsement : ", endorsementQA);

    try {
      await saveObjectUseCase().execute(
        "business_verification_form_qa",
        object
      );
      await this.upsertUseCase.execute(collectionEndorsement, endorsementQA);
      //   await this.upsertUseCase.execute(collectionUsers, userForQA);
      this.change = {};
    } catch (error) {
      throw error; // rethrow the error to be caught by the caller
    }
  }

  async submit() {
    // if (Object.values(this.change).length === 0) {
    //   this.view.showSuccessSnackbar("Successfully saved!");
    //   return;
    // }
    try {
      this.view.submitting();
      await this.save();
      this.view.submissionSuccess();
      this.view.showSuccessSnackbar("Successfully saved!");
    } catch (error) {
      this.view.submissionError(error);
      this.view.showError(error);
    }
  }
}

export default QAComparisonBVRPresenter;
