import React, { Component } from "react";
import BaseListPresenter from "../../../base/BaseListPresenter";

class EmploymentAuditPresenter extends BaseListPresenter {
  onClickAdd() {
    const collection = this.view.getCollectionName();
    this.view.navigateTo("/employment-audit-form");
  }

  onClickItem(index) {
    const object = this.objects[index];
    const collection = this.view.getCollectionName();
    console.log("path", object);
    this.view.navigateTo("/employment-audit-form/" + object.id);
  }
}

export default EmploymentAuditPresenter;
