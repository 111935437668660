// import React from "react";
// import InputSelect from "../InputSelect";

// const newCategoryData = {
//   questionType: "",
//   header: "", // Added header field
//   question_per_category: [],
// };

// export default function BusinessQuestionList({ object, setObjectState }) {
//   console.log("object structue : ", object);
//   function onAddCategory(e) {
//     e.preventDefault();
//     const newCategories = Array.isArray(object?.questions)
//       ? [...object.questions]
//       : [];
//     const newCategory = {
//       ...newCategoryData,
//       question_per_category: [],
//     };
//     newCategories.push(newCategory);
//     const updatedExam = { ...object, questions: newCategories };
//     setObjectState(updatedExam);
//   }

//   function onDeleteCategory(categoryIndex) {
//     const updatedCategories = [...object.questions];
//     if (updatedCategories[categoryIndex].id) {
//       alert(updatedCategories[categoryIndex].id);
//       updatedCategories.splice(categoryIndex, 1);
//     } else {
//       updatedCategories[categoryIndex].__operation = "REMOVE";
//       alert("hi");
//     }

//     const updatedExam = { ...object, questions: updatedCategories };
//     setObjectState(updatedExam);
//   }

//   function onAddQuestion(categoryIndex) {
//     const newCategories = [...object.questions];
//     if (!newCategories[categoryIndex].question_per_category) {
//       newCategories[categoryIndex].question_per_category = [];
//     }
//     newCategories[categoryIndex].question_per_category.push({
//       perCategory: "",
//       choices: [{ name: "" }],
//     });
//     const updatedExam = {
//       ...object,
//       questions: newCategories,
//     };
//     setObjectState(updatedExam);
//   }

//   function onDeleteQuestion(categoryIndex, questionIndex) {
//     const newQuestions = [...object.questions];
//     if (newQuestions[categoryIndex].question_per_category[questionIndex].id) {
//       newQuestions[categoryIndex].question_per_category.splice(
//         questionIndex,
//         1
//       );
//     } else {
//       newQuestions[categoryIndex].question_per_category[
//         questionIndex
//       ].__operation = "REMOVE";
//     }

//     const updatedExam = { ...object, questions: newQuestions };
//     setObjectState(updatedExam);
//   }

//   function handleQuestionChange(categoryIndex, questionIndex, event) {
//     const { value } = event.target;
//     const newQuestions = [...object.questions];

//     if (
//       newQuestions[categoryIndex].question_per_category &&
//       newQuestions[categoryIndex].question_per_category[questionIndex]
//     ) {
//       newQuestions[categoryIndex].question_per_category[
//         questionIndex
//       ].perCategory = value;
//     } else {
//       console.error("Invalid category or question index");
//     }

//     const updatedExam = { ...object, questions: newQuestions };
//     setObjectState(updatedExam);
//   }

//   function handleHeaderChange(categoryIndex, event) {
//     const { value } = event.target;
//     const newCategories = [...object.questions];
//     newCategories[categoryIndex].header = value;
//     const updatedExam = { ...object, questions: newCategories };
//     setObjectState(updatedExam);
//   }

//   function onChangeType(categoryIndex, option) {
//     const newCategories = [...object.questions];
//     newCategories[categoryIndex].questionType = option;
//     const updatedExam = { ...object, questions: newCategories };
//     setObjectState(updatedExam);
//   }

//   function onAddAnswer(categoryIndex, questionIndex) {
//     const newQuestions = [...object.questions];
//     const question =
//       newQuestions[categoryIndex].question_per_category[questionIndex];

//     question.choices.push({
//       name: "",
//     });

//     const updatedExam = {
//       ...object,
//       questions: newQuestions,
//     };
//     setObjectState(updatedExam);
//   }

//   function handleAnswerChange(
//     categoryIndex,
//     questionIndex,
//     answerIndex,
//     event
//   ) {
//     const value = event.target.value;
//     const newQuestions = JSON.parse(JSON.stringify(object.questions));

//     if (
//       !newQuestions[categoryIndex].question_per_category[questionIndex].choices
//     ) {
//       newQuestions[categoryIndex].question_per_category[questionIndex].choices =
//         [];
//     }

//     if (
//       newQuestions[categoryIndex].question_per_category[questionIndex].choices
//         .length > answerIndex
//     ) {
//       newQuestions[categoryIndex].question_per_category[questionIndex].choices[
//         answerIndex
//       ].name = value;
//     } else {
//       newQuestions[categoryIndex].question_per_category[
//         questionIndex
//       ].choices.push({ name: value });
//     }

//     const updatedExam = {
//       ...object,
//       questions: newQuestions,
//     };

//     setObjectState(updatedExam);
//   }

//   function handleQuestionChangeYes(category_index, e) {
//     const { value } = e.target;
//     const newCategories = [...object.questions];
//     newCategories[category_index].questionYes = value;
//     const updatedExam = {
//       ...object,
//       questions: newCategories,
//     };
//     setObjectState(updatedExam);
//   }

//   return (
//     <div className="row g-3 mb-3">
//       <div>
//         <hr className="dropdown-divider" />
//       </div>

//       {object?.questions?.map((category, category_index) => {
//         return (
//           <React.Fragment key={category_index}>
//             {!category.__operation && (
//               <div className="p-4 border shadow">
//                 <div>
//                   <p className="small fw-bold mb-0 ms-1">Form Categories</p>
//                   <hr className="dropdown-divider" />
//                 </div>
//                 <div className="col-md-12 row">
//                   <div className="col-md-4 mb-3">
//                     <label className="form-label fs-sm">Header</label>{" "}
//                     <input
//                       type="text"
//                       className="form-control"
//                       placeholder={`Header ${category_index + 1}`}
//                       defaultValue={category.header}
//                       onChange={(e) => handleHeaderChange(category_index, e)}
//                     />
//                   </div>
//                   <div className="col-md-4 mb-3">
//                     <label className="form-label fs-sm">Question Type</label>{" "}
//                     <span
//                       className="ms-2"
//                       role="button"
//                       onClick={() => onDeleteCategory(category_index)}
//                     >
//                       <i className="bi bi-trash text-danger"></i>
//                     </span>
//                     <InputSelect
//                       placeholder="Select Question Category"
//                       options={[
//                         "MULTIPLE CHOICE",
//                         "YES OR NO",
//                         "LABEL",
//                         "INFORMANTS",
//                       ]}
//                       defaultValue={category.questionType}
//                       onChange={(selectedOption) => {
//                         onChangeType(category_index, selectedOption);
//                       }}
//                     />
//                   </div>
//                   {category.questionType === "YES OR NO" && (
//                     <input
//                       type="text"
//                       className="form-control"
//                       defaultValue={category.questionYes}
//                       placeholder="Question"
//                       onChange={(e) =>
//                         handleQuestionChangeYes(category_index, e)
//                       }
//                       required
//                     />
//                   )}
//                   {category.questionType === "LABEL" && (
//                     <input
//                       type="text"
//                       className="form-control"
//                       defaultValue={category.questionYes}
//                       placeholder="Question"
//                       onChange={(e) =>
//                         handleQuestionChangeYes(category_index, e)
//                       }
//                       required
//                     />
//                   )}
//                 </div>
//                 <div>
//                   {category?.question_per_category?.map(
//                     (question, question_index) =>
//                       !question.__operation && (
//                         <div
//                           key={
//                             question.id ||
//                             `question-${category_index}-${question_index}`
//                           }
//                           className="mt-2 py-2"
//                         >
//                           <div className="col-md-12 row">
//                             <div className="col-md-4">
//                               <label className="form-label d-flex align-items-center gap-2">
//                                 Enter Question
//                                 <span
//                                   role="button"
//                                   onClick={() =>
//                                     onDeleteQuestion(
//                                       category_index,
//                                       question_index
//                                     )
//                                   }
//                                 >
//                                   <i className="bi bi-trash text-danger"></i>
//                                 </span>
//                               </label>

//                               <input
//                                 type="String"
//                                 className="form-control"
//                                 defaultValue={question.perCategory}
//                                 placeholder={
//                                   category.questionType === "MULTIPLE CHOICE"
//                                     ? `Question ${question_index + 1}`
//                                     : "Column"
//                                 }
//                                 onChange={(e) =>
//                                   handleQuestionChange(
//                                     category_index,
//                                     question_index,
//                                     e
//                                   )
//                                 }
//                                 required
//                               />
//                             </div>
//                           </div>

//                           <hr />
//                           {question.choices &&
//                             question.choices.map((answer, answerIndex) => (
//                               <input
//                                 key={answerIndex}
//                                 type="text"
//                                 className="form-control mb-2"
//                                 value={answer.name}
//                                 onChange={(e) =>
//                                   handleAnswerChange(
//                                     category_index,
//                                     question_index,
//                                     answerIndex,
//                                     e
//                                   )
//                                 }
//                                 placeholder={
//                                   category.questionType === "MULTIPLE CHOICE"
//                                     ? "Enter answer here"
//                                     : "Row"
//                                 }
//                               />
//                             ))}
//                           <div key={question_index}>
//                             <div
//                               className="btn btn-sm text-white"
//                               onClick={() =>
//                                 onAddAnswer(category_index, question_index)
//                               }
//                               style={{ backgroundColor: "#003466" }}
//                             >
//                               {category.questionType === "MULTIPLE CHOICE"
//                                 ? "Add Answer"
//                                 : category.questionType === "YES OR NO"
//                                 ? "Add Row"
//                                 : "Add Row"}
//                             </div>
//                           </div>
//                         </div>
//                       )
//                   )}
//                   {category.questionType &&
//                     (category.questionType === "MULTIPLE CHOICE" ? (
//                       <div
//                         className="btn btn-sm text-white me-2"
//                         onClick={() => onAddQuestion(category_index)}
//                         style={{ backgroundColor: "#003466" }}
//                       >
//                         Add Question
//                       </div>
//                     ) : (
//                       <div
//                         className="btn btn-sm text-white me-2 mt-2"
//                         onClick={() => onAddQuestion(category_index)}
//                         style={{ backgroundColor: "#003466" }}
//                       >
//                         Add Column
//                       </div>
//                     ))}
//                 </div>
//               </div>
//             )}
//           </React.Fragment>
//         );
//       })}
//       <button
//         className="btn"
//         onClick={onAddCategory}
//         style={{ backgroundColor: "#dfbe68" }}
//       >
//         Add Categories
//       </button>
//     </div>
//   );
// }

import React from "react";
import InputSelect from "../InputSelect";

const newCategoryData = {
  header: "",
  questionType: "",
  questions: [],
};

export default function BusinessQuestionList({ object, setObjectState }) {
  console.log("object structue : ", object);
  function onAddCategory(e) {
    e.preventDefault();
    const newCategories = Array.isArray(object?.categories)
      ? [...object.categories]
      : [];
    const newCategory = {
      ...newCategoryData,
      questions: [],
    };
    newCategories.push(newCategory);
    const updatedExam = { ...object, categories: newCategories };
    setObjectState(updatedExam);
  }

  function onDeleteCategory(categoryIndex) {
    const updatedCategories = [...object.categories];
    if (updatedCategories[categoryIndex].id) {
      alert(updatedCategories[categoryIndex].id);
      updatedCategories.splice(categoryIndex, 1);
    } else {
      updatedCategories[categoryIndex].__operation = "REMOVE";
    }

    const updatedExam = { ...object, categories: updatedCategories };
    setObjectState(updatedExam);
  }

  function onAddQuestion(categoryIndex) {
    const newCategories = [...object.categories];
    if (!newCategories[categoryIndex].questions) {
      newCategories[categoryIndex].questions = [];
    }
    newCategories[categoryIndex].questions.push({
      question: "",
      choices: [{ text: "" }],
    });
    const updatedExam = {
      ...object,
      categories: newCategories,
    };
    setObjectState(updatedExam);
  }

  function onDeleteQuestion(categoryIndex, questionIndex) {
    const newQuestions = [...object.categories];
    if (newQuestions[categoryIndex].questions[questionIndex].id) {
      newQuestions[categoryIndex].questions.splice(questionIndex, 1);
    } else {
      newQuestions[categoryIndex].questions[questionIndex].__operation =
        "REMOVE";
    }

    const updatedExam = { ...object, categories: newQuestions };
    setObjectState(updatedExam);
  }

  function handleQuestionChange(categoryIndex, questionIndex, event) {
    const { value } = event.target;
    const newQuestions = [...object.categories];

    if (
      newQuestions[categoryIndex].questions &&
      newQuestions[categoryIndex].questions[questionIndex]
    ) {
      newQuestions[categoryIndex].questions[questionIndex].question = value;
    } else {
      console.error("Invalid category or question index");
    }

    const updatedExam = { ...object, categories: newQuestions };
    setObjectState(updatedExam);
  }

  function handleHeaderChange(categoryIndex, event) {
    const { value } = event.target;
    const newCategories = [...object.categories];
    newCategories[categoryIndex].header = value;
    const updatedExam = { ...object, categories: newCategories };
    setObjectState(updatedExam);
  }

  function onChangeType(categoryIndex, option) {
    const newCategories = [...object.categories];
    newCategories[categoryIndex].questionType = option;
    const updatedExam = { ...object, categories: newCategories };
    setObjectState(updatedExam);
  }

  function onAddAnswer(categoryIndex, questionIndex) {
    const newQuestions = [...object.categories];
    const question = newQuestions[categoryIndex].questions[questionIndex];

    question.choices.push({
      text: "",
    });

    const updatedExam = {
      ...object,
      categories: newQuestions,
    };
    setObjectState(updatedExam);
  }

  function handleAnswerChange(
    categoryIndex,
    questionIndex,
    answerIndex,
    event
  ) {
    const value = event.target.value;
    const newQuestions = JSON.parse(JSON.stringify(object.categories));

    if (!newQuestions[categoryIndex].questions[questionIndex].choices) {
      newQuestions[categoryIndex].questions[questionIndex].choices = [];
    }

    if (
      newQuestions[categoryIndex].questions[questionIndex].choices.length >
      answerIndex
    ) {
      newQuestions[categoryIndex].questions[questionIndex].choices[
        answerIndex
      ].text = value;
    } else {
      newQuestions[categoryIndex].questions[questionIndex].choices.push({
        text: value,
      });
    }

    const updatedExam = {
      ...object,
      categories: newQuestions,
    };

    setObjectState(updatedExam);
  }

  function handleQuestionChangeYes(category_index, e) {
    const { value } = e.target;
    const newCategories = [...object.categories];
    newCategories[category_index].questionYes = value;

    const updatedExam = {
      ...object,
      categories: newCategories,
    };
    setObjectState(updatedExam);
  }

  return (
    <div className="row g-3 mb-3">
      <div>
        <hr className="dropdown-divider" />
      </div>

      {object?.categories?.map((category, category_index) => {
        return (
          <React.Fragment key={category_index}>
            {!category.__operation && (
              <div className="p-4 border shadow">
                <div>
                  <p className="small fw-bold mb-0 ms-1">Form Categories</p>
                  <hr className="dropdown-divider" />
                </div>
                <div className="col-md-12 row">
                  <div className="col-md-4 mb-3">
                    <label className="form-label fs-sm">Header</label>{" "}
                    <input
                      type="text"
                      className="form-control"
                      placeholder={`Header ${category_index + 1}`}
                      defaultValue={category.header}
                      onChange={(e) => handleHeaderChange(category_index, e)}
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label className="form-label fs-sm">Question Type</label>{" "}
                    <span
                      className="ms-2"
                      role="button"
                      onClick={() => onDeleteCategory(category_index)}
                    >
                      <i className="bi bi-trash text-danger"></i>
                    </span>
                    <InputSelect
                      placeholder="Select Question Category"
                      options={[
                        "MULTIPLE CHOICE",
                        "YES OR NO",
                        "LABEL",
                        "INFORMANTS",
                      ]}
                      defaultValue={category.questionType}
                      onChange={(selectedOption) => {
                        onChangeType(category_index, selectedOption);
                      }}
                    />
                  </div>

                  {category.questionType === "YES OR NO" && (
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={category.questionYes}
                      placeholder="Question"
                      onChange={(e) =>
                        handleQuestionChangeYes(category_index, e)
                      }
                      required
                    />
                  )}

                  {category.questionType === "LABEL" && (
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={category.questionYes}
                      placeholder="Question"
                      onChange={(e) =>
                        handleQuestionChangeYes(category_index, e)
                      }
                      required
                    />
                  )}
                </div>
                <div>
                  {category?.questions?.map(
                    (question, question_index) =>
                      !question.__operation && (
                        <div
                          key={
                            question.id ||
                            `question-${category_index}-${question_index}`
                          }
                          className="mt-2 py-2"
                        >
                          <div className="col-md-12 row">
                            <div className="col-md-4">
                              <label className="form-label d-flex align-items-center gap-2">
                                Enter Question
                                <span
                                  role="button"
                                  onClick={() =>
                                    onDeleteQuestion(
                                      category_index,
                                      question_index
                                    )
                                  }
                                >
                                  <i className="bi bi-trash text-danger"></i>
                                </span>
                              </label>

                              <input
                                type="String"
                                className="form-control"
                                defaultValue={question.question}
                                placeholder={
                                  category.questionType === "MULTIPLE CHOICE"
                                    ? `Question ${question_index + 1}`
                                    : "Column"
                                }
                                onChange={(e) =>
                                  handleQuestionChange(
                                    category_index,
                                    question_index,
                                    e
                                  )
                                }
                                required
                              />
                            </div>
                          </div>

                          <hr />
                          {question.choices &&
                            question.choices.map((answer, answerIndex) => (
                              <input
                                key={answerIndex}
                                type="text"
                                className="form-control mb-2"
                                value={answer.name}
                                onChange={(e) =>
                                  handleAnswerChange(
                                    category_index,
                                    question_index,
                                    answerIndex,
                                    e
                                  )
                                }
                                placeholder={
                                  category.questionType === "MULTIPLE CHOICE"
                                    ? "Enter answer here"
                                    : "Row"
                                }
                              />
                            ))}
                          <div key={question_index}>
                            <div
                              className="btn btn-sm text-white"
                              onClick={() =>
                                onAddAnswer(category_index, question_index)
                              }
                              style={{ backgroundColor: "#003466" }}
                            >
                              {category.questionType === "MULTIPLE CHOICE"
                                ? "Add Answer"
                                : category.questionType === "YES OR NO"
                                ? "Add Row"
                                : "Add Row"}
                            </div>
                          </div>
                        </div>
                      )
                  )}
                  {category.questionType &&
                    (category.questionType === "MULTIPLE CHOICE" ? (
                      <div
                        className="btn btn-sm text-white me-2"
                        onClick={() => onAddQuestion(category_index)}
                        style={{ backgroundColor: "#003466" }}
                      >
                        Add Question
                      </div>
                    ) : (
                      <div
                        className="btn btn-sm text-white me-2 mt-2"
                        onClick={() => onAddQuestion(category_index)}
                        style={{ backgroundColor: "#003466" }}
                      >
                        Add Column
                      </div>
                    ))}
                </div>
              </div>
            )}
          </React.Fragment>
        );
      })}
      <button
        className="btn"
        onClick={onAddCategory}
        style={{ backgroundColor: "#dfbe68" }}
      >
        Add Categories
      </button>
    </div>
  );
}
