import React from "react";
import DispatchDialogPresenter from "./DispatchDialogPresenter";
import {
  findObjectUseCase,
  getObjectUseCase,
  upsertUseCase,
} from "../../../usecases/object";
import BaseFormPage from "../../../base/BaseFormPage";
import FormFactory from "../../../components/FormFactory";
import TimeInput from "../../../components/TimeInput";
import { Button, dialog, InputFactory } from "nq-component";
import Select from "react-select";

class DispatchDialog extends BaseFormPage {
  constructor(props) {
    super(props);
    this.state = {
      object: {},
      advanced: false,
      agent: [],
      selectedUser: null,
      selectedTime: { hour: "12", minute: "00", period: "AM" },
      selectedTurnTime: null,
      location: "",
    };
    this.presenter = new DispatchDialogPresenter(
      this,
      getObjectUseCase(),
      upsertUseCase()
    );
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  getCollectionName() {
    return this.props.schema.collection;
  }

  onSubmitForm(e) {
    e.preventDefault();
    const object = this.props.dispatchObject;
    this.presenter.submit(object);
  }

  async onChange(value, field) {
    console.log("field", field);
    if (field === "location") {
      this.setState({ location: value.name });
      const query = {
        where: {
          rolesName: "AGENTS",
          status: "Active",
        },
        include: ["all"],
      };
      const agents = await findObjectUseCase().execute("users", query);
      const filteredAgents = agents.filter((agent) =>
        agent.location.some((location) => location.name === value.name)
      );
      const agentOptions = filteredAgents.map((agent) => ({
        value: agent.id,
        label: agent.name,
      }));
      this.setState({ agent: agentOptions, selectedUser: null }, () => {
        this.presenter.onChange(value, field); // Call onChange here
      });
    } else {
      this.presenter.onChange(value, field); // Call onChange for other fields
    }
  }

  handleUserChange = (selectedUser) => {
    this.setState({ selectedUser });
  };

  handleTurnTimeChange = (selectedTurnTime) => {
    this.setState({ selectedTurnTime });
  };

  handleTimeChange = (selectedTime) => {
    this.setState({ selectedTime });
  };

  render() {
    const { object, agent, selectedUser, selectedTurnTime, selectedTime } =
      this.state;
    const schema = this.props.schema;
    console.warn("SDLFKJSDGSD: ", object);
    const schemas = this.props.schemas;
    const userOptions = agent;
    const times = [
      "8:00 AM",
      "8:30 AM",
      "9:00 AM",
      "9:30 AM",
      "10:00 AM",
      "10:30 AM",
      "11:00 AM",
      "11:30 AM",
      "12:00 PM",
      "12:30 PM",
      "1:00 PM",
      "1:30 PM",
      "2:00 PM",
      "2:30 PM",
      "3:00 PM",
      "3:30 PM",
      "4:00 PM",
      "4:30 PM",
      "5:00 PM",
    ];
    const timeOptions = times.map((time) => ({
      label: time,
      value: time,
    }));

    if (this.state.loading) return <span>Loading...</span>;
    return (
      <div className="p-3 pb-4">
        <h4 className="fw-bold" style={{ color: "#003869" }}>
          Dispatch
        </h4>
        <form onSubmit={this.onSubmitForm.bind(this)}>
          <div className="row g-3">
            <div className="mb-3">
              <FormFactory
                schemas={schemas}
                schema={schema}
                object={object}
                onChange={this.onChange.bind(this)}
                excludeFields={[
                  "loanTitle",
                  "residentName",
                  "residentialAddress",
                  "residentContact",
                  "employerName",
                  "employerAddress",
                  "employerContact",
                  "reportType",
                  "agent",
                  "timeCompleted",
                  "rType",
                  "startTime",
                  "note",
                  "notesCI",
                ]}
              />
              <div className="mb-3">
                <label htmlFor="user">User</label>
                <Select
                  options={userOptions}
                  value={selectedUser}
                  onChange={this.handleUserChange}
                />
              </div>

              <div
                className="mb-3"
                style={{
                  display: object.dispatchType !== "Pending" ? "none" : null,
                }}
              >
                <label htmlFor="start-time">Start Time</label>
                <Select
                  style={{
                    display: object.dispatchType !== "Pending" ? "none" : null,
                  }}
                  options={timeOptions || null}
                  value={selectedTurnTime || null}
                  onChange={this.handleTurnTimeChange}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="timePicker">Time to be Completed</label>
                <TimeInput
                  value={selectedTime}
                  onChange={this.handleTimeChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="user">Notes</label>
                <InputFactory
                  type="String"
                  field="notesCI"
                  _type="Text"
                  placeholder="Enter Notes"
                  onChange={this.onChange.bind(this)}
                />
              </div>
            </div>
            <div className="col-md-12 d-flex justify-content-center text-center">
              <button
                type="button"
                className="btn btn-light btn-sm fs-sm me-2"
                onClick={() => dialog.close()}
              >
                CANCEL
              </button>
              <Button
                type="submit"
                className="btn btn-sm fs-sm text-nowrap"
                style={{ backgroundColor: "#fdcb5b" }}
              >
                Confirm
              </Button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default DispatchDialog;
