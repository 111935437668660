import React, { useEffect, useState } from "react";

function Form1({
  object,
  answer,
  onChange,
  fieldRefs,
  validationErrors,
  user,
  disabled,
}) {
  const [selectedYesNo, setSelectedYesNo] = useState({});

  useEffect(() => {
    // Initialize refs for questions
    object.questions?.forEach((question) => {
      if (!fieldRefs?.questions[question.id]) {
        fieldRefs.questions[question.id] = React.createRef();
      }
      question.question_per_category?.forEach((perCategory) => {
        if (!fieldRefs.questions[perCategory.id]) {
          fieldRefs.questions[perCategory.id] = React.createRef();
        }
      });
    });

    // Initialize selectedYesNo with existing answers
    const initialSelectedYesNo = {};
    object.categories?.forEach((category) => {
      if (category.questionType === "YES OR NO") {
        const existingAnswer = answer.answers?.find(
          (ans) => ans.question.id === category.id
        );
        if (existingAnswer) {
          initialSelectedYesNo[category.id] = existingAnswer.response;
        }
      }
    });
    setSelectedYesNo(initialSelectedYesNo);
  }, [object, fieldRefs, answer]);

  const handleInputChange = (questionId, value, choiceId = null) => {
    console.log("value", value, "questionId", questionId, "choiceId", choiceId);
    const answerIndex = answer.answers.findIndex(
      (ans) => ans.question.id === questionId
    );

    console.log("ANSWERiNDEX", answerIndex);

    if (answerIndex >= 0) {
      if (choiceId) {
        // If there's a choiceId, update or add the response in the choices array
        const choiceIndex = answer.answers[answerIndex].choices.findIndex(
          (ch) => ch.id === choiceId
        );
        console.log("CI", choiceIndex);

        if (choiceIndex >= 0) {
          answer.answers[answerIndex].choices[choiceIndex].text = value;
        } else {
          answer.answers[answerIndex].choices.push({
            id: choiceId,
            text: value,
          });
        }
      } else {
        // If no choiceId, update the response directly
        answer.answers[answerIndex].answers = value;
      }
    } else {
      // If the question is not yet in the answers array, add it
      const newAnswer = {
        question: { id: questionId },
        response: choiceId ? "" : value, // Response is empty if it's a choice
        choices: choiceId ? [{ id: choiceId, text: value }] : [],
      };

      answer.answers.push(newAnswer);
    }

    console.log("Updated ANSWERS:", answer);
    onChange(answer); // Call the onChange handler to propagate changes
  };

  const handleYesNoChange = (categoryId, value) => {
    setSelectedYesNo((prev) => ({
      ...prev,
      [categoryId]: value,
    }));
    handleInputChange(categoryId, value);
  };

  const getInputClassName = (error) => {
    return `form-control${error ? " is-invalid" : ""}`;
  };

  return (
    <div className="row g-3 mb-3">
      <div className="mt-3">
        {object?.categories?.map((category) => (
          <div key={category.id}>
            {/* YES OR NO logic */}
            {category.questionType === "YES OR NO" && (
              <>
                <h6>{category.questionYes}</h6>
                <div className="d-flex">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name={`yes-no-group-${category.id}`} // Grouping by category id
                      id={`yes-radio-${category.id}`}
                      checked={
                        selectedYesNo[category.id] === "Yes" ||
                        answer.answers?.find(
                          (ans) => ans.question.id === category.id
                        )?.answers === "Yes"
                      }
                      onChange={() => handleYesNoChange(category.id, "Yes")}
                      disabled={disabled}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`yes-radio-${category.id}`}
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check ms-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name={`yes-no-group-${category.id}`} // Grouping by category id
                      id={`no-radio-${category.id}`}
                      checked={
                        selectedYesNo[category.id] === "No" ||
                        answer.answers?.find(
                          (ans) => ans.question.id === category.id
                        )?.answers === "No"
                      }
                      onChange={() => handleYesNoChange(category.id, "No")}
                      disabled={disabled}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`no-radio-${category.id}`}
                    >
                      No
                    </label>
                  </div>
                </div>

                {selectedYesNo[category.id] === "Yes" ||
                  (answer.answers?.find(
                    (ans) => ans.question.id === category.id
                  )?.answers === "Yes" && (
                    <div className="mt-3">
                      {category.questions?.length > 0
                        ? category.questions.map((question) => {
                            const existingAnswer = answer.answers?.find(
                              (ans) => ans.question.id === question.id
                            );
                            const existingAnswerId =
                              existingAnswer?.choices?.map((ch) => ch.id);
                            const uniqueChoices = existingAnswer?.choices.map(
                              (ch) => ch.text
                            );

                            return (
                              <div key={question.id}>
                                <h6>{question.question}</h6>
                                {/* Render unique choice inputs as controlled components */}
                                {uniqueChoices.map(
                                  (item, index) =>
                                    item && (
                                      <input
                                        key={`${question.id}-${index}`} // Ensure this key does not change on re-render
                                        type="text"
                                        className={getInputClassName(
                                          validationErrors?.questions[
                                            question.id
                                          ]
                                        )}
                                        ref={fieldRefs?.questions[question.id]}
                                        value={item}
                                        onChange={(e) =>
                                          handleInputChange(
                                            question.id,
                                            e.target.value,
                                            existingAnswerId[index]
                                          )
                                        }
                                        placeholder={
                                          validationErrors?.questions[
                                            question.id
                                          ]
                                            ? "Please fill this field"
                                            : item
                                        }
                                        disabled={disabled}
                                      />
                                    )
                                )}

                                {/* Render inputs for the rest of the choices as controlled components */}
                                {question.choices?.map((choice) => {
                                  const matchedText = uniqueChoices.includes(
                                    choice.text
                                  );
                                  const choiceValue =
                                    existingAnswer?.choices?.find(
                                      (ch) => ch.id === choice.id
                                    )?.text;

                                  if (!matchedText && choiceValue) {
                                    return (
                                      <input
                                        key={`${question.id}-choice-${choice.id}`} // Ensure this key does not change on re-render
                                        type="text"
                                        className={getInputClassName(
                                          validationErrors?.questions[
                                            question.id
                                          ]
                                        )}
                                        ref={fieldRefs?.questions[question.id]}
                                        value={choiceValue}
                                        onChange={(e) =>
                                          handleInputChange(
                                            question.id,
                                            e.target.value,
                                            choice.id
                                          )
                                        }
                                        placeholder={
                                          validationErrors?.questions[
                                            question.id
                                          ]
                                            ? "Please fill this field"
                                            : choice.name
                                        }
                                        disabled={disabled}
                                      />
                                    );
                                  }
                                })}
                              </div>
                            );
                          })
                        : null}
                    </div>
                  ))}
              </>
            )}

            {category.questionType === "LABEL" && (
              <>
                <h6>{category.questionYes}</h6>
                {category.questions?.map((question) => {
                  // Find the existing answers for this question
                  const existingAnswers = answer.answers
                    ?.find((ans) => ans.question.id === question.id)
                    ?.choices?.map((ch) => ch.text)
                    ?.filter((text) => text); // Filter out empty texts

                  const existingAnswersId = answer.answers
                    ?.find((ans) => ans.question.id === question.id)
                    ?.choices?.map((ch) => ch.id);
                  // ?.filter((text) => text);

                  // Ensure unique and non-empty choices
                  const uniqueAnswers = [...new Set(existingAnswers)];

                  return (
                    <div className="col-md-4" key={question.id}>
                      <h6>{question.question}</h6>
                      {existingAnswers?.map((item, index) => {
                        return (
                          <div
                            className="d-flex"
                            key={`${question.id}-unique-choice-${index}`}
                          >
                            <input
                              type="text"
                              className={getInputClassName(
                                validationErrors?.questions[question.id]
                              )}
                              ref={fieldRefs?.questions[question.id]}
                              // value={item}
                              defaultValue={item}
                              onChange={(e) =>
                                handleInputChange(
                                  question.id,
                                  e.target.value,
                                  existingAnswersId[index]
                                )
                              }
                              placeholder={
                                validationErrors?.questions[question.id]
                                  ? "Please fill this field"
                                  : item
                              }
                              disabled={disabled}
                            />
                          </div>
                        );
                      })}

                      {/* Handle other choices that are not in uniqueAnswers */}
                      {question.choices?.map((choice, choiceIndex) => {
                        const choiceValue = existingAnswers?.find(
                          (text) => text === choice.text
                        );

                        if (
                          !uniqueAnswers.includes(choice.text) &&
                          choiceValue
                        ) {
                          return (
                            <div
                              className="d-flex"
                              key={`${question.id}-choice-${choiceIndex}`}
                            >
                              <input
                                type="text"
                                className={getInputClassName(
                                  validationErrors?.questions[question.id]
                                )}
                                ref={fieldRefs?.questions[question.id]}
                                value={choiceValue}
                                onChange={(e) =>
                                  handleInputChange(
                                    question.id,
                                    e.target.value,
                                    choice.id
                                  )
                                }
                                placeholder={
                                  validationErrors?.questions[question.id]
                                    ? "Please fill this field"
                                    : choice.name
                                }
                                disabled={disabled}
                              />
                            </div>
                          );
                        }
                      })}
                    </div>
                  );
                })}
              </>
            )}

            {category.questionType === "MULTIPLE CHOICE" && (
              <>
                {category.questions?.map((question) => {
                  // Find the existing answer for this question
                  const existingAnswer =
                    answer?.answers?.find(
                      (ans) => ans.question.id === question.id
                    )?.answers || "";

                  return (
                    <div className="col-md-4" key={question.id}>
                      <h6>{question.question}</h6>
                      {question.choices?.map((choice, choiceIndex) => (
                        <div
                          className="d-flex"
                          key={`${question.id}-choice-${choiceIndex}`}
                        >
                          <p className="me-2">{choiceIndex + 1}.</p>
                          <p>{choice.text}</p>
                        </div>
                      ))}
                      <input
                        type="text"
                        className={getInputClassName(
                          validationErrors?.questions[question.id]
                        )}
                        ref={fieldRefs?.questions[question.id]}
                        value={existingAnswer}
                        onChange={(e) =>
                          handleInputChange(question.id, e.target.value)
                        }
                        placeholder={
                          validationErrors?.questions[question.id]
                            ? "Please fill this field"
                            : "Your answer"
                        }
                        disabled={disabled}
                      />
                    </div>
                  );
                })}
              </>
            )}

            {category.questionType === "INFORMANTS" && (
              <>
                {category.questions?.map((question) => {
                  const existingAnswer = answer?.answers
                    ?.find((ans) => ans.question.id === question.id)
                    .choices.map((ch) => ch.text);
                  const existingAnswerId = answer?.answers
                    ?.find((ans) => ans.question.id === question.id)
                    .choices.map((ch) => ch.id);

                  return (
                    <div className="col-md-4" key={question.id}>
                      <h6>{question.question}</h6>
                      {question.choices?.map((choice, choiceIndex) => {
                        // Find the specific choice in the existingAnswer that matches this choice.id
                        const existingChoice = existingAnswer?.choices?.find(
                          (ch) => ch.id === choice.id
                        );

                        return (
                          <div
                            className="d-flex"
                            key={`${question.id}-choice-${choiceIndex}`}
                          >
                            <input
                              type="text"
                              className={getInputClassName(
                                validationErrors?.questions[question.id]
                              )}
                              ref={fieldRefs?.questions[question.id]}
                              // value={existingChoice?.text || ""}
                              // value={existingAnswer || ""}
                              defaultValue={existingAnswer || ""}
                              onChange={(e) =>
                                handleInputChange(
                                  question.id,
                                  e.target.value,
                                  existingAnswerId[0]
                                )
                              }
                              placeholder={
                                validationErrors?.questions[question.id]
                                  ? "Please fill this field"
                                  : "Your answer"
                              }
                              disabled={disabled}
                            />
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Form1;
