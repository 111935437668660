import React, { useState, useRef, useEffect } from "react";
import regionsPh from "../regionsPh.json";
import provincePh from "../provincePh.json";
import classNames from "../../classNames";

const LocationSelectorMIS = ({ object, onLocationChange }) => {
  const [regions, setRegions] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState({});
  const inputRef = useRef();
  const wrapperRef = useRef();

  useEffect(() => {
    if (object) {
      setRegions(object.regions || []);
      setProvinces(object.provinces || []);
    }
  }, [object]);

  useEffect(() => {
    onLocationChange(regions, provinces);
  }, [regions, provinces, onLocationChange]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setDropdownOpen({});
    }
  };

  const openDropdown = (type, index) => {
    setDropdownOpen((prevState) => ({
      ...prevState,
      [`${type}-${index}`]: !prevState[`${type}-${index}`],
    }));
  };

  const clearSelection = (type, index) => {
    if (type === "region") {
      const newRegions = [...regions];
      newRegions[index] = "";
      setRegions(newRegions);
    } else if (type === "province") {
      const newProvinces = [...provinces];
      newProvinces[index] = "";
      setProvinces(newProvinces);
    }
  };

  const handleRegionsChange = (valueName, valueCode, index) => {
    const newRegions = [...regions];
    newRegions[index] = { name: valueName, code: valueCode };
    setRegions(newRegions);
    setDropdownOpen((prevState) => ({
      ...prevState,
      [`region-${index}`]: false,
    }));
  };

  const handleProvinceChange = (valueName, valueCode, index) => {
    const newProvinces = [...provinces];
    newProvinces[index] = { name: valueName, code: valueCode };
    setProvinces(newProvinces);
    setDropdownOpen((prevState) => ({
      ...prevState,
      [`province-${index}`]: false,
    }));
  };

  const addRegion = () => {
    setRegions([...regions, ""]);
  };

  const addProvince = () => {
    setProvinces([...provinces, ""]);
  };

  const deleteRegion = (index) => {
    const newRegions = regions.filter((_, i) => i !== index);
    setRegions(newRegions);
  };

  const deleteProvince = (index) => {
    const newProvinces = provinces.filter((_, i) => i !== index);
    setProvinces(newProvinces);
  };

  const getFilteredRegions = () => {
    const selectedRegionCodes = regions.map((region) => region.code);
    return regionsPh.filter(
      (region) => !selectedRegionCodes.includes(region.code)
    );
  };

  const getFilteredProvinces = () => {
    const selectedProvinceCodes = provinces.map((province) => province.code);
    return provincePh.filter(
      (province) => !selectedProvinceCodes.includes(province.code)
    );
  };

  return (
    <div ref={wrapperRef}>
      <div className="row">
        {regions.map((region, index) => (
          <div
            key={`region-${index}`}
            className="mb-3 col-12 d-flex align-items-center"
          >
            <div className="col-11">
              <label className="mb-2" htmlFor={`region-${index}`}>
                Region {index + 1}
              </label>
              <div className="custom-select-wrapper">
                <div className="position-relative">
                  <input
                    readOnly
                    className={classNames("form-control")}
                    value={region.name || ""}
                    placeholder="Select Region"
                    onClick={() => openDropdown("region", index)} // Open dropdown when input is clicked
                    ref={inputRef}
                  />
                  <span
                    className="position-absolute end-0 top-50 translate-middle-y me-3 cursor-pointer"
                    onClick={() =>
                      region
                        ? clearSelection("region", index)
                        : openDropdown("region", index)
                    }
                  >
                    {region ? (
                      <i className="bi bi-x-lg"></i>
                    ) : (
                      <i className="bi bi-chevron-down"></i>
                    )}
                  </span>
                </div>
                {dropdownOpen[`region-${index}`] && (
                  <ul className="custom-select-options">
                    {getFilteredRegions().map((region) => (
                      <li
                        key={region.code}
                        onClick={() =>
                          handleRegionsChange(region.name, region.code, index)
                        }
                        className="custom-select-option"
                      >
                        {region.name}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
            <div className="col-1 d-flex justify-content-end">
              <span
                className="cursor-pointer"
                onClick={() => deleteRegion(index)}
              >
                <i className="bi bi-trash"></i>
              </span>
            </div>
          </div>
        ))}
        <div className="mb-3 col-12">
          <button type="button" onClick={addRegion} className="btn btn-primary">
            Add Region
          </button>
        </div>

        {provinces.map((province, index) => (
          <div
            key={`province-${index}`}
            className="mb-3 col-12 d-flex align-items-center"
          >
            <div className="col-11">
              <label className="mb-2" htmlFor={`province-${index}`}>
                Province {index + 1}
              </label>
              <div className="custom-select-wrapper">
                <div className="position-relative">
                  <input
                    readOnly
                    className={classNames("form-control")}
                    value={province.name || ""}
                    placeholder="Select Province"
                    onClick={() => openDropdown("province", index)} // Open dropdown when input is clicked
                  />
                  <span
                    className="position-absolute end-0 top-50 translate-middle-y me-3 cursor-pointer"
                    onClick={() =>
                      province
                        ? clearSelection("province", index)
                        : openDropdown("province", index)
                    }
                  >
                    {province ? (
                      <i className="bi bi-x-lg"></i>
                    ) : (
                      <i className="bi bi-chevron-down"></i>
                    )}
                  </span>
                </div>
                {dropdownOpen[`province-${index}`] && (
                  <ul className="custom-select-options">
                    {getFilteredProvinces().map((province) => (
                      <li
                        key={province.code}
                        onClick={() =>
                          handleProvinceChange(
                            province.name,
                            province.code,
                            index
                          )
                        }
                        className="custom-select-option"
                      >
                        {province.name}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
            <div className="col-1 d-flex justify-content-end">
              <span
                className="cursor-pointer"
                onClick={() => deleteProvince(index)}
              >
                <i className="bi bi-trash"></i>
              </span>
            </div>
          </div>
        ))}
        <div className="mb-3 col-12">
          <button
            type="button"
            onClick={addProvince}
            className="btn btn-primary"
          >
            Add Province
          </button>
        </div>
      </div>
    </div>
  );
};

export default LocationSelectorMIS;
