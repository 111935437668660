import React from "react";
import { InputJson } from "nq-component";
import {
  findObjectUseCase,
  getObjectUseCase,
  upsertUseCase,
} from "../../usecases/object";
import withRouter from "../../withRouter";
import BaseFormPage from "../../base/BaseFormPage";
import NavBar from "../../components/navbar";
import FormFactory from "../../components/FormFactory";
import Select from "react-select";
import EndorsementViewPresenter from "./EndorsementViewPresenter";

class EndorsementViewPage extends BaseFormPage {
  constructor(props) {
    super(props);
    this.state = { object: {}, advanced: false, type: [], selectedForm: null };
    this.presenter = new EndorsementViewPresenter(
      this,
      getObjectUseCase(),
      upsertUseCase()
    );
  }

  componentDidMount() {
    this.presenter.componentDidMount();
    this.getUserAgent();
  }

  getCollectionName() {
    return "endorsement";
  }

  onClickAdvance() {
    this.setState({ advanced: !this.state.advanced });
  }

  onChangeObject(object) {
    this.presenter.onChangeObject(object);
  }

  async getUserAgent() {
    const user = this.getCurrentUser();
    const query = {
      where: {
        clients: user.name,
      },
      include: ["all"],
    };
    const bvr = await findObjectUseCase().execute(
      "business_verification_form",
      query
    );
    const formOptions = bvr?.map((agent) => ({
      value: agent.id,
      label: agent.type,
    }));
    console.log("bvr", formOptions);
    this.setState({ type: formOptions });
  }

  handleUserChange = (selectedForm) => {
    this.setState({ selectedForm });
  };

  getStatusColor(status) {
    switch (status) {
      case "Done":
        return "#42B23E";
      case "For QA":
        return "#9747ff";
      case "For CI":
        return "#fdcb5b";
      case "For Dispatch":
        return "#f48808";
      case "PENDING":
        return "#434343";
      default:
        return "black";
    }
  }

  render() {
    const object = this.state.object;
    const selectedClient = object.clients;
    const formType = this.state.type;
    console.log("form", formType);
    console.log("Edit Object", object);
    const advanced = this.state.advanced;
    const schema = this.getSchema(this.getCollectionName());
    const formatDateToPhilippinesTime = (dateString) => {
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
        timeZone: "Asia/Manila",
      };
      const formatter = new Intl.DateTimeFormat("en-US", options);
      const parts = formatter.formatToParts(new Date(dateString));
      const date = `${parts.find((p) => p.type === "year").value}-${
        parts.find((p) => p.type === "month").value
      }-${parts.find((p) => p.type === "day").value}`;
      const time = `${parts.find((p) => p.type === "hour").value}:${
        parts.find((p) => p.type === "minute").value
      }:${parts.find((p) => p.type === "second").value} ${
        parts.find((p) => p.type === "dayPeriod").value
      }`;
      return `${date} ${time}`;
    };

    if (!schema) return <h1>no schema</h1>;
    const label = this.getObjectId() === undefined ? "Add New " : "View ";
    return (
      <>
        <NavBar
          action={() => (
            <div className="dropdown dropstart d-inline-block">
              <i
                role="button"
                data-bs-toggle="dropdown"
                className="bi bi-three-dots-vertical"
              ></i>
              <div className="dropdown-menu fs-xs">
                <button
                  onClick={this.onClickAdvance.bind(this)}
                  className="dropdown-item py-3"
                >
                  <i className="bi bi-gear pe-2" />
                  Toggle Advance
                </button>
              </div>
            </div>
          )}
        />
        <div className="overflow-auto">
          <div className="h-100">
            <div className="p-3 p-lg-4">
              <h1 className="fw-bold mt-3 text-capitalize">
                {label + (schema.label || this.getCollectionName())}
              </h1>
              <div className="d-flex">
                <ul className="nav nav-tabs mb-3">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="credits-tab"
                      data-bs-toggle="tab"
                      href="#credits"
                      role="tab"
                      aria-controls="credits"
                      aria-selected="true"
                    >
                      Overview
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="logs-tab"
                      data-bs-toggle="tab"
                      href="#logs"
                      role="tab"
                      aria-controls="logs"
                      aria-selected="false"
                    >
                      Timeline
                    </a>
                  </li>
                </ul>
              </div>

              <div className="mt-3 bg-white shadow rounded p-3 px-lg-5 py-lg-4">
                <div className="tab-content">
                  <div
                    className="tab-pane fade show active"
                    id="credits"
                    role="tabpanel"
                    aria-labelledby="credits-tab"
                  >
                    <ul className="list-unstyled ms-1">
                      <li>
                        <span
                          className="ms-2 fw-bold"
                          style={{ color: "#004E9B" }}
                        >
                          Loan Title:
                        </span>
                        <span className="fs-sm text-nowrap">
                          {" "}
                          {object.loanTitle}
                        </span>
                      </li>
                      <hr />
                      <h6 style={{ color: "#fdcb5b", marginBottom: "15px" }}>
                        {object?.rType === "BUSINESS VERIFICATION (BVR)"
                          ? "Business Details"
                          : "Resident Details"}
                      </h6>
                      <li>
                        <span className="ms-2 fw-bold text-dark">
                          {object?.rType === "BUSINESS VERIFICATION (BVR)"
                            ? "Business Name: "
                            : "Resident Name:"}
                        </span>
                        <span className="fs-sm text-nowrap">
                          {" "}
                          {object.residentName}
                        </span>
                      </li>

                      <li>
                        <span className="ms-2 fw-bold text-dark">
                          {object?.rType === "BUSINESS VERIFICATION (BVR)"
                            ? "Business Address: "
                            : "Residential Address:"}
                        </span>
                        <span className="fs-sm text-nowrap">
                          {object.residentialAddress}, {object.barangay},{" "}
                          {object.city}, {object.province} {object.region}
                        </span>
                      </li>
                      <li>
                        <span className="ms-2 fw-bold text-dark">
                          Contact Number:{" "}
                        </span>
                        <span className="fs-sm text-nowrap">
                          {object.residentContact}
                        </span>
                      </li>
                      <hr />
                      <h6 style={{ color: "#fdcb5b", marginBottom: "15px" }}>
                        {object?.rType === "BUSINESS VERIFICATION (BVR)"
                          ? "Subject Details"
                          : "Employment Details"}
                      </h6>
                      <li>
                        <span className="ms-2 fw-bold text-dark">
                          {object?.rType === "BUSINESS VERIFICATION (BVR)"
                            ? "Subject Name: "
                            : "Employer's Name:"}
                        </span>
                        <span className="fs-sm text-nowrap">
                          {" "}
                          {object.employerName}
                        </span>
                      </li>

                      <li>
                        <span className="ms-2 fw-bold text-dark">
                          {object?.rType === "BUSINESS VERIFICATION (BVR)"
                            ? "Subject Address: "
                            : "Employer's Address:"}
                        </span>
                        <span className="fs-sm text-nowrap">
                          {object.employerAddress}
                        </span>
                      </li>
                      <li>
                        <span className="ms-2 fw-bold text-dark">
                          Contact Number:{" "}
                        </span>
                        <span className="fs-sm text-nowrap">
                          {object.employerContact}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div
                    className="tab-pane fade show"
                    id="logs"
                    role="tabpanel"
                    aria-labelledby="logs-tab"
                  >
                    <h6 className="fw-bold" style={{ color: "#004E9B" }}>
                      Status Update
                    </h6>
                    <div
                      className="timeline-container"
                      style={{
                        border: "1px solid #ced4da",
                        borderRadius: "5px",
                        padding: "10px",
                        marginTop: "10px",
                      }}
                    >
                      {object.timeline
                        ?.slice()
                        .reverse()
                        .map((t, index) => (
                          <div
                            key={index}
                            className="timeline-item"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              padding: "5px 0",
                            }}
                          >
                            <span
                              className="timeline-time"
                              style={{ color: "#6c757d", fontSize: "0.9rem" }}
                            >
                              {formatDateToPhilippinesTime(t.time)}
                              {/* t.time */}
                            </span>
                            <span
                              className="timeline-status"
                              style={{
                                fontWeight: "bold",
                                fontSize: "0.9rem",
                                color: this.getStatusColor(t.status),
                              }}
                            >
                              {t.status}
                            </span>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(EndorsementViewPage);
