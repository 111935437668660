import React from "react";
import { InputFactory, dialog } from "nq-component";
import {
  findObjectUseCase,
  getObjectUseCase,
  upsertUseCase,
} from "../../../../usecases/object";
import withRouter from "../../../../withRouter";
import BaseFormPage from "../../../../base/BaseFormPage";
import NavBar from "../../../../components/navbar";
import Form1Agent from "../../components/Form1Agent";
import InputAdd from "../../../../components/InputAdd";
import Form1 from "../../components/Form1";
import QAComparisonBVRPresenter from "./QAComparisonBVRPresenter";
import classNames from "../../../../classNames";

class QAComparisonBVRPage extends BaseFormPage {
  constructor(props) {
    super(props);
    this.state = {
      object: {},
      answerAgent: {},
      answerQA: {},
      advanced: false,
      type: [],
      selectedForm: null,
      reason: "",
      uploadRelation: [],
      activeTab: "CI FORM",
      validationErrors: {
        natureBusiness: false,
        operational: false,
        requesting: false,
        questions: {},
      },
    };
    this.presenter = new QAComparisonBVRPresenter(
      this,
      getObjectUseCase(),
      upsertUseCase()
    );

    this.fieldRefs = {
      natureBusiness: React.createRef(),
      operational: React.createRef(),
      requesting: React.createRef(),
      questions: {},
    };
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  getObjectId() {
    return this.props.params && this.props.params.id;
  }

  getCollectionName() {
    return "bvr_forms_mis";
  }

  setQaAnswer(answerQA) {
    this.setState({ answerQA });
  }

  setAgentAnswer(answerAgent) {
    this.setState({ answerAgent });
  }

  onClickAdvance() {
    this.setState({ advanced: !this.state.advanced });
  }

  onChangeObject(object) {
    this.presenter.onChangeObject(object);
  }

  handleInputChange = (id, value, choiceId = null) => {
    console.log("Input Change", value);
    const newObject = { ...this.state.object };
    const newValidationErrors = { ...this.state.validationErrors };

    // Clear validation error when the user starts typing
    if (newValidationErrors[id]) {
      newValidationErrors[id] = false;
    } else if (choiceId && newValidationErrors.questions[choiceId]) {
      newValidationErrors.questions[choiceId] = false;
    } else if (newValidationErrors.questions[id]) {
      newValidationErrors.questions[id] = false;
    }

    // Handle top-level input fields
    if (["natureBusiness", "operational", "requesting"].includes(id)) {
      newObject[id] = value;
    } else {
      // Check if the ID is from categories
      const categoryIndex = newObject.categories?.findIndex((c) => c.id === id);
      if (categoryIndex !== -1) {
        newObject.categories[categoryIndex].answer = value;
      } else {
        // Check if it's for a choice within a question
        let updated = false;
        newObject.questions?.forEach((question) => {
          question.question_per_category?.forEach((perCategory) => {
            if (choiceId) {
              // Update the choice name if choiceId is provided
              const choiceIndex = perCategory.choices?.findIndex(
                (c) => c.id === choiceId
              );
              if (choiceIndex !== -1) {
                perCategory.choices[choiceIndex].name = value;
                updated = true;
              }
            } else if (perCategory.id === id) {
              // Update the answerQuestion if no choiceId is provided
              perCategory.answerQuestion = value;
              updated = true;
            }
          });
        });
        if (!updated) {
          console.log("No matching IDs found for update.");
        }
      }
    }
    this.setState({ object: newObject, validationErrors: newValidationErrors });
  };

  validateForm = () => {
    const object = this.state.object;
    const requiredFields = ["natureBusiness", "operational", "requesting"];
    let isValid = true;
    const validationErrors = {
      natureBusiness: false,
      operational: false,
      requesting: false,
      questions: {},
    };

    // Validate top-level fields
    for (const field of requiredFields) {
      if (!object[field]) {
        validationErrors[field] = true;
        isValid = false;
        if (this.fieldRefs[field].current) {
          this.fieldRefs[field].current.scrollIntoView({ behavior: "smooth" });
          this.fieldRefs[field].current.focus();
        }
      }
    }

    // Validate questions and their categories
    for (const question of object.questions || []) {
      if (question.questionType === "YES OR NO" && !question.yesOrNo) {
        if (this.fieldRefs.questions[question.id]) {
          validationErrors.questions[question.id] = true;
          isValid = false;
          this.fieldRefs.questions[question.id].current.scrollIntoView({
            behavior: "smooth",
          });
          this.fieldRefs.questions[question.id].current.focus();
        }
      }

      for (const perCategory of question.question_per_category || []) {
        if (
          perCategory.hasOwnProperty("answerQuestion") &&
          (perCategory.answerQuestion === undefined ||
            perCategory.answerQuestion === "")
        ) {
          if (this.fieldRefs.questions[perCategory.id]) {
            validationErrors.questions[perCategory.id] = true;
            isValid = false;
            this.fieldRefs.questions[perCategory.id].current.scrollIntoView({
              behavior: "smooth",
            });
            this.fieldRefs.questions[perCategory.id].current.focus();
          }
        }
      }
    }

    this.setState({ validationErrors });
    return isValid;
  };

  onSubmitForm(e) {
    e.preventDefault();
    if (this.validateForm()) {
      this.presenter.submit();
    } else {
      console.log("Validation failed");
    }
  }

  // onSubmitForm(e) {
  //   e.preventDefault();

  //   this.presenter.submit();
  // }

  onClickStatusQA(index) {
    this.presenter.onClickStatus(index);
    dialog.close();
  }

  onChangeReason(e) {
    const value = e.target.value;
    console.log("val", value);
    this.setState({ reason: value });
  }

  onClickStatusQADis() {
    dialog.fire({
      html: (
        <>
          <div>
            <h5 className="text-danger">Disapprove Audit</h5>
            <div className="col">
              <h6>Please enter reason of disapproval</h6>
              <input
                className="form-control"
                type="text"
                onChange={(e) => this.onChangeReason(e)}
              />
            </div>
            <div className="m-3 text-center">
              <button
                className="btn btn-light me-2"
                onClick={() => dialog.close()}
              >
                Cancel
              </button>
              <button
                className="btn btn-danger text-white"
                onClick={() => this.onClickStatusQA("Disapprove")}
              >
                Disapprove
              </button>
            </div>
          </div>
        </>
      ),
      footer: false,
    });
  }

  handleImagesChange = (items) => {
    this.setState((prevState) => ({
      object: {
        ...prevState.object,
        uploadRelation: items,
      },
    }));
  };

  onChange(value, field) {
    const newObject = { ...this.state.object }; // Create a copy of the current object
    newObject[field] = value; // Update the specific field with the new value
    this.setState({ object: newObject }); // Update the state with the modified object
  }

  onTabChange = (tabName) => {
    this.setState({ activeTab: tabName });
  };

  render() {
    const object = this.state.object;
    const answerQA = this.state.answerQA;
    const answerAgent = this.state.answerAgent;
    const activeTab = this.state.activeTab;
    console.log("ANSWER AGENT", answerAgent);
    console.log("ANSWER QA", answerQA);
    const validationErrors = this.state.validationErrors;
    // const { object, validationErrors } = this.state;
    console.log("objectsss", object);
    console.log("valid", validationErrors);
    const advanced = this.state.advanced;
    const schema = this.getSchema(this.getCollectionName());
    const user = this.getCurrentUser();
    if (!schema) return <h1>no schema</h1>;
    const label = this.getObjectId() === undefined ? "Add New " : "View ";
    return (
      <>
        <NavBar
          action={() => (
            <div className="dropdown dropstart d-inline-block">
              <i
                role="button"
                data-bs-toggle="dropdown"
                className="bi bi-three-dots-vertical"
              ></i>
              <div className="dropdown-menu fs-xs">
                <button
                  onClick={this.onClickAdvance.bind(this)}
                  className="dropdown-item py-3"
                >
                  <i className="bi bi-gear pe-2" />
                  Toggle Advance
                </button>
              </div>
            </div>
          )}
        />
        <div className="overflow-auto">
          <div className="h-100">
            <div className="p-3 p-lg-4">
              <div className="row">
                <div className="col">
                  <h1 className="fw-bold mt-3 text-capitalize">
                    {/* {label + (schema.label || this.getCollectionName())} */}
                    <i
                      class="bi bi-arrow-left"
                      onClick={this.onClickBack.bind(this)}
                    ></i>{" "}
                    Comparison
                  </h1>
                </div>
              </div>
              <div className="d-flex">
                <ul className="nav nav-tabs mb-3">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="credits-tab"
                      data-bs-toggle="tab"
                      href="#credits"
                      role="tab"
                      aria-controls="credits"
                      aria-selected="true"
                      onClick={() => this.onTabChange("CI FORM")}
                    >
                      CI FORM
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="logs-tab"
                      data-bs-toggle="tab"
                      href="#logs"
                      role="tab"
                      aria-controls="logs"
                      aria-selected="false"
                      onClick={() => this.onTabChange("QA FORM")}
                    >
                      QA FORM
                    </a>
                  </li>
                </ul>
              </div>
              <div className="mt-3 bg-white shadow rounded p-3 px-lg-5 py-lg-4">
                <div className="tab-content">
                  <div
                    // className="tab-pane fade show active"
                    className={`tab-pane fade ${
                      activeTab === "CI FORM" ? "show active" : ""
                    }`}
                    id="credits"
                    role="tabpanel"
                    aria-labelledby="credits-tab"
                  >
                    <div className="row g-3">
                      {/* <Form1Agent
                        object={object}
                        onChange={this.handleInputChange}
                        fieldRefs={this.fieldRefs}
                        validationErrors={validationErrors}
                        user={user}
                        disabled={true}
                      /> */}
                      <Form1
                        object={object}
                        answer={answerAgent}
                        onChange={this.handleInputChange}
                        fieldRefs={this.fieldRefs}
                        validationErrors={validationErrors}
                        disabled={true}
                      />
                      <div>
                        <label className="mb-2">Remarks</label>
                        <InputFactory
                          type="String"
                          _type="Text"
                          field="remarks"
                          object={answerAgent}
                          onChange={this.onChange.bind(this)}
                          disabled={true}
                        />
                      </div>
                      <div>
                        {answerAgent.uploadRelation?.map((upload) => (
                          <div className="col-6">
                            <img
                              src={upload.image}
                              className="rounded m-2 img-fluid"
                              height="300"
                            />
                            <input
                              className={classNames("form-control mt-2")}
                              value={upload.label}
                              disabled
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div
                    // className="tab-pane fade show"
                    className={`tab-pane fade ${
                      activeTab === "QA FORM" ? "show active" : ""
                    }`}
                    id="logs"
                    role="tabpanel"
                    aria-labelledby="logs-tab"
                  >
                    <form onSubmit={this.onSubmitForm.bind(this)}>
                      <div className="row g-3">
                        <Form1
                          object={object}
                          answer={answerQA}
                          onChange={this.handleInputChange}
                          fieldRefs={this.fieldRefs}
                          validationErrors={validationErrors}
                          disabled={true}
                        />
                        <div>
                          <label className="mb-2">Remarks</label>
                          <InputFactory
                            type="String"
                            _type="Text"
                            field="remarks"
                            object={answerQA}
                            onChange={this.onChange.bind(this)}
                            disabled={true}
                          />
                        </div>

                        <div>
                          {answerQA.uploadRelation?.map((upload) => (
                            <div className="col-6">
                              <img
                                src={upload.image}
                                className="rounded m-2 img-fluid"
                                height="300"
                              />
                              <input
                                className={classNames("form-control mt-2")}
                                value={upload.label}
                                disabled
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(QAComparisonBVRPage);
