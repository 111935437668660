import React, { useState } from "react";

function Form3({
  object,
  answer,
  onChange,
  disabled,
  fieldRefs,
  validationErrors,
}) {
  console.log("Form2 Object:", object);
  console.log("Form2 Answer:", answer);

  const [homeOwnership, setHomeOwnership] = useState("");

  const handleQuestionChange = (categoryId, questionId, value) => {
    console.log("Question Change:", categoryId, questionId, value);
    onChange(categoryId, questionId, value);
  };

  const handleHomeOwnershipChange = (categoryId, questionId, value) => {
    setHomeOwnership(value);
    handleQuestionChange(categoryId, questionId, value);
  };

  const getAnswerForQuestion = (questionId) => {
    const foundAnswer = answer.answers?.find(
      (ans) => ans?.question?.id === questionId
    );

    return foundAnswer ? foundAnswer.answer : "";
  };

  return (
    <div className="row g-3 mb-3">
      {object?.categories?.map((category) => (
        <div className="col-md-12" key={category.id}>
          <h4>{category?.title}</h4>
          {category?.subTitle && <h5>{category.subTitle}</h5>}
          {category?.questions?.map((question) => (
            <div key={question.id}>
              <h6>{question.question}</h6>
              {question.question === "NEIGHBORHOOD:" ? (
                // Render radio buttons for the NEIGHBORHOOD question
                <div>
                  <div className="form-check">
                    <input
                      type="radio"
                      name={question.id}
                      value="MIXED"
                      //   checked={getAnswerForQuestion(question.id)}
                      checked={getAnswerForQuestion(question.id) === "MIXED"}
                      className="form-check-input"
                      onChange={(e) =>
                        handleQuestionChange(
                          category.id,
                          question.id,
                          e.target.value
                        )
                      }
                      disabled={disabled}
                    />
                    <label className="form-check-label">MIXED</label>
                  </div>
                  <div className="form-check">
                    <input
                      type="radio"
                      name={question.id}
                      value="COMMERCIAL"
                      //   checked={getAnswerForQuestion(question.id)}
                      checked={
                        getAnswerForQuestion(question.id) === "COMMERCIAL"
                      }
                      className="form-check-input"
                      onChange={(e) =>
                        handleQuestionChange(
                          category.id,
                          question.id,
                          e.target.value
                        )
                      }
                      disabled={disabled}
                    />
                    <label className="form-check-label">COMMERCIAL</label>
                  </div>
                  <div className="form-check">
                    <input
                      type="radio"
                      name={question.id}
                      value="RESIDENTIAL"
                      //   checked={getAnswerForQuestion(question.id)}
                      checked={
                        getAnswerForQuestion(question.id) === "RESIDENTIAL"
                      }
                      className="form-check-input"
                      onChange={(e) =>
                        handleQuestionChange(
                          category.id,
                          question.id,
                          e.target.value
                        )
                      }
                      disabled={disabled}
                    />
                    <label className="form-check-label">RESIDENTIAL</label>
                  </div>
                </div>
              ) : question.question === "NEIGHBORHOOD CLASS :" ? (
                // Render radio buttons for the NEIGHBORHOOD CLASS question
                <div>
                  <div className="form-check">
                    <input
                      type="radio"
                      name={question.id}
                      value="UPPER- MIDDLE CLASS"
                      checked={
                        getAnswerForQuestion(question.id) ===
                        "UPPER- MIDDLE CLASS"
                      }
                      className="form-check-input"
                      onChange={(e) =>
                        handleQuestionChange(
                          category.id,
                          question.id,
                          e.target.value
                        )
                      }
                      disabled={disabled}
                    />
                    <label className="form-check-label">
                      UPPER- MIDDLE CLASS
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      type="radio"
                      name={question.id}
                      value="MIDDLE-LOW MIDDLE CLASS"
                      className="form-check-input"
                      checked={
                        getAnswerForQuestion(question.id) ===
                        "MIDDLE-LOW MIDDLE CLASS"
                      }
                      onChange={(e) =>
                        handleQuestionChange(
                          category.id,
                          question.id,
                          e.target.value
                        )
                      }
                      disabled={disabled}
                    />
                    <label className="form-check-label">
                      MIDDLE-LOW MIDDLE CLASS
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      type="radio"
                      name={question.id}
                      value="LOWER CLASS"
                      checked={
                        getAnswerForQuestion(question.id) === "LOWER CLASS"
                      }
                      className="form-check-input"
                      onChange={(e) =>
                        handleQuestionChange(
                          category.id,
                          question.id,
                          e.target.value
                        )
                      }
                      disabled={disabled}
                    />
                    <label className="form-check-label">LOWER CLASS</label>
                  </div>
                  <div className="form-check">
                    <input
                      type="radio"
                      name={question.id}
                      value="GREY CONCERN"
                      checked={
                        getAnswerForQuestion(question.id) === "GREY CONCERN"
                      }
                      className="form-check-input"
                      onChange={(e) =>
                        handleQuestionChange(
                          category.id,
                          question.id,
                          e.target.value
                        )
                      }
                      disabled={disabled}
                    />
                    <label className="form-check-label">GREY CONCERN</label>
                  </div>
                </div>
              ) : question.question === "HOME OWNERSHIP :" ? (
                // Render radio buttons for the HOME OWNERSHIP question
                <div>
                  <div className="form-check">
                    <input
                      type="radio"
                      name={question.id}
                      value="OWNED"
                      checked={getAnswerForQuestion(question.id) === "OWNED"}
                      className="form-check-input"
                      onChange={(e) =>
                        handleHomeOwnershipChange(
                          category.id,
                          question.id,
                          e.target.value
                        )
                      }
                      disabled={disabled}
                    />
                    <label className="form-check-label">OWNED</label>
                  </div>
                  <div className="form-check">
                    <input
                      type="radio"
                      name={question.id}
                      value="LIVING WITH RELATIVES OR OTHERS"
                      className="form-check-input"
                      checked={
                        getAnswerForQuestion(question.id) ===
                        "LIVING WITH RELATIVES OR OTHERS"
                      }
                      onChange={(e) =>
                        handleHomeOwnershipChange(
                          category.id,
                          question.id,
                          e.target.value
                        )
                      }
                      disabled={disabled}
                    />
                    <label className="form-check-label">
                      LIVING WITH RELATIVES OR OTHERS
                    </label>
                  </div>
                  {(homeOwnership === "LIVING WITH RELATIVES OR OTHERS" ||
                    getAnswerForQuestion(question.id) ===
                      "LIVING WITH RELATIVES OR OTHERS") && (
                    <div className="form-group mt-2">
                      <label>Specify:</label>
                      <input
                        type="text"
                        className="form-control"
                        value={getAnswerForQuestion(`${question.id}-specify`)}
                        onChange={(e) =>
                          handleQuestionChange(
                            category.id,
                            `${question.id}-specify`,
                            e.target.value
                          )
                        }
                        disabled={disabled}
                      />
                    </div>
                  )}
                  <div className="form-check">
                    <input
                      type="radio"
                      name={question.id}
                      value="RENTED"
                      checked={getAnswerForQuestion(question.id) === "RENTED"}
                      className="form-check-input"
                      onChange={(e) =>
                        handleHomeOwnershipChange(
                          category.id,
                          question.id,
                          e.target.value
                        )
                      }
                      disabled={disabled}
                    />
                    <label className="form-check-label">RENTED</label>
                  </div>
                  {(homeOwnership === "RENTED" ||
                    getAnswerForQuestion(question.id) === "RENTED") && (
                    <div className="form-group mt-2">
                      <label>Monthly Rental:</label>
                      <input
                        type="text"
                        className="form-control"
                        value={getAnswerForQuestion(
                          `${question.id}-monthly-rental`
                        )}
                        onChange={(e) =>
                          handleQuestionChange(
                            category.id,
                            `${question.id}-monthly-rental`,
                            e.target.value
                          )
                        }
                        disabled={disabled}
                      />
                      <label className="mt-2">
                        Owners/Lessee of House Occupied:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={getAnswerForQuestion(
                          `${question.id}-owner-lessee`
                        )}
                        onChange={(e) =>
                          handleQuestionChange(
                            category.id,
                            `${question.id}-owner-lessee`,
                            e.target.value
                          )
                        }
                        disabled={disabled}
                      />
                    </div>
                  )}
                  <div className="form-check">
                    <input
                      type="radio"
                      name={question.id}
                      value="MORTGAGED"
                      checked={
                        getAnswerForQuestion(question.id) === "MORTGAGED"
                      }
                      className="form-check-input"
                      onChange={(e) =>
                        handleHomeOwnershipChange(
                          category.id,
                          question.id,
                          e.target.value
                        )
                      }
                      disabled={disabled}
                    />
                    <label className="form-check-label">MORTGAGED</label>
                  </div>
                  {(homeOwnership === "MORTGAGED" ||
                    getAnswerForQuestion(question.id) === "MORTGAGED") && (
                    <div className="form-group mt-2">
                      <label>Monthly Amortization:</label>
                      <input
                        type="text"
                        className="form-control"
                        value={getAnswerForQuestion(
                          `${question.id}-monthly-amort`
                        )}
                        onChange={(e) =>
                          handleQuestionChange(
                            category.id,
                            `${question.id}-monthly-amort`,
                            e.target.value
                          )
                        }
                        disabled={disabled}
                      />
                    </div>
                  )}
                </div>
              ) : category.subTitle ? (
                // Render radio inputs for other questions under categories with a subTitle
                <div>
                  <input
                    type="radio"
                    name={category.id} // group by category ID
                    value={question.id}
                    checked={getAnswerForQuestion(question.id)}
                    onChange={(e) =>
                      handleQuestionChange(
                        category.id,
                        question.id,
                        e.target.value
                      )
                    }
                    disabled={disabled}
                  />
                </div>
              ) : (
                // Render normal input for questions without a subTitle
                <div>
                  <input
                    type="text"
                    name={question.id}
                    className="form-control"
                    onChange={(e) =>
                      handleQuestionChange(
                        category.id,
                        question.id,
                        e.target.value
                      )
                    }
                    value={getAnswerForQuestion(question.id)}
                    // ref={(el) => (fieldRefs[question.id] = el)}
                    // className={`form-control ${
                    //   validationErrors?.[category.id]?.[question.id]
                    //     ? "is-invalid"
                    //     : ""
                    // }`}
                    disabled={disabled}
                  />
                  {validationErrors?.[category.id]?.[question.id] && (
                    <div className="invalid-feedback">
                      This field is required.
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default Form3;
