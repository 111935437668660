import React from "react";
import { InputFactory } from "nq-component";
import { getObjectUseCase, upsertUseCase } from "../../usecases/object";
import withRouter from "../../withRouter";
import BaseFormPage from "../../base/BaseFormPage";
import NavBar from "../../components/navbar";
import CreditForm1Presenter from "./CreditForm1Presenter";

import InputAdd from "../../components/InputAdd";
import QAFormCIPresenter from "./QAFormCIPresenter";
import Form1 from "../qa/components/Form1";

class QAFormCIPage extends BaseFormPage {
  constructor(props) {
    super(props);
    this.state = {
      answer: null,
      object: {},
      advanced: false,
      type: [],
      selectedForm: null,
      uploadRelation: [],
      validationErrors: {
        natureBusiness: false,
        operational: false,
        requesting: false,
        questions: {},
      },
      imageRequired: false,
    };
    this.presenter = new QAFormCIPresenter(
      this,
      getObjectUseCase(),
      upsertUseCase()
    );

    // Create refs for each field
    this.fieldRefs = {
      natureBusiness: React.createRef(),
      operational: React.createRef(),
      requesting: React.createRef(),
      questions: {},
    };
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  getObjectId() {
    return this.props.params && this.props.params.id;
  }

  getAnswerId() {
    return this.props.params.formId;
  }

  getCollectionName() {
    return "bvr_forms_mis";
  }

  getAnswer() {
    return this.state?.answer;
  }

  getUpload() {
    return this.state.object?.uploadRelation;
  }

  setAnswer(answer) {
    this.setState({ answer });
  }

  onClickAdvance() {
    this.setState({ advanced: !this.state.advanced });
  }

  onChangeObject(object) {
    this.presenter.onChangeObject(object);
  }
  handleInputChange = (id, value, choiceId = null) => {
    console.log("Input Change", value);
    const newObject = { ...this.state.object };
    const newValidationErrors = { ...this.state.validationErrors };

    // Clear validation error when the user starts typing
    if (newValidationErrors[id]) {
      newValidationErrors[id] = false;
    } else if (choiceId && newValidationErrors.questions[choiceId]) {
      newValidationErrors.questions[choiceId] = false;
    } else if (newValidationErrors.questions[id]) {
      newValidationErrors.questions[id] = false;
    }

    // Handle top-level input fields
    if (["natureBusiness", "operational", "requesting"].includes(id)) {
      newObject[id] = value;
    } else {
      // Check if the ID is from categories
      const categoryIndex = newObject.categories?.findIndex((c) => c.id === id);
      if (categoryIndex !== -1) {
        newObject.categories[categoryIndex].answer = value;
      } else {
        // Check if it's for a choice within a question
        let updated = false;
        newObject.categories?.forEach((question) => {
          question.questions?.forEach((perCategory) => {
            if (choiceId) {
              // Update the choice name if choiceId is provided
              const choiceIndex = perCategory.choices?.findIndex(
                (c) => c.id === choiceId
              );
              if (choiceIndex !== -1) {
                perCategory.choices[choiceIndex].text = value;
                updated = true;
              }
            } else if (perCategory.id === id) {
              // Update the answerQuestion if no choiceId is provided
              perCategory.answerQuestion = value;
              updated = true;
            }
          });
        });
        if (!updated) {
          console.log("No matching IDs found for update.");
        }
      }
    }
    this.setState({ object: newObject, validationErrors: newValidationErrors });
  };
  // handleInputChange = (id, value, choiceId = null) => {
  //   console.log("Input Change", value);
  //   const newObject = { ...this.state.object };
  //   const newValidationErrors = { ...this.state.validationErrors };

  //   // Clear validation error when the user starts typing
  //   if (newValidationErrors[id]) {
  //     newValidationErrors[id] = false;
  //   } else if (choiceId && newValidationErrors.questions[choiceId]) {
  //     newValidationErrors.questions[choiceId] = false;
  //   } else if (newValidationErrors.questions[id]) {
  //     newValidationErrors.questions[id] = false;
  //   }

  //   // Handle top-level input fields
  //   if (["natureBusiness", "operational", "requesting"].includes(id)) {
  //     newObject[id] = value;
  //   } else {
  //     // Check if the ID is from categories
  //     const categoryIndex = newObject.categories?.findIndex((c) => c.id === id);
  //     if (categoryIndex !== -1) {
  //       newObject.categories[categoryIndex].answer = value;
  //     } else {
  //       // Check if it's for a choice within a question
  //       let updated = false;
  //       newObject.categories?.forEach((question) => {
  //         question.questions?.forEach((perCategory) => {
  //           if (choiceId) {
  //             // Update the choice name if choiceId is provided
  //             const choiceIndex = perCategory.choices?.findIndex(
  //               (c) => c.id === choiceId
  //             );
  //             if (choiceIndex !== -1) {
  //               perCategory.choices[choiceIndex].text = value;
  //               updated = true;
  //             }
  //           } else if (perCategory.id === id) {
  //             // Update the answerQuestion if no choiceId is provided
  //             perCategory.answerQuestion = value;
  //             updated = true;
  //           }
  //         });
  //       });
  //       if (!updated) {
  //         console.log("No matching IDs found for update.");
  //       }
  //     }
  //   }
  //   this.setState({ object: newObject, validationErrors: newValidationErrors });
  // };

  validateForm = () => {
    const { object } = this.state;
    console.log("validate object", object);
    const requiredFields = ["natureBusiness", "operational", "requesting"];
    let isValid = true;
    const validationErrors = {
      natureBusiness: false,
      operational: false,
      requesting: false,
      questions: {},
    };

    // Validate top-level fields
    for (const field of requiredFields) {
      if (!object[field]) {
        validationErrors[field] = true;
        isValid = false;
        if (this.fieldRefs[field].current) {
          this.fieldRefs[field].current.scrollIntoView({ behavior: "smooth" });
          this.fieldRefs[field].current.focus();
        }
      }
    }

    if (object?.uploadRelation) {
      for (const item of object.uploadRelation) {
        if (!item.image) {
          isValid = false;

          // validationErrors.uploadRelation = true;
          this.setState({ imageRequired: true });
          break;
        }
      }
    }

    // Validate questions and their categories
    for (const question of object.questions || []) {
      if (question.questionType === "YES OR NO" && !question.yesOrNo) {
        if (this.fieldRefs.questions[question.id]) {
          validationErrors.questions[question.id] = true;
          isValid = false;
          this.fieldRefs.questions[question.id].current.scrollIntoView({
            behavior: "smooth",
          });
          this.fieldRefs.questions[question.id].current.focus();
        }
      }

      for (const perCategory of question.question_per_category || []) {
        if (
          perCategory.hasOwnProperty("answerQuestion") &&
          (perCategory.answerQuestion === undefined ||
            perCategory.answerQuestion === "")
        ) {
          if (this.fieldRefs.questions[perCategory.id]) {
            validationErrors.questions[perCategory.id] = true;
            isValid = false;
            this.fieldRefs.questions[perCategory.id].current.scrollIntoView({
              behavior: "smooth",
            });
            this.fieldRefs.questions[perCategory.id].current.focus();
          }
        }
      }
    }

    this.setState({ validationErrors });
    return isValid;
  };

  onSubmitForm(e) {
    e.preventDefault();
    this.presenter.submit();
    // if (this.validateForm()) {
    //   this.presenter.submit();
    // } else {
    //   console.log("Validation failed");
    // }
  }

  handleImagesChange = (items) => {
    // console.log("ITEMMMMMMM IMAGE: ", items.map);
    this.setState((prevState) => ({
      object: {
        ...prevState.object,
        uploadRelation: items,
      },
    }));
  };

  onChange(value, field) {
    const newObject = { ...this.state.object }; // Create a copy of the current object
    newObject[field] = value; // Update the specific field with the new value
    this.setState({ object: newObject }); // Update the state with the modified object
  }

  render() {
    const { object, answer, validationErrors } = this.state;
    const user = this.getCurrentUser();
    console.log("object", object);
    console.log("objectAnswer", answer);
    console.log("upload hehe", this.state?.uploadRelation);
    const advanced = this.state.advanced;
    const schema = this.getSchema(this.getCollectionName());

    if (!schema) return <h1>no schema</h1>;
    const label = this.getObjectId() === undefined ? "Add New " : "View ";
    return (
      <>
        <NavBar
          action={() => (
            <div className="dropdown dropstart d-inline-block">
              <i
                role="button"
                data-bs-toggle="dropdown"
                className="bi bi-three-dots-vertical"
              ></i>
              <div className="dropdown-menu fs-xs">
                <button
                  onClick={this.onClickAdvance.bind(this)}
                  className="dropdown-item py-3"
                >
                  <i className="bi bi-gear pe-2" />
                  Toggle Advance
                </button>
              </div>
            </div>
          )}
        />
        <div className="overflow-auto">
          <div className="h-100">
            <div className="p-3 p-lg-4">
              <h1 className="fw-bold mt-3 text-capitalize">
                {/* {label + (schema.label || this.getCollectionName())} */}
                View Business Verification
              </h1>

              <div className="mt-3 bg-white shadow rounded p-3 px-lg-5 py-lg-4">
                <form onSubmit={this.onSubmitForm.bind(this)}>
                  <div className="row g-3">
                    <div>
                      <label className="mb-2">NATURE OF BUSINESS</label>
                      <InputFactory
                        type="String"
                        field="natureBusiness"
                        object={answer}
                        onChange={this.onChange.bind(this)}
                        placeholder="Enter Nature of Business"
                        required
                      />
                    </div>
                    <div>
                      <label className="mb-2">OPERATIONAL DURING VISIT?</label>
                      <InputFactory
                        type="String"
                        field="operational"
                        object={answer}
                        onChange={this.onChange.bind(this)}
                        placeholder="Enter Operational During Visit"
                        required
                      />
                    </div>
                    <div>
                      <label className="mb-2">REQUESTING OFFICER & UNIT</label>
                      <InputFactory
                        type="String"
                        field="requesting"
                        object={answer}
                        onChange={this.onChange.bind(this)}
                        placeholder="Enter Requesting Officer & Unit"
                        required
                      />
                    </div>
                    <Form1
                      object={object}
                      answer={answer}
                      onChange={this.handleInputChange}
                      fieldRefs={this.fieldRefs}
                      validationErrors={validationErrors}
                      user={user}
                    />

                    <div>
                      <label className="mb-2">Remarks</label>
                      <InputFactory
                        type="String"
                        field="remarks"
                        _type="Text"
                        object={answer}
                        onChange={this.onChange.bind(this)}
                        required
                      />
                    </div>

                    <InputAdd
                      object={object}
                      onImagesChange={this.handleImagesChange}
                      user={user}
                      required={this.state.imageRequired}
                    />
                  </div>
                  <div className="mt-4">
                    <button
                      type="submit"
                      className="btn text-dark fs-sm me-3"
                      style={{ backgroundColor: "#fdcb5b" }}
                    >
                      <i className="bi bi-file-earmark-check me-2"></i>SAVE
                    </button>
                    <button
                      type="button"
                      className="btn btn-light fs-sm"
                      onClick={this.onClickBack.bind(this)}
                    >
                      GO BACK
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(QAFormCIPage);
