import BaseListPresenter from "../../../base/BaseListPresenter";
import { findObjectUseCase } from "../../../usecases/object";

class DashboardAgentPresenter extends BaseListPresenter {
  init() {
    const user = this.view.getCurrentUser();
    this.limit = 20;
    this.where = { agent: user.name };
    this.search = {};
    this.filter = {};
    this.include = ["all"];
    this.keys = undefined; // if keys are specified, only those keys will be returned
    this.sort = { createdAt: -1 };
    this.progress = true;
    this.reset();
    this.userAgent = [];
    this.newEndorseLetter = [];
    this.filterDate = {};
    this.filterLocation = "";
  }

  componentDidMount() {
    this.init();
    this.getAllEndorsement();
    return this.getObjects();
  }

  createQuery() {
    const skip = (this.current - 1) * this.limit;
    const query = {
      limit: this.limit,
      skip: skip,
      where: {
        ...this.where,
        ...this.search,
        ...this.filter,
        ...this.filterDate,
      },
      include: this.include,
    };
    if (this.sort) {
      query.sort = this.sort;
    }
    const keys = this.keys || this.view.getKeys() || [];
    if (keys.length > 0) {
      query.keys = keys;
    }
    return query;
  }
  async getObjects() {
    this.reset();
    await this.countObjects();
    await this.findObjects();
    await this.getAllEndorsement();
  }
  async findObjects() {
    const collection = this.view.getCollectionName();
    // const user = this.view.getCurrentUser();
    // const myQuery = {
    //   where: {
    //     id: user.id,
    //   },
    // };
    // const getEndorsement = await this.findObjectUseCase.execute(
    //   "users",
    //   myQuery
    // );

    // console.log("end", getEndorsement);

    const skip = (this.current - 1) * this.limit;
    const query = this.createQuery();

    try {
      this.showProgress();
      this.findObjectUseCase.abort();
      const objects = await this.findObjectUseCase.execute(
        "endorsement",
        query
      );
      this.objects = this.objects.concat(objects);
      console.log("my", this.objects);
      this.view.setTotal(this.objects.length);
      this.view.setObjects(this.objects);
      this.hideProgress();
    } catch (error) {
      this.hideProgress();
      this.view.showError(error);
    }
    this.progress = false;
  }

  async getAllEndorsement() {
    const user = this.view.getCurrentUser();
    const query = {
      include: ["location", "timeline"],
      where: {
        agent: user.name,
        ...this.filterDate,
      },
    };

    const object = await findObjectUseCase().execute("endorsement", query);
    console.log("getall", object);
    this.view.setState({ agentForm: object });
  }

  onChangeDate(where) {
    this.filterDate = where;
    this.getObjects();
  }

  calculatePendingInfo() {
    return {
      value: this.objects
        ?.filter((o) => o.dispatchType === "Pending")
        .map((o) => o.statusQA)?.length,
      percentage: 0,
    };
  }

  calculateNewInfo() {
    return {
      value: this.objects
        ?.filter((o) => o.dispatchType === "Work in Progress")
        .map((o) => o.statusQA)?.length,
      percentage: 0,
    };
  }
  calculateDisapprovedInfo() {
    return {
      value: this.objects
        ?.filter((o) => o.statusQA === "Disapprove")
        .map((o) => o.statusQA)?.length,
      percentage: 0,
    };
  }

  calculateQAInfo() {
    return {
      value: this.objects
        ?.filter((o) => o.status === "For QA")
        .map((o) => o.statusQA)?.length,
      percentage: 0,
    };
  }

  calculateDoneInfo() {
    return {
      value: this.objects
        ?.filter((o) => o.status === "Done")
        .map((o) => o.statusQA)?.length,
      percentage: 0,
    };
  }

  filterSubmit(where) {
    const locationId = where.locationP?.id;
    this.reset();
    this.filterLocation = locationId;
    this.getObjects();
  }
}

export default DashboardAgentPresenter;
