import BaseFormPresenter from "../../../base/BaseFormPresenter";
import { saveObjectUseCase, upsertUseCase } from "../../../usecases/object";

class ResidenceAuditFormPresenter extends BaseFormPresenter {
  componentDidMount() {
    this.init();
    this.getObject();
  }
  async getObject() {
    const collection = this.view.getCollectionName();
    const id = this.object?.id || this.view.getObjectId();
    if (id) {
      const params = {
        include: ["client", "categories", "categories.questions"],
      };
      try {
        this.view.showProgress();
        this.object = await this.getObjectUseCase.execute(collection, id, {
          params,
        });
        this.view.hideProgress();
        this.view.setObject(this.object);
      } catch (error) {
        this.view.hideProgress();
        this.view.showError(error);
      }
    }
  }

  // async save() {
  //   const collection = this.view.getCollectionName();
  //   if (this.object.id) {
  //     this.change.id = this.object.id;
  //   } else {
  //     this.change.acl = this.view.getAcl();
  //     this.change.clients = this.view.state.selectedUser.label;
  //     this.change.type = "EMPLOYEE VERIFICATION (EVR)";
  //   }
  //   try {
  //     await this.upsertUseCase.execute(collection, this.change);
  //     this.change = {};
  //   } catch (error) {
  //     throw error; // rethrow the error to be caught by the caller
  //   }
  // }

  onChange(value, field) {
    this.change[field] = value;
    this.object[field] = value;
    this.view.setObject(this.object);
  }
  init() {
    const collection = this.view.getCollectionName();
    const exam = this.view.getExamId();
    const query = {
      where: { id: exam?.id },
      include: [
        "categories",
        "categories.text",
        // "categories.questions.answers",
      ],
    };
    if (exam?.id) {
      this.view.showProgress();
      this.findObjectUseCase
        .execute("exams", query)
        .then(([object]) => {
          this.view.hideProgress();
          this.object = object;
          this.view.setObject(Object.assign({}, object));
        })
        .catch((error) => {
          this.view.hideProgress();
          this.view.showError(error);
        });
    }
  }

  submit() {
    const collection = this.view.getCollectionName();
    const object = this.view.getObject();

    this.view.showProgress();
    if (object.id) {
      const change = object;
      change.id = this.object.id;
      upsertUseCase()
        .execute(collection, change)
        .then(() => {
          this.view.hideProgress();
          this.view.navigateBack();
        })
        .catch((error) => {
          this.view.hideProgress();
          this.view.showError(error);
        });
    } else {
      const roles = this.view.getCurrentRoles();
      const aclRoles = roles.map((r) => `role:${r.name}`);
      const user = this.view.getCurrentUser();
      const acl = {
        read: ["*", user.id, aclRoles],
        write: ["*", user.id, aclRoles],
      };
      object.acl = acl;
      object.clients = this.view.state.selectedUser?.label;
      object.type = "RESIDENCE VERIFICATION (RVR)";
      delete object.agent;
      delete object.client;
      delete object.selectedUser;
      saveObjectUseCase()
        .execute(collection, object)
        .then(() => {
          this.view.hideProgress();
          this.view.navigateBack();
        })
        .catch((error) => {
          this.view.hideProgress();
          this.view.showError(error);
        });
    }
  }

  backClick() {
    // @todo check if object is un-save show discard
    this.view.navigateBack();
  }
}
export default ResidenceAuditFormPresenter;
