import React from "react";
import { Progress } from "nq-component";
import {
  findObjectUseCase,
  getObjectUseCase,
  upsertUseCase,
} from "../../../usecases/object";
import { restUseCase } from "../../../usecases/rest";
import NavBar from "../../../components/navbar";
import InputFactory from "../../../components/InputFactory";
import BaseFormPage from "../../../base/BaseFormPage";
import withRouter from "../../../withRouter";
import ExamQuestion from "../../../components/ExamQuestion";
import ResidenceAuditFormPresenter from "./ResidenceAuditFormPresenter";
import Select from "react-select";
import InputAdd from "../../../components/InputAdd";

class ResidenceAuditFormPage extends BaseFormPage {
  constructor(props) {
    super(props);
    this.state = {
      object: {
        selectedClient: {},
        selectedEndorser: [],
        categories: [],
        agent: [],
        selectedUser: null,
        uploadRelation: [],
      },
    };
    this.presenter = new ResidenceAuditFormPresenter(
      this,
      getObjectUseCase(),
      upsertUseCase(),
      restUseCase(),
      findObjectUseCase()
    );
    this.contractPDF = React.createRef();
  }

  componentDidMount() {
    this.presenter.componentDidMount();
    this.getUserAgent();
    this.getAllClient();
  }

  getCollectionName() {
    return "rvr_forms_mis";
  }

  getObject() {
    return this.state.object;
  }

  getExamId() {
    return this.getArgument();
  }

  onSubmitForm(e) {
    e.preventDefault();
    this.presenter.submit();
  }

  setObject(object) {
    this.setState({ object });
  }

  setUpload(upload) {
    this.setState((prevState) => ({
      object: {
        ...prevState.object,
        upload: upload,
      },
    }));
  }

  onChange(value, field) {
    this.presenter.onChange(value, field);
  }

  back() {
    this.navigateBack();
  }

  async getUserAgent() {
    const groupSelect = this.state.object?.selectedClient;
    const query = {
      where: {
        group: {
          id: groupSelect.value,
        },
      },
      include: ["all"],
    };
    const agent = await findObjectUseCase().execute("users", query);
    const agentOptions = agent?.map((agent) => ({
      value: agent.id,
      label: agent.name,
    }));
    this.setState({
      agent: agentOptions,
      isUserOptionsLoaded: true, // Mark userOptions as loaded
    });
  }

  getAcl() {
    const user = this.getCurrentUser();
    return {
      read: [
        "*",
        user.id,
        "role:MIS",
        "role:ENDORSER",
        "role:QA",
        "role:AGENTS",
      ],
      write: [
        "*",
        user.id,
        "role:MIS",
        "role:ENDORSER",
        "role:QA",
        "role:AGENTS",
      ],
    };
  }

  handleUserChange = (selectedUser) => {
    this.setState({ selectedUser });
    console.log("asas", selectedUser);
  };

  handleImagesChange = (items) => {
    this.setState((prevState) => ({
      object: {
        ...prevState.object,
        uploadRelation: items,
      },
    }));
  };

  handleClientChange = (selectedGroup) => {
    this.setState(
      (prevState) => ({
        object: {
          ...prevState.object,
          selectedClient: selectedGroup,
        },
      }),
      () => {
        this.getUserAgent();
      }
    );
  };

  handleUserChange = (selectedUsers) => {
    this.setState((prevState) => ({
      object: {
        ...prevState.object,
        selectedEndorser: selectedUsers,
      },
    }));
  };

  async getAllClient() {
    const client = await findObjectUseCase().execute("group");
    const clientOptions = client?.map((client) => ({
      value: client.id,
      label: client.name,
    }));
    this.setState({
      client: clientOptions,
      isClientOptionsLoaded: true, // Mark userOptions as loaded
    });
  }

  render() {
    const {
      object,
      agent,
      client,
      isUserOptionsLoaded,
      isClientOptionsLoaded,
    } = this.state;
    const userOptions = agent;
    const clientOptions = client;

    // Only compute selectedClients if userOptions are loaded
    const selectedClients = isUserOptionsLoaded
      ? (object.selectedEndorser || [])
          .map((user) =>
            userOptions.find((option) => option.value === user.value)
          )
          .filter(Boolean)
      : [];
    const user = this.getCurrentUser();
    const schema = this.getSchema(this.getCollectionName());
    if (!schema) return <Progress />;

    return (
      <>
        <NavBar />
        <div className="overflow-auto">
          <div className="p-3 p-lg-4">
            <div className="row align-items-center">
              <div className="col-auto d-flex align-items-center">
                <button
                  type="button"
                  className="btn btn-link"
                  style={{ padding: "0" }}
                >
                  <i
                    className="bi bi-arrow-left fs-4"
                    onClick={this.back.bind(this)}
                    style={{ color: "#DAB451", margin: "0" }}
                  ></i>
                </button>
              </div>

              <div className="col">
                <h1 className="fw-bold mt-3 text-capitalize">
                  {object && object.id ? "Edit RVR" : "Create New RVR"}
                </h1>
              </div>
            </div>

            <div className="row align-items-center">
              <div className="mt-2 bg-white rounded p-3 px-lg-5 py-lg-4">
                <form onSubmit={this.onSubmitForm.bind(this)}>
                  <div className="mb-3">
                    <label htmlFor="user">Client</label>
                    <Select
                      options={clientOptions}
                      placeholder="Select Clients"
                      value={this.state.object.selectedClient}
                      onChange={this.handleClientChange}
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="user">Endorser</label>
                    <Select
                      options={userOptions}
                      placeholder="Select Endorser"
                      value={selectedClients} // Set value to selectedClients array
                      isMulti={true} // Enable multiple selection
                      onChange={this.handleUserChange}
                    />
                  </div>

                  <ExamQuestion
                    object={object}
                    setObjectState={this.setObject.bind(this)}
                  />

                  <InputAdd
                    object={object}
                    onImagesChange={this.handleImagesChange}
                    user={user}
                  />
                  <div className="d-flex justify-content-center text-center mt-4">
                    <div className="me-3">
                      <button
                        type="button"
                        className="btn btn-light fs-sm"
                        onClick={this.back.bind(this)}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="">
                      <button
                        type="submit"
                        className="btn fs-sm me-3"
                        style={{ backgroundColor: "#dfbe68" }}
                      >
                        <i className="bi bi-file-earmark-check me-2"></i>SAVE
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(ResidenceAuditFormPage);
