// import React from "react";

// function Form3({ object, answer, onChange, fieldRefs, validationErrors }) {
//   console.log("Form2 Object:", object);
//   console.log("Form2 Answer:", answer);

//   const handleQuestionChange = (categoryId, answerId, value) => {
//     console.log("Question Change:", categoryId, answerId, value);
//     onChange(categoryId, answerId, value);
//   };

//   return (
//     <div className="row g-3 mb-3">
//       {object?.categories?.map((category) => (
//         <div className="col-md-12" key={category.id}>
//           <h4>{category?.title}</h4>
//           {category?.subTitle && <h5>{category.subTitle}</h5>}
//           {category?.questions?.map((question) => (
//             <div key={question.id}>
//               <h6>{question.question}</h6>
//               {category.subTitle ? (
//                 // Render radio inputs for questions under categories with a subTitle
//                 <div>
//                   <input
//                     type="radio"
//                     name={category.id} // group by category ID
//                     value={question.id}
//                     onChange={(e) =>
//                       handleQuestionChange(
//                         category.id,
//                         question.id,
//                         e.target.value
//                       )
//                     }
//                   />
//                 </div>
//               ) : (
//                 // Render normal input for questions without a subTitle
//                 <div>
//                   <input
//                     type="text"
//                     name={question.id}
//                     // value={
//                     //   answer?.find((ans) => ans?.question.id === question.id)
//                     //     ?.answer || ""
//                     // }
//                     onChange={(e) =>
//                       handleQuestionChange(
//                         category.id,
//                         question.id,
//                         e.target.value
//                       )
//                     }
//                     ref={(el) => (fieldRefs[question.id] = el)}
//                     className={`form-control ${
//                       validationErrors?.[category.id]?.[question.id]
//                         ? "is-invalid"
//                         : ""
//                     }`}
//                   />
//                   {validationErrors?.[category.id]?.[question.id] && (
//                     <div className="invalid-feedback">
//                       This field is required.
//                     </div>
//                   )}
//                 </div>
//               )}
//             </div>
//           ))}
//         </div>
//       ))}
//     </div>
//   );
// }

// export default Form3;

import React, { useState } from "react";

function Form3({ object, answer, onChange, fieldRefs, validationErrors }) {
  console.log("Form2 Object:", object);
  console.log("Form2 Answer:", answer);

  const [homeOwnership, setHomeOwnership] = useState("");

  const handleQuestionChange = (categoryId, questionId, value) => {
    console.log("Question Change:", categoryId, questionId, value);
    onChange(categoryId, questionId, value);
  };

  const handleHomeOwnershipChange = (categoryId, questionId, value) => {
    setHomeOwnership(value);
    handleQuestionChange(categoryId, questionId, value);
  };

  return (
    <div className="row g-3 mb-3">
      {object?.categories?.map((category) => (
        <div className="col-md-12" key={category.id}>
          <h4>{category?.title}</h4>
          {category?.subTitle && <h5>{category.subTitle}</h5>}
          {category?.questions?.map((question) => (
            <div className="mt-3" key={question.id}>
              <h6>{question.question}</h6>
              {question.question === "NEIGHBORHOOD:" ? (
                // Render radio buttons for the NEIGHBORHOOD question
                <div>
                  <div className="form-check">
                    <input
                      type="radio"
                      name={question.id}
                      value="MIXED"
                      className="form-check-input"
                      required
                      onChange={(e) =>
                        handleQuestionChange(
                          category.id,
                          question.id,
                          e.target.value
                        )
                      }
                    />
                    <label className="form-check-label">MIXED</label>
                  </div>
                  <div className="form-check">
                    <input
                      type="radio"
                      name={question.id}
                      value="COMMERCIAL"
                      className="form-check-input"
                      required
                      onChange={(e) =>
                        handleQuestionChange(
                          category.id,
                          question.id,
                          e.target.value
                        )
                      }
                    />
                    <label className="form-check-label">COMMERCIAL</label>
                  </div>
                  <div className="form-check">
                    <input
                      type="radio"
                      name={question.id}
                      value="RESIDENTIAL"
                      className="form-check-input"
                      required
                      onChange={(e) =>
                        handleQuestionChange(
                          category.id,
                          question.id,
                          e.target.value
                        )
                      }
                      placeholder="Your answer"
                    />
                    <label className="form-check-label">RESIDENTIAL</label>
                  </div>
                </div>
              ) : question.question === "NEIGHBORHOOD CLASS :" ? (
                // Render radio buttons for the NEIGHBORHOOD CLASS question
                <div>
                  <div className="form-check">
                    <input
                      type="radio"
                      name={question.id}
                      value="UPPER- MIDDLE CLASS"
                      className="form-check-input"
                      required
                      onChange={(e) =>
                        handleQuestionChange(
                          category.id,
                          question.id,
                          e.target.value
                        )
                      }
                    />
                    <label className="form-check-label">
                      UPPER- MIDDLE CLASS
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      type="radio"
                      name={question.id}
                      value="MIDDLE-LOW MIDDLE CLASS"
                      className="form-check-input"
                      required
                      onChange={(e) =>
                        handleQuestionChange(
                          category.id,
                          question.id,
                          e.target.value
                        )
                      }
                    />
                    <label className="form-check-label">
                      MIDDLE-LOW MIDDLE CLASS
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      type="radio"
                      name={question.id}
                      value="LOWER CLASS"
                      className="form-check-input"
                      required
                      onChange={(e) =>
                        handleQuestionChange(
                          category.id,
                          question.id,
                          e.target.value
                        )
                      }
                    />
                    <label className="form-check-label">LOWER CLASS</label>
                  </div>
                  <div className="form-check">
                    <input
                      type="radio"
                      name={question.id}
                      value="GREY CONCERN"
                      className="form-check-input"
                      required
                      onChange={(e) =>
                        handleQuestionChange(
                          category.id,
                          question.id,
                          e.target.value
                        )
                      }
                    />
                    <label className="form-check-label">GREY CONCERN</label>
                  </div>
                </div>
              ) : question.question === "HOME OWNERSHIP :" ? (
                // Render radio buttons for the HOME OWNERSHIP question
                <div>
                  <div className="form-check">
                    <input
                      type="radio"
                      name={question.id}
                      value="OWNED"
                      className="form-check-input"
                      required
                      onChange={(e) =>
                        handleHomeOwnershipChange(
                          category.id,
                          question.id,
                          e.target.value
                        )
                      }
                    />
                    <label className="form-check-label">OWNED</label>
                  </div>
                  <div className="form-check">
                    <input
                      type="radio"
                      name={question.id}
                      value="LIVING WITH RELATIVES OR OTHERS"
                      required
                      className="form-check-input"
                      onChange={(e) =>
                        handleHomeOwnershipChange(
                          category.id,
                          question.id,
                          e.target.value
                        )
                      }
                    />
                    <label className="form-check-label">
                      LIVING WITH RELATIVES OR OTHERS
                    </label>
                  </div>
                  {homeOwnership === "LIVING WITH RELATIVES OR OTHERS" && (
                    <div className="form-group mt-2">
                      <label>Specify:</label>
                      <input
                        type="text"
                        className="form-control"
                        required
                        onChange={(e) =>
                          handleQuestionChange(
                            category.id,
                            `${question.id}-specify`,
                            e.target.value
                          )
                        }
                        placeholder="Your answer"
                      />
                    </div>
                  )}
                  <div className="form-check">
                    <input
                      type="radio"
                      name={question.id}
                      value="RENTED"
                      className="form-check-input"
                      required
                      onChange={(e) =>
                        handleHomeOwnershipChange(
                          category.id,
                          question.id,
                          e.target.value
                        )
                      }
                    />
                    <label className="form-check-label">RENTED</label>
                  </div>
                  {homeOwnership === "RENTED" && (
                    <div className="form-group mt-2">
                      <label>Monthly Rental:</label>
                      <input
                        type="text"
                        className="form-control"
                        required
                        onChange={(e) =>
                          handleQuestionChange(
                            category.id,
                            `${question.id}-monthly-rental`,
                            e.target.value
                          )
                        }
                        placeholder="Your answer"
                      />
                      <label className="mt-2">
                        Owners/Lessee of House Occupied:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        required
                        onChange={(e) =>
                          handleQuestionChange(
                            category.id,
                            `${question.id}-owner-lessee`,
                            e.target.value
                          )
                        }
                        placeholder="Your answer"
                      />
                    </div>
                  )}
                  <div className="form-check">
                    <input
                      type="radio"
                      name={question.id}
                      value="MORTGAGED"
                      required
                      className="form-check-input"
                      onChange={(e) =>
                        handleHomeOwnershipChange(
                          category.id,
                          question.id,
                          e.target.value
                        )
                      }
                    />
                    <label className="form-check-label">MORTGAGED</label>
                  </div>
                  {homeOwnership === "MORTGAGED" && (
                    <div className="form-group mt-2">
                      <label>Monthly Amortization:</label>
                      <input
                        type="text"
                        className="form-control"
                        required
                        onChange={(e) =>
                          handleQuestionChange(
                            category.id,
                            `${question.id}-monthly-amort`,
                            e.target.value
                          )
                        }
                        placeholder="Your answer"
                      />
                    </div>
                  )}
                </div>
              ) : category.subTitle ? (
                // Render radio inputs for other questions under categories with a subTitle
                <div>
                  <input
                    type="radio"
                    name={category.id} // group by category ID
                    required
                    value={question.id}
                    onChange={(e) =>
                      handleQuestionChange(
                        category.id,
                        question.id,
                        e.target.value
                      )
                    }
                  />
                </div>
              ) : (
                // Render normal input for questions without a subTitle
                <div className="">
                  <input
                    type="text"
                    className="form-control "
                    required
                    name={question.id}
                    onChange={(e) =>
                      handleQuestionChange(
                        category.id,
                        question.id,
                        e.target.value
                      )
                    }
                    placeholder="Your answer"
                    // ref={(el) => (fieldRefs[question.id] = el)}
                    // className={`form-control ${
                    //   validationErrors?.[category.id]?.[question.id]
                    //     ? "is-invalid"
                    //     : ""
                    // }`}
                  />
                  {validationErrors?.[category.id]?.[question.id] && (
                    <div className="invalid-feedback">
                      This field is required.
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default Form3;
