import React from "react";
import withRouter from "../../withRouter";
import BaseListPage from "../../base/BaseListPage";
import { countObjectUseCase, findObjectUseCase } from "../../usecases/object";
import NavBar from "../../components/navbar";
import { Button, InfiniteScroll, Progress, dialog } from "nq-component";
import InputFactory from "../../components/InputFactory";
import Search from "../../components/Search";
import TableAudit from "../../components/TableAudit";
import DispatchPresenter from "./DispatchPresenter";
import DispatchDialog from "./components/DispatchDialog";

class DispatchPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new DispatchPresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase()
    );
  }

  getCollectionName() {
    return "endorsement";
  }

  onClickAdd() {
    this.presenter.onClickAdd();
  }

  onClickItem(index, field) {
    this.presenter.onClickItem(index, field);
  }

  onClickDispatchModal(index) {
    const dispatchObject = this.state.objects[index];
    console.log("obb", dispatchObject);
    const schema = this.getSchema("endorsement");
    dialog.fire({
      html: (
        <>
          <DispatchDialog
            schema={schema}
            schemas={this.getSchemas()}
            onCancel={() => dialog.close()}
            dispatchObject={dispatchObject}
          />
        </>
      ),
      footer: false,
    });
  }

  onCollapse(index, object) {
    return (
      <>
        <div className="d-flex">
          {object.picture && (
            <div
              className="mb-3"
              style={{
                width: "100px",
                height: "100px",
              }}
            >
              <img
                className="w-100 h-100"
                style={{ objectFit: "cover" }}
                src={object.picture}
              />
            </div>
          )}
          <ul className="list-unstyled ms-1">
            <li>
              <span className="ms-2 fw-bold" style={{ color: "#003869" }}>
                Report Type:
              </span>
              <span className="fs-sm text-nowrap"> {object.rType}</span>
            </li>
            <hr />
            <li>
              <span className="ms-2 fw-bold">
                {object.rType === "BUSINESS VERIFICATION (BVR)"
                  ? "Business Name: "
                  : "Subject Name: "}
              </span>
              <span className="fs-sm text-nowrap"> {object.residentName}</span>
            </li>

            <li>
              <span className="ms-2 fw-bold">
                {" "}
                {object.rType === "BUSINESS VERIFICATION (BVR)"
                  ? "Business Address: "
                  : "Residential Address: "}
              </span>
              <span className="fs-sm text-nowrap">
                {object.residentialAddress}, {object.barangay}, {object.city},{" "}
                {object.province} {object.region}
              </span>
            </li>
            <li>
              <span className="ms-2 fw-bold">
                {" "}
                {object.rType === "BUSINESS VERIFICATION (BVR)"
                  ? "Business Contact Number: "
                  : "Contact Number: "}
              </span>
              <span className="fs-sm text-nowrap">
                {object.residentContact}
              </span>
            </li>
            <hr />
            <li>
              <span className="ms-2 fw-bold">
                {" "}
                {object.rType === "BUSINESS VERIFICATION (BVR)"
                  ? "Subject Name: "
                  : "Employer's Name: "}
              </span>
              <span className="fs-sm text-nowrap"> {object.employerName}</span>
            </li>

            <li>
              <span className="ms-2 fw-bold">
                {" "}
                {object.rType === "BUSINESS VERIFICATION (BVR)"
                  ? "Business Address: "
                  : "Employer's Address: "}{" "}
              </span>
              <span className="fs-sm text-nowrap">
                {object.employerAddress}
              </span>
            </li>
            <li>
              <span className="ms-2 fw-bold">Contact Number: </span>
              <span className="fs-sm text-nowrap">
                {object.employerContact}
              </span>
            </li>
            <hr />
            <li>
              <span className="ms-2 fw-bold">Note:</span>
              <span
                className="fs-sm text-nowrap font-italic"
                style={{ fontStyle: "italic" }}
              >
                {" "}
                {object.note}
              </span>
            </li>
            {/* <hr />
            <li>
              <span className="ms-2 fw-light">Location:</span>
              <span className="fs-sm text-nowrap"> {object.location}</span>
            </li> */}
          </ul>
        </div>

        <div className="d-flex">
          <div className="me-1">
            <button
              className="btn"
              onClick={() => this.onClickDispatchModal(index)}
              style={{ backgroundColor: "#fdcb5b" }}
            >
              <i className="bi bi-pencil-square me-1"></i>DISPATCH
            </button>
          </div>
        </div>
      </>
    );
  }

  render() {
    const schema = this.getSchema(this.getCollectionName());
    const { objects, selected, count, progress } = this.state;
    console.log("clp", objects);

    if (!schema) return <Progress />;
    const user = this.getCurrentUser();
    return (
      <>
        <NavBar />
        <div className="overflow-auto">
          <InfiniteScroll
            className="h-100"
            loadMore={this.loadMore.bind(this)}
            hasMore={!progress && count > objects.length}
          >
            <div className="p-3 p-lg-4">
              <div className="d-flex justify-content-between align-items-center">
                <h1 className="fw-bold text-capitalize">
                  {schema.label || "For Dispatch"}
                </h1>
                {selected.length > 0 ? (
                  <div>
                    <span className="ms-2">Selected: </span>
                    <span className="fs-sm text-nowrap">{selected.length}</span>
                    <span className="ms-1">of </span>
                    <span className="fs-sm text-nowrap">{count}</span>
                  </div>
                ) : (
                  <div>
                    <span className="ms-2">Total: </span>
                    <span className="fs-sm text-nowrap">{objects.length}</span>
                    <span className="ms-1">of </span>
                    <span className="fs-sm text-nowrap">{count}</span>
                  </div>
                )}
              </div>
              {/* <div className="d-flex mt-3">
                {Object.keys(schema.filters || {}).map((field) => {
                  let { type, ...options } = schema.filters[field];
                  return (
                    <InputFactory
                      key={field}
                      className="ms-1"
                      type={type}
                      field={field}
                      where={{}}
                      onChange={this.onChangeFilter.bind(this, type)}
                      {...options}
                    />
                  );
                })}
              </div> */}
              <Search
                schemas={this.getSchemas()}
                className="mt-3"
                onSubmit={this.searchSubmit.bind(this)}
                fields={schema.fields}
              />

              <TableAudit
                fields={schema.fields}
                objects={objects}
                // selectable
                collapsable
                excludeFields={Object.keys(schema.fields).reduce(
                  (acc, key) => {
                    const options = schema.fields[key];
                    if (options.read === false) {
                      acc.push(key);
                    }
                    switch (options._type || options.type) {
                      case "Relation":
                      case "Array":
                      case "Object":
                      case "File":
                        acc.push(key);
                        break;
                      default:
                    }
                    return acc;
                  },
                  [
                    "acl",
                    "password",
                    "username",
                    "firstName",
                    "middleName",
                    "lastName",
                    "picture",
                    "signature",
                    "email",
                    "residentName",

                    "residentContact",
                    "employerName",
                    "employerAddress",
                    "employerContact",
                    "reportType",
                    "loanTitle",
                    "dispatchType",
                    "rate",
                    // "rType",
                    "statusQA",
                    "location",
                    "borrowerName",
                    "id",
                    "note",
                    "clientName",
                    "residentialAddress",
                  ]
                )}
                selected={selected}
                onSelect={this.onSelect.bind(this)}
                onSelectAll={this.onSelectAll.bind(this)}
                progress={progress}
                onClickItem={this.onClickItem.bind(this)}
                onCollapse={this.onCollapse.bind(this)}
                className="mt-3"
              />
            </div>
          </InfiniteScroll>
        </div>
      </>
    );
  }
}

export default withRouter(DispatchPage);
