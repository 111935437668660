import React, { useEffect, useState } from "react";

function Form1({
  object,
  answer,
  onChange,
  fieldRefs,
  validationErrors,
  user,
}) {
  const [selectedYesNo, setSelectedYesNo] = useState({});

  useEffect(() => {
    object.questions?.forEach((question) => {
      if (!fieldRefs?.questions[question.id]) {
        fieldRefs.questions[question.id] = React.createRef();
      }
      question.question_per_category?.forEach((perCategory) => {
        if (!fieldRefs.questions[perCategory.id]) {
          fieldRefs.questions[perCategory.id] = React.createRef();
        }
      });
    });
  }, [object, fieldRefs]);

  // const handleInputChange = (questionId, value, choiceId = null) => {
  //   console.log("VALUESSSSSS: ", value);
  //   const answerIndex = answer.answers.findIndex(
  //     (ans) => ans.question.id === questionId
  //   );

  //   if (answerIndex >= 0) {
  //     if (choiceId) {
  //       // If there's a choiceId, update or add the response in the choices array
  //       const choiceIndex = answer.answers[answerIndex].choices.findIndex(
  //         (ch) => ch.id === choiceId
  //       );

  //       if (choiceIndex >= 0) {
  //         answer.answers[answerIndex].choices[choiceIndex].text = value;
  //       } else {
  //         answer.answers[answerIndex].choices.push({
  //           id: choiceId,
  //           text: value,
  //         });
  //       }
  //     } else {
  //       // If no choiceId, update the response directly
  //       // answer.answers[answerIndex].answer = value;
  //     }
  //   } else {
  //     // If the question is not yet in the answers array, add it

  //     const newAnswer = {
  //       question: { id: questionId },
  //       answers: choiceId ? "" : value, // Response is empty if it's a choice
  //       choices: choiceId ? [{ id: choiceId, text: value }] : [],
  //     };

  //     answer.answers.push(newAnswer);
  //   }

  //   console.log("Updated ANSWERS:", answer);
  // };

  const handleInputChange = (questionId, value, choiceId = null) => {
    const answerIndex = answer.answers.findIndex(
      (ans) => ans.question.id === questionId
    );

    if (answerIndex >= 0) {
      if (choiceId) {
        const choiceIndex = answer.answers[answerIndex].choices.findIndex(
          (ch) => ch.id === choiceId
        );

        if (choiceIndex >= 0) {
          // Update existing choice
          answer.answers[answerIndex].choices[choiceIndex].text = value;
        } else {
          // Add new choice
          answer.answers[answerIndex].choices.push({
            id: choiceId,
            text: value,
          });
        }
      } else {
        // Update the answer if no choiceId is provided
        answer.answers[answerIndex].answers = value;
      }
    } else {
      const newAnswer = {
        question: { id: questionId },
        answers: choiceId ? "" : value, // Response is empty if it's a choice
        choices: choiceId ? [{ id: choiceId, text: value }] : [],
      };
      answer.answers.push(newAnswer);
    }

    console.log("Updated ANSWERS:", answer);
  };

  const handleYesNoChange = (categoryId, value) => {
    setSelectedYesNo((prev) => ({
      ...prev,
      [categoryId]: value,
    }));
    handleInputChange(categoryId, value);
  };

  const getInputClassName = (error) => {
    return `form-control${error ? " is-invalid" : ""}`;
  };

  return (
    <div className="row g-3 mb-3">
      <div className="mt-3">
        {object?.categories?.map((category) => (
          <div key={category.id} className="mt-3">
            {/* YES OR NO logic */}
            {category.questionType === "YES OR NO" && (
              <>
                <h6>{category.questionYes}</h6>
                <div className="d-flex">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      required
                      type="radio"
                      name={`yes-no-group-${category.id}`} // Grouping by category id
                      id={`yes-radio-${category.id}`}
                      onChange={() => handleYesNoChange(category.id, "Yes")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`yes-radio-${category.id}`}
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check ms-3">
                    <input
                      className="form-check-input"
                      required
                      type="radio"
                      name={`yes-no-group-${category.id}`} // Grouping by category id
                      id={`no-radio-${category.id}`}
                      onChange={() => handleYesNoChange(category.id, "No")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`no-radio-${category.id}`}
                    >
                      No
                    </label>
                  </div>
                </div>

                {/* Conditionally render the questions if "Yes" is selected */}
                {selectedYesNo[category.id] === "Yes" && (
                  <div className="mt-3">
                    {category.questions?.length > 0
                      ? category.questions.map((question) => (
                          <div key={question.id} className="mt-3">
                            <h6>{question.question}</h6>
                            {question.choices?.map((choice) => (
                              <div
                                className="d-flex mb-2"
                                key={`${question.id}-choice-${choice.id}`}
                              >
                                <input
                                  type="text"
                                  required
                                  className={getInputClassName(
                                    validationErrors?.questions[question.id]
                                  )}
                                  ref={fieldRefs?.questions[question.id]}
                                  onChange={(e) =>
                                    handleInputChange(
                                      question.id,
                                      e.target.value,
                                      choice.id
                                    )
                                  }
                                  placeholder={
                                    validationErrors?.questions[question.id]
                                      ? "Please fill this field"
                                      : "Your answer"
                                  }
                                />
                              </div>
                            ))}
                          </div>
                        ))
                      : // <p>No additional questions available.</p>
                        null}
                  </div>
                )}

                {selectedYesNo[category.id] === "No" && (
                  <div className="mt-3">
                    {category.questions?.length > 0 &&
                      category.questions.map((question) => (
                        <div key={question.id}>
                          <h6 className="d-none">{question.question}</h6>
                          {question.choices?.forEach((choice) => {
                            handleInputChange(question.id, "", choice.id);
                          })}
                        </div>
                      ))}
                  </div>
                )}
              </>
            )}

            {/* LABEL logic */}
            {category.questionType === "LABEL" && (
              <>
                <h6>{category.questionYes}</h6>
                {category.questions?.map((question) => (
                  <div className="col-md-12 ms-3" key={question.id}>
                    <h6>{question.question}</h6>
                    {question.choices?.map((choice, choiceIndex) => (
                      <div
                        className="d-flex mb-2"
                        key={`${question.id}-choice-${choiceIndex}`}
                      >
                        <input
                          type="text"
                          required
                          className={getInputClassName(
                            validationErrors?.questions[question.id]
                          )}
                          ref={fieldRefs?.questions[question.id]}
                          onChange={(e) =>
                            handleInputChange(
                              question.id,
                              e.target.value,
                              choice.id
                            )
                          }
                          placeholder={
                            validationErrors?.questions[question.id]
                              ? "Please fill this field"
                              : "Your answer"
                          }
                        />
                      </div>
                    ))}
                  </div>
                ))}
              </>
            )}

            {/* MULTIPLE CHOICE logic */}
            {category.questionType === "MULTIPLE CHOICE" && (
              <>
                {category.questions?.map((question) => {
                  console.log("QUESTIONS : ", question);
                  return (
                    <div className="col-md-12 mt-3" key={question.id}>
                      <h6>{question.question}</h6>
                      {question.choices?.map((choice, choiceIndex) => (
                        <div
                          className="d-flex "
                          key={`${question.id}-choice-${choiceIndex}`}
                        >
                          <p className="me-2 ms-3">{choiceIndex + 1}.</p>
                          <p>{choice.text}</p>
                        </div>
                      ))}
                      <input
                        type="text"
                        required
                        className={getInputClassName(
                          validationErrors?.questions[question.id]
                        )}
                        // value={"HAHAHAHAHHAHA"}
                        ref={fieldRefs?.questions[question.id]}
                        onChange={(e) =>
                          handleInputChange(question.id, e.target.value)
                        }
                        placeholder={
                          validationErrors?.questions[question.id]
                            ? "Please fill this field"
                            : "Your answer"
                        }
                      />
                    </div>
                  );
                })}
              </>
            )}

            {/* INFORMANTS logic */}
            {category.questionType === "INFORMANTS" && (
              <>
                {category.questions?.map((question) => (
                  <div className="col-md-12 mb-2" key={question.id}>
                    <h6>{question.question}</h6>
                    {question.choices?.map((choice, choiceIndex) => (
                      <div
                        className="d-flex"
                        key={`${question.id}-choice-${choiceIndex}`}
                      >
                        <input
                          type="text"
                          required
                          className={getInputClassName(
                            validationErrors?.questions[question.id]
                          )}
                          ref={fieldRefs?.questions[question.id]}
                          onChange={(e) =>
                            handleInputChange(
                              question.id,
                              e.target.value,
                              choice.id
                            )
                          }
                          placeholder={
                            validationErrors?.questions[question.id]
                              ? "Please fill this field"
                              : "Your answer"
                          }
                        />
                      </div>
                    ))}
                  </div>
                ))}
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Form1;
