import React from "react";
import NavBar from "../components/navbar";

const ConstructionPage = () => {
  return (
    <>
      <NavBar />
      <div className="card" style={styles.container}>
        <img
          src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEiIZ_BjpWFXnflq9YW5rnmQ_FV1g5K5Am5FfJKSRjI_KqqNX6tn6c2sbWutMr5u5aMbIi9TGxLtN6kTtDxuc_53vzUdEV6id3vT3lqWBWS5TGoaw7hq4pADmExiE_VOFK7NsM_-DXJYamh3/s640/underconstruction.jpg"
          alt="Under Construction"
          style={styles.image}
        />
        <h1 style={styles.title}>Page Under Construction</h1>
        <p style={styles.description}>
          We are working hard to bring you this page. Please check back later
          for updates.
        </p>
      </div>
    </>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    // backgroundColor: "#f0f0f0",
    textAlign: "center",
    padding: "20px",
    margin: "20px",
  },
  image: {
    maxWidth: "50%",
    height: "auto",
    marginBottom: "20px",
  },
  title: {
    fontSize: "32px",
    color: "#333",
    marginBottom: "10px",
  },
  description: {
    fontSize: "18px",
    color: "#666",
    marginBottom: "20px",
  },
  button: {
    padding: "10px 20px",
    fontSize: "16px",
    color: "#fff",
    backgroundColor: "#007bff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
};

export default ConstructionPage;
