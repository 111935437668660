import React from "react";
import click from "../../click";
import classNames from "../../classNames";
import Signature from "../Signature";
import canvasToBlob from "../../canvasToBlob";
import { dialog } from "nq-component";

function InputSignature({ className, value, onChange, saveImage }) {
  const canvasRef = React.useRef(null);
  const [progress, setProgress] = React.useState(0);
  const [image, setImage] = React.useState(null);

  function readImage(file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      setImage(e.target.result);
    };
    reader.readAsDataURL(file);
  }

  function onFileSelected(file) {
    readImage(file);
    const options = {
      progress: (value) => setProgress(value * 100),
    };
    saveImage
      .execute(file, options)
      .then((result) => {
        setImage(result.url);
        setProgress(0);
        onChange(result.url);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  function onClick() {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "img";
    input.onchange = (e) => {
      const [file] = e.target.files;
      file && onFileSelected(file);
    };
    click(input);
  }

  function pinClick(e) {
    e.stopPropagation();
    dialog.fire({
      html: <Signature setRef={canvasRef} />,
      positiveButton: "SAVE",
      onPositiveClick: () => {
        const dataURL = canvasRef.current.toDataURL("image/png");
        const canvas = canvasRef.current;
        setImage(dataURL);
        const options = {
          progress: (value) => setProgress(value * 100),
        };
        canvasToBlob(canvas, "signature", "image/png")
          .then((blob) => saveImage.execute(blob, options))
          .then((result) => {
            setImage(result.url);
            setProgress(0);
            onChange(result.url);
          })
          .catch((error) => {
            console.log("error", error);
          });
      },
    });
  }

  return (
    <div
      onClick={onClick}
      className={classNames(
        "rounded bg-light d-flex position-relative cursor-pointer",
        className
      )}
      style={{ height: "250px" }}
    >
      <div className="position-absolute h-100 w-100 d-flex justify-content-center">
        <img className="img-fluid" src={image || value} />
      </div>
      {!(image || value) && (
        <div className="position-absolute h-100 w-100 d-flex">
          <i className="bi bi-camera-fill display-3 text-dark m-auto" />
        </div>
      )}
      {progress > 0 && (
        <div className="position-absolute h-100 w-100 bg-black bg-opacity-50" />
      )}
      {progress > 0 && (
        <div className="position-absolute h-100 w-100 d-flex">
          <h3 className="m-auto text-white">{progress.toFixed(0)}%</h3>
        </div>
      )}
      <div className="position-absolute w-100 text-end p-2">
        <button type="button" onClick={pinClick} className="btn btn-secondary">
          <i className="bi bi-pencil"></i>
        </button>
      </div>
    </div>
  );
}

export default InputSignature;
