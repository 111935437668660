import React from "react";
import { getObjectUseCase, upsertUseCase } from "../../usecases/object";
import withRouter from "../../withRouter";
import BaseFormPage from "../../base/BaseFormPage";
import NavBar from "../../components/navbar";
import Form2 from "./components/Form2";
import InputAdd from "../../components/InputAdd";
import { InputFactory } from "nq-component";
import QAFormCIPresenter3 from "./QAFormCIPresenter3";
import Form3 from "../qa/components/Form3";

class QAFormCIPage3 extends BaseFormPage {
  constructor(props) {
    super(props);
    this.state = {
      answer: null,
      object: {},
      advanced: false,
      type: [],
      selectedForm: null,
      uploadRelation: [],
      validationErrors: {},
      imageRequired: false,
    };
    this.presenter = new QAFormCIPresenter3(
      this,
      getObjectUseCase(),
      upsertUseCase()
    );

    this.fieldRefs = {};
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  getObjectId() {
    return this.props.params && this.props.params.id;
  }

  getAnswer() {
    return this.state?.answer;
  }
  getAnswerId() {
    return this.props.params.formId;
  }

  getUpload() {
    return this.state.object?.uploadRelation;
  }

  setAnswer(answer) {
    this.setState({ answer });
  }

  getCollectionName() {
    return "rvr_forms_mis";
  }

  onClickAdvance() {
    this.setState({ advanced: !this.state.advanced });
  }

  onChangeObject(object) {
    this.setState({ object }); // Ensure state is updated properly
  }

  // handleInputChange = (categoryId, answerId, value) => {
  //   console.log("Input Change:", categoryId, answerId, value);
  //   const newObject = { ...this.state.object };
  //   const newValidationErrors = { ...this.state.validationErrors };

  //   // Clear validation error when the user starts typing
  //   if (
  //     newValidationErrors[categoryId] &&
  //     newValidationErrors[categoryId][answerId]
  //   ) {
  //     newValidationErrors[categoryId][answerId] = false;
  //   }

  //   newObject.categories = newObject.categories.map((category) => {
  //     if (category.id === categoryId) {
  //       return {
  //         ...category,
  //         text: category.text.map((textItem) => {
  //           return {
  //             ...textItem,
  //             answers: textItem.answers.map((answerItem) => {
  //               if (answerItem.id === answerId) {
  //                 return { ...answerItem, answer: value };
  //               }
  //               return answerItem;
  //             }),
  //           };
  //         }),
  //       };
  //     }
  //     return category;
  //   });

  //   this.setState({ object: newObject, validationErrors: newValidationErrors });
  // };

  handleInputChange = (categoryId, questionId, value) => {
    this.setState((prevState) => {
      const existingAnswerIndex = prevState.answer.answers.findIndex(
        (ans) => ans.question.id === questionId
      );

      let updatedAnswers;

      if (existingAnswerIndex > -1) {
        // Update existing answer
        updatedAnswers = prevState.answer.answers.map((ans, index) =>
          index === existingAnswerIndex ? { ...ans, answer: value } : ans
        );
      } else {
        // Add new answer
        updatedAnswers = [
          ...prevState.answer.answers,
          {
            question: { id: questionId },
            answer: value,
          },
        ];
      }

      return {
        answer: {
          ...prevState.answer,
          answers: updatedAnswers,
        },
      };
    });
  };

  validateForm = () => {
    const { object } = this.state;
    let isValid = true;
    const validationErrors = {};

    object.categories?.forEach((category) => {
      category.text?.forEach((textItem) => {
        textItem.answers?.forEach((answerItem) => {
          if (!answerItem.answer) {
            if (!validationErrors[category.id]) {
              validationErrors[category.id] = {};
            }
            validationErrors[category.id][answerItem.id] = true;
            isValid = false;
            if (
              this.fieldRefs[answerItem.id] &&
              this.fieldRefs[answerItem.id].current
            ) {
              this.fieldRefs[answerItem.id].current.scrollIntoView({
                behavior: "smooth",
              });
              this.fieldRefs[answerItem.id].current.focus();
            }
          }
        });
      });
    });

    if (object?.uploadRelation) {
      for (const item of object.uploadRelation) {
        if (!item.image) {
          isValid = false;

          // validationErrors.uploadRelation = true;
          this.setState({ imageRequired: true });
          break;
        }
      }
    }

    this.setState({ validationErrors });
    return isValid;
  };

  onSubmitForm(e) {
    e.preventDefault();

    if (!this.validateForm()) {
      this.presenter.submit();
    } else {
      console.log("Validation failed");
    }
  }

  handleImagesChange = (items) => {
    this.setState((prevState) => ({
      object: {
        ...prevState.object,
        uploadRelation: items,
      },
    }));
  };

  render() {
    const object = this.state.object;
    const answer = this.state.answer;
    console.log("Updated Answers: ", answer);
    const advanced = this.state.advanced;
    const schema = this.getSchema(this.getCollectionName());
    const user = this.getCurrentUser();

    if (!schema) return <h1>No schema</h1>;
    const label = this.getObjectId() === undefined ? "Add New " : "View ";
    return (
      <>
        <NavBar
          action={() => (
            <div className="dropdown dropstart d-inline-block">
              <i
                role="button"
                data-bs-toggle="dropdown"
                className="bi bi-three-dots-vertical"
              ></i>
              <div className="dropdown-menu fs-xs">
                <button
                  onClick={this.onClickAdvance.bind(this)}
                  className="dropdown-item py-3"
                >
                  <i className="bi bi-gear pe-2" />
                  Toggle Advance
                </button>
              </div>
            </div>
          )}
        />
        <div className="overflow-auto">
          <div className="h-100">
            <div className="p-3 p-lg-4">
              <h1 className="fw-bold mt-3 text-capitalize">
                {label + (schema.label || this.getCollectionName())}
              </h1>

              <div className="mt-3 bg-white shadow rounded p-3 px-lg-5 py-lg-4">
                <form onSubmit={this.onSubmitForm.bind(this)}>
                  <div className="row g-3">
                    <div>
                      <label className="mb-1">Account Name</label>
                      <InputFactory
                        type="String"
                        field="accountName"
                        object={answer}
                        onChange={this.onChange.bind(this)}
                        placeholder="Enter account name"
                        required
                      />
                    </div>
                    <Form3
                      object={object}
                      answer={answer}
                      onChange={this.handleInputChange}
                      fieldRefs={this.fieldRefs}
                      validationErrors={this.state.validationErrors}
                    />
                    <div>
                      <label className="mb-2">Remarks 1</label>
                      <InputFactory
                        type="String"
                        field="remarks"
                        _type="Text"
                        object={answer}
                        onChange={this.onChange.bind(this)}
                        required
                      />
                    </div>

                    <div>
                      <label className="mb-2">Remarks 2</label>
                      <InputFactory
                        type="String"
                        field="remarks2"
                        _type="Text"
                        object={answer}
                        onChange={this.onChange.bind(this)}
                        required
                      />
                    </div>
                    <InputAdd
                      object={object}
                      onImagesChange={this.handleImagesChange}
                      user={user}
                      required={this.state.imageRequired}
                    />
                  </div>
                  <div className="mt-4">
                    <button
                      type="submit"
                      className="btn text-dark fs-sm me-3"
                      style={{ backgroundColor: "#fdcb5b" }}
                    >
                      <i className="bi bi-file-earmark-check me-2"></i>SAVE
                    </button>
                    <button
                      type="button"
                      className="btn btn-light fs-sm"
                      onClick={this.onClickBack.bind(this)}
                    >
                      GO BACK
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(QAFormCIPage3);
