// import React from "react";
// import InputFactory from "../../../components/InputFactory";

// function Form2({ object, onChange, disabled }) {
//   console.log("Form2 Object:", object);

//   const handleQuestionChange = (categoryId, answerId, value) => {
//     console.log("Question Change:", categoryId, answerId, value);
//     onChange(categoryId, answerId, value);
//   };

//   return (
//     <div className="row g-3 mb-3">
//       {object?.categories?.map((category) => (
//         <div className="col-md-12" key={category.id}>
//           <h4>{category?.title}</h4>
//           {category?.text?.map((textItem) => (
//             <div key={textItem.id}>
//               <h6>{textItem.text}</h6>
//               {textItem.answers.map((answerItem) => (
//                 <input
//                   key={answerItem.id}
//                   className="form-control mb-2"
//                   type="text"
//                   value={answerItem.answer || ""}
//                   onChange={(e) =>
//                     handleQuestionChange(
//                       category.id,
//                       answerItem.id,
//                       e.target.value
//                     )
//                   }
//                   placeholder={`Fill up this field`}
//                   disabled={disabled}
//                 />
//               ))}
//             </div>
//           ))}
//         </div>
//       ))}

//       {/* <InputFactory
//         type="Relation"
//         _type="Related"
//         field="upload"
//         target="residence_upload"
//         object={object}
//         onChange={onChange}
//       /> */}
//     </div>
//   );
// }

// export default Form2;

// // import React, { useEffect } from "react";
// // import InputFactory from "../../../components/InputFactory";

// // function Form2({ object, onChange, fieldRefs, validationErrors, disabled }) {
// //   console.log("Form2 Object:", object);

// //   useEffect(() => {
// //     // Initialize refs for answers
// //     object.categories?.forEach((category) => {
// //       category.text?.forEach((textItem) => {
// //         textItem.answers?.forEach((answerItem) => {
// //           if (!fieldRefs[answerItem.id]) {
// //             fieldRefs[answerItem.id] = React.createRef();
// //           }
// //         });
// //       });
// //     });
// //   }, [object, fieldRefs]);

// //   const handleQuestionChange = (categoryId, answerId, value) => {
// //     console.log("Question Change:", categoryId, answerId, value);
// //     onChange(categoryId, answerId, value);
// //   };

// //   const getInputClassName = (categoryId, answerId) => {
// //     return `form-control${
// //       validationErrors[categoryId] && validationErrors[categoryId][answerId]
// //         ? " is-invalid"
// //         : ""
// //     }`;
// //   };

// //   return (
// //     <div className="row g-3 mb-3">
// //       {object?.categories?.map((category) => (
// //         <div className="col-md-12" key={category.id}>
// //           <h4>{category?.title}</h4>
// //           {category?.text?.map((textItem) => (
// //             <div key={textItem.id}>
// //               <h6>{textItem.text}</h6>
// //               {textItem.answers.map((answerItem) => (
// //                 <input
// //                   key={answerItem.id}
// //                   className={getInputClassName(category.id, answerItem.id)}
// //                   type="text"
// //                   ref={fieldRefs[answerItem.id]}
// //                   value={answerItem.answer || ""}
// //                   onChange={(e) =>
// //                     handleQuestionChange(
// //                       category.id,
// //                       answerItem.id,
// //                       e.target.value
// //                     )
// //                   }
// //                   placeholder={`Fill up this field`}
// //                   disabled={disabled}
// //                 />
// //               ))}
// //             </div>
// //           ))}
// //         </div>
// //       ))}

// //       <InputFactory
// //         type="Relation"
// //         _type="Related"
// //         field="upload"
// //         target="residence_upload"
// //         object={object}
// //         onChange={onChange}
// //       />
// //     </div>
// //   );
// // }

// // export default Form2;

// import React from "react";

// function Form2({ object, answer, onChange, fieldRefs, validationErrors }) {
//   console.log("Form2 Object:", object);
//   console.log("Form2 Answer:", answer);

//   const handleQuestionChange = (categoryId, answerId, value) => {
//     console.log("Question Change:", categoryId, answerId, value);
//     onChange(categoryId, answerId, value);
//   };

//   return (
//     <div className="row g-3 mb-3">
//       {object?.categories?.map((category) => (
//         <div className="col-md-12" key={category.id}>
//           <h4>{category?.title}</h4>
//           {category?.subTitle && <h5>{category.subTitle}</h5>}
//           {category?.questions?.map((question) => (
//             <div key={question.id}>
//               <h6>{question.question}</h6>
//               {category.subTitle ? (
//                 // Render radio inputs for questions under categories with a subTitle
//                 <div>
//                   <input
//                     type="radio"
//                     name={category.id} // group by category ID
//                     value={question.id}
//                     onChange={(e) =>
//                       handleQuestionChange(
//                         category.id,
//                         question.id,
//                         e.target.value
//                       )
//                     }
//                   />
//                 </div>
//               ) : (
//                 // Render normal input for questions without a subTitle
//                 <div>
//                   <input
//                     type="text"
//                     name={question.id}
//                     // value={
//                     //   answer?.find((ans) => ans?.question.id === question.id)
//                     //     ?.answer || ""
//                     // }
//                     onChange={(e) =>
//                       handleQuestionChange(
//                         category.id,
//                         question.id,
//                         e.target.value
//                       )
//                     }
//                     // ref={(el) => (fieldRefs[question.id] = el)}
//                     // className={`form-control ${
//                     //   validationErrors?.[category.id]?.[question.id]
//                     //     ? "is-invalid"
//                     //     : ""
//                     // }`}
//                   />
//                   {validationErrors?.[category.id]?.[question.id] && (
//                     <div className="invalid-feedback">
//                       This field is required.
//                     </div>
//                   )}
//                 </div>
//               )}
//             </div>
//           ))}
//         </div>
//       ))}
//     </div>
//   );
// }

// export default Form2;
// import React from "react";

// function Form2({
//   object,
//   answer,
//   onChange,
//   disabled,
//   fieldRefs,
//   validationErrors,
// }) {
//   console.log("Form2 Object:", object);
//   console.log("Form2 Answer:", answer);

//   const handleQuestionChange = (categoryId, questionId, value) => {
//     console.log("Question Change:", categoryId, questionId, value);
//     onChange(categoryId, questionId, value);
//   };

//   const getAnswerForQuestion = (questionId) => {
//     const foundAnswer = answer.answers?.find(
//       (ans) => ans?.question?.id === questionId
//     );
//     return foundAnswer ? foundAnswer.answer : "";
//   };

//   return (
//     <div className="row g-3 mb-3">
//       {object?.categories?.map((category) => (
//         <div className="col-md-12" key={category.id}>
//           <h4>{category?.title}</h4>
//           {category?.subTitle && <h5>{category.subTitle}</h5>}
//           {category?.questions?.map((question) => (
//             <div key={question.id}>
//               <h6>{question.question}</h6>
//               {category.subTitle ? (
//                 // Render radio inputs for questions under categories with a subTitle
//                 <div>
//                   <input
//                     type="radio"
//                     name={category.id} // group by category ID
//                     value={question.id}
//                     checked={getAnswerForQuestion(question.id) === question.id}
//                     onChange={(e) =>
//                       handleQuestionChange(
//                         category.id,
//                         question.id,
//                         e.target.value
//                       )
//                     }
//                     disabled={disabled}
//                   />
//                 </div>
//               ) : (
//                 // Render normal input for questions without a subTitle
//                 <div>
//                   <input
//                     type="text"
//                     name={question.id}
//                     className="form-control"
//                     value={getAnswerForQuestion(question.id)}
//                     onChange={(e) =>
//                       handleQuestionChange(
//                         category.id,
//                         question.id,
//                         e.target.value
//                       )
//                     }
//                     disabled={disabled}
//                   />
//                   {validationErrors?.[category.id]?.[question.id] && (
//                     <div className="invalid-feedback">
//                       This field is required.
//                     </div>
//                   )}
//                 </div>
//               )}
//             </div>
//           ))}
//         </div>
//       ))}
//     </div>
//   );
// }

// export default Form2;
import React from "react";

function Form2({
  object,
  answer,
  onChange,
  disabled,
  fieldRefs,
  validationErrors,
}) {
  console.log("Form2 Object:", object);
  console.log("Form2 Answer:", answer);

  const handleQuestionChange = (categoryId, questionId, value) => {
    console.log("Question Change:", categoryId, questionId, value);
    onChange(categoryId, questionId, value);
  };

  const getAnswerForQuestion = (questionId) => {
    const foundAnswer = answer.answers?.find(
      (ans) => ans?.question?.id === questionId
    );
    return foundAnswer ? foundAnswer.answer : "";
  };

  return (
    <div className="row g-3 mb-3">
      {object?.categories?.map((category, index) => (
        <div className="col-md-12" key={category.id}>
          {index === 0 && <h4>{category?.title}</h4>}
          {category?.subTitle && <h5>{category.subTitle}</h5>}
          {category?.questions?.map((question) => (
            <div key={question.id}>
              <h6>{question.question}</h6>
              {category.subTitle ? (
                // Render radio inputs for questions under categories with a subTitle
                <div>
                  <input
                    type="radio"
                    name={category.id} // group by category ID
                    value={question.id}
                    checked={getAnswerForQuestion(question.id) === question.id}
                    onChange={(e) =>
                      handleQuestionChange(
                        category.id,
                        question.id,
                        e.target.value
                      )
                    }
                    disabled={disabled}
                  />
                </div>
              ) : (
                // Render normal input for questions without a subTitle
                <div>
                  <input
                    type="text"
                    name={question.id}
                    className="form-control"
                    value={getAnswerForQuestion(question.id)}
                    onChange={(e) =>
                      handleQuestionChange(
                        category.id,
                        question.id,
                        e.target.value
                      )
                    }
                    disabled={disabled}
                  />
                  {validationErrors?.[category.id]?.[question.id] && (
                    <div className="invalid-feedback">
                      This field is required.
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default Form2;
