import React, { useEffect } from "react";
import InputFactory from "../../../components/InputFactory";

function Form1Agent({
  object,
  onChange,
  fieldRefs,
  validationErrors,
  user,
  disabled,
}) {
  console.log("Object:", object);
  console.log("vv", validationErrors);

  useEffect(() => {
    // Initialize refs for questions
    object.questions?.forEach((question) => {
      if (!fieldRefs?.questions[question.id]) {
        fieldRefs.questions[question.id] = React.createRef();
      }
      question.question_per_category?.forEach((perCategory) => {
        if (!fieldRefs.questions[perCategory.id]) {
          fieldRefs.questions[perCategory.id] = React.createRef();
        }
      });
    });
  }, [object, fieldRefs]);

  const handleQuestionChange = (categoryId, value) => {
    onChange(categoryId, value);
  };

  const handleChoiceChange = (categoryId, choiceId, newValue) => {
    onChange(categoryId, newValue, choiceId);
  };

  const handleOverallQuestionChange = (questionId, newAnswer) => {
    object.questions.forEach((question) => {
      if (question.id === questionId) {
        if (question.question_per_category.length === 0) {
          // If there are no question_per_category, directly set the yesOrNo value
          question.yesOrNo = newAnswer;
          handleQuestionChange(question.id, newAnswer);
        } else {
          question.question_per_category.forEach((perCategory) => {
            handleQuestionChange(perCategory.id, newAnswer);
          });
          question.yesOrNo = newAnswer;
        }
      }
    });
  };

  const handleInputChange = (field, value) => {
    onChange(field, value);
  };

  const getInputClassName = (error) => {
    return `form-control${error ? " is-invalid" : ""}`;
  };

  return (
    <div className="row g-3 mb-3">
      <div className="col-md-4">
        <h6>NATURE OF BUSINESS</h6>
        <input
          className={getInputClassName(validationErrors?.natureBusiness)}
          type="text"
          ref={fieldRefs?.natureBusiness}
          value={object.natureBusiness || ""}
          onChange={(e) => handleInputChange("natureBusiness", e.target.value)}
          placeholder={
            validationErrors?.natureBusiness
              ? "Please fill this field"
              : "Enter Nature of Business"
          }
          disabled={disabled}
        />
      </div>
      <div className="col-md-4">
        <h6>OPERATIONAL DURING VISIT?</h6>
        <input
          className={getInputClassName(validationErrors?.operational)}
          type="text"
          ref={fieldRefs?.operational}
          value={object.operational || ""}
          onChange={(e) => handleInputChange("operational", e.target.value)}
          placeholder={
            validationErrors?.operational
              ? "Please fill this field"
              : "Enter Operational During Visit"
          }
          disabled={disabled}
        />
      </div>
      <div className="col-md-4">
        <h6>REQUESTING OFFICER & UNIT</h6>
        <input
          className={getInputClassName(validationErrors?.requesting)}
          type="text"
          ref={fieldRefs?.requesting}
          value={object.requesting || ""}
          onChange={(e) => handleInputChange("requesting", e.target.value)}
          placeholder={
            validationErrors?.requesting
              ? "Please fill this field"
              : "Enter Requesting Officer & Unit"
          }
          disabled={disabled}
        />
      </div>

      {object?.questions?.map((question) => (
        <div key={question.id}>
          {question.questionType === "YES OR NO" && (
            <>
              <h6>{question.questionYes}</h6>
              <div className="d-flex">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={`yes-checkbox-${question.id}`}
                    checked={
                      question.question_per_category.length === 0
                        ? question.yesOrNo === "Yes"
                        : question.question_per_category.some(
                            (perCategory) =>
                              perCategory.answerQuestion === "Yes"
                          )
                    }
                    onChange={(e) => {
                      handleOverallQuestionChange(
                        question.id,
                        e.target.checked ? "Yes" : ""
                      );
                    }}
                    disabled={disabled}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`yes-checkbox-${question.id}`}
                  >
                    Yes
                  </label>
                </div>
                <div className="form-check ms-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={`no-checkbox-${question.id}`}
                    checked={
                      question.question_per_category.length === 0
                        ? question.yesOrNo === "No"
                        : question.question_per_category.every(
                            (perCategory) => perCategory.answerQuestion === "No"
                          )
                    }
                    onChange={(e) => {
                      handleOverallQuestionChange(
                        question.id,
                        e.target.checked ? "No" : ""
                      );
                    }}
                    disabled={disabled}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`no-checkbox-${question.id}`}
                  >
                    No
                  </label>
                </div>
              </div>
            </>
          )}
          {question.questionType === "LABEL" && (
            <>
              <h6>{question.questionYes}</h6>
              {question.question_per_category?.map((perCategory) => (
                <div className="col-md-4" key={perCategory.id}>
                  <h6>{perCategory.perCategory}</h6>
                  {perCategory.choices?.map((choice, index) => (
                    <div
                      className="d-flex"
                      key={`${perCategory.id}-choice-${index}`}
                    >
                      <input
                        type="text"
                        className={getInputClassName(
                          validationErrors?.questions[perCategory.id]
                        )}
                        ref={fieldRefs?.questions[perCategory.id]}
                        defaultValue={choice.name}
                        onChange={(e) =>
                          handleChoiceChange(
                            perCategory.id,
                            choice.id,
                            e.target.value
                          )
                        }
                        placeholder={
                          validationErrors?.questions[perCategory.id]
                            ? "Please fill this field"
                            : choice.name
                        }
                        disabled={disabled}
                      />
                    </div>
                  ))}
                </div>
              ))}
            </>
          )}
          {question.question_per_category?.map((perCategory) => (
            <div className="col-md-4" key={perCategory.id}>
              {question.questionType === "YES OR NO" &&
                question.question_per_category.some(
                  (pc) => pc.answerQuestion === "Yes"
                ) && (
                  <>
                    <h6>{perCategory.perCategory}</h6>
                    {perCategory.choices?.map((choice, index) => (
                      <div
                        className="d-flex"
                        key={`${perCategory.id}-choice-${index}`}
                      >
                        <input
                          type="text"
                          className={getInputClassName(
                            validationErrors?.questions[perCategory.id]
                          )}
                          ref={fieldRefs?.questions[perCategory.id]}
                          defaultValue={choice.name}
                          onChange={(e) =>
                            handleChoiceChange(
                              perCategory.id,
                              choice.id,
                              e.target.value
                            )
                          }
                          placeholder={
                            validationErrors?.questions[perCategory.id]
                              ? "Please fill this field"
                              : choice.name
                          }
                          disabled={disabled}
                        />
                      </div>
                    ))}
                  </>
                )}

              {question.questionType === "MULTIPLE CHOICE" && (
                <>
                  <h6>{perCategory.perCategory}</h6>
                  {perCategory.choices?.map((choice, index) => (
                    <div
                      className="d-flex"
                      key={`${perCategory.id}-choice-${index}`}
                    >
                      <p className="me-2">{index + 1}.</p>
                      <p>{choice.name}</p>
                    </div>
                  ))}
                  <input
                    type="text"
                    className={getInputClassName(
                      validationErrors?.questions[perCategory.id]
                    )}
                    ref={fieldRefs?.questions[perCategory.id]}
                    value={perCategory.answerQuestion || ""}
                    onChange={(e) =>
                      handleQuestionChange(perCategory.id, e.target.value)
                    }
                    placeholder={
                      validationErrors?.questions[perCategory.id]
                        ? "Please fill this field"
                        : "Your answer"
                    }
                    disabled={disabled}
                  />
                </>
              )}
              {question.questionType === "INFORMANTS" && (
                <>
                  <h6>{perCategory.perCategory}</h6>
                  {perCategory.choices?.map((choice, index) => (
                    <div className="" key={`${perCategory.id}-choice-${index}`}>
                      <p className="mb-0">{choice.name}</p>
                    </div>
                  ))}
                  <input
                    type="text"
                    className={getInputClassName(
                      validationErrors?.questions[perCategory.id]
                    )}
                    ref={fieldRefs?.questions[perCategory.id]}
                    value={perCategory.answerQuestion || ""}
                    onChange={(e) =>
                      handleQuestionChange(perCategory.id, e.target.value)
                    }
                    placeholder={
                      validationErrors?.questions[perCategory.id]
                        ? "Please fill this field"
                        : "Your answer"
                    }
                    disabled={disabled}
                  />
                </>
              )}
            </div>
          ))}
        </div>
      ))}
      {/* <InputFactory
        type="Relation"
        _type="Related"
        field="upload"
        target="business_upload"
        object={object}
        onChange={onChange}
        user={user}
      /> */}
    </div>
  );
}

export default Form1Agent;
