import React from "react";
import schemaDashboard from "./schemaDashboard.json";
import BaseListPage from "../../../base/BaseListPage";
import withRouter from "../../../withRouter";
import {
  countObjectUseCase,
  findObjectUseCase,
} from "../../../usecases/object";
import NavBar2 from "../../../components/NavBar2";
// import Count from "./components/Count";

import DateRange from "../../../components/DateRange";
import InputFactory from "../../../components/InputFactory";
import DashboardEndorserPresenter from "./DashboardEndorserPresenter";
import Count from "../dashboard-admin/components/Count";

class DashboardEndorserPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new DashboardEndorserPresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase()
    );
    this.state = {
      objects: [],
      selected: [],
      progress: true,
      count: 0,
      where: {},
      range: "",
      store: [],
      storess: "",
    };
  }

  getCollectionName() {
    return "endorsement";
  }

  setStore(store) {
    this.setState({ store });
  }

  onChangeStore(where) {
    this.setState({ storess: where });
    this.presenter.onChangeStore(where);
  }

  getValueForDashboardObject(object) {
    switch (object.label) {
      case "Pending":
        return this.presenter.calculatePendingInfo();
      case "For Dispatch":
        return this.presenter.calculateDispatchInfo();
      case "For CI":
        return this.presenter.calculateCIInfo();
      case "For QA":
        return this.presenter.calculateQAInfo();
      case "Done":
        return this.presenter.calculateDoneInfo();
        case "Total Endorsement":
        return this.presenter.calculateTotalEndorsementInfo();
      default:
        return { value: 0, percentage: 0 };
    }
  }

  handleCountClick(nav) {
    this.navigateTo(nav);
  }

  onChangeDate(where) {
    console.log("where date", where);
    this.presenter.onChangeDate(where);
  }

  filterSubmit(where) {
    this.presenter.filterSubmit(where);
  }

  onChangeFilter(type, value, field) {
    const where = {};
    switch (type) {
      case "Pointer":
        if (Object.keys(value).length > 0) {
          where[field] = { id: value.id };
        }
        break;
      case "Boolean":
        where[field] = value;
        break;
      default:
        where[field] = { $regex: value, $options: "i" };
    }
    this.filterSubmit(where);
  }

  render() {
    const objects = this.state.objects;
    const schema = this.getSchema(this.getCollectionName());

    console.log("translate", schema);

    return (
      <>
        <NavBar2 />
        <div className="overflow-auto">
          <div className="p-3 p-lg-4">
            <div className="d-flex justify-content-between align-items-center">
              <h1 className="fw-bold mt-3 text-capitalize">Dashboard</h1>
            </div>
            <div className="row">
              <div className="col-sm-4 mb-2">
                <DateRange
                  onChange={this.onChangeDate.bind(this)}
                  field="createdAt"
                />
              </div>
              {/* <div className="col-sm-4" style={{ maxWidth: "max-content" }}>
                {Object.keys(schema.filters || {}).map((field) => {
                  let { type, ...options } = schema.filters[field];

                  return (
                    <div className="mb-2">
                      <InputFactory
                        key={field}
                        className="ms-1"
                        type={type}
                        field={field}
                        where={{}}
                        onChange={this.onChangeFilter.bind(this, type)}
                        {...options}
                      />
                    </div>
                  );
                })}
              </div> */}
            </div>

            <div className="row mt-1 g-3">
              {schemaDashboard.map((object, index) => {
                const { value, percentage } = this.getValueForDashboardObject(
                  object,
                  objects
                );

                const navigationTarget = object.navigationTarget;
                return (
                  <div key={index} className="col-6 col-md-3">
                    <Count
                      collection={object.collection}
                      icon={object.icon}
                      where={object.where}
                      val={value}
                      label={object.label}
                      labelAction={object.labelAction}
                      barangayValue={this.state.barangayValue}
                      object={object}
                      percentage={percentage}
                      bg={object.bg}
                      onClick={() => this.handleCountClick(navigationTarget)}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(DashboardEndorserPage);
