import React from "react";
import classNames from "../../classNames";

const noop = () => {};
const defaultProps = {
  type: "password",
  onChange: noop,
};

function InputPassword({ className, onChange, ...props }) {
  function onInput(e) {
    const value = e.target.value;
    onChange(value);
  }

  function showPasswordClick(e) {
    const input = e.target.previousElementSibling;
    const isText = input.type === "text";
    input.type = isText ? "password" : "text";
    if (isText) {
      e.target.classList.add("bi-eye-slash");
      e.target.classList.remove("bi-eye");
    } else {
      e.target.classList.remove("bi-eye-slash");
      e.target.classList.add("bi-eye");
    }
  }

  return (
    <div className="input-group">
      <input
        autoComplete="new-password"
        className={classNames("form-control border-end-0 pe-0", className)}
        onInput={onInput}
        {...props}
      />
      <button
        type="button"
        className="btn btn-link text-muted shadow-none bi bi-eye-slash"
        onClick={showPasswordClick}
      ></button>
    </div>
  );
}

InputPassword.defaultProps = defaultProps;

export default InputPassword;
