import { dialog } from "nq-component";
import BaseListPresenter from "../../base/BaseListPresenter";
import { deleteObjectUseCase, upsertUseCase } from "../../usecases/object";

class RankingPresenter extends BaseListPresenter {
  init() {
    this.limit = 20;
    this.where = { rolesName: "AGENTS" };
    this.search = {};
    this.filter = {};
    this.filterDate = {};
    this.include = ["all"];
    this.keys = undefined; // if keys are specified, only those keys will be returned
    this.sort = { rank: -1 };
    this.progress = true;
    this.filterLocation = "";
    this.reset();
  }

  componentDidMount() {
    this.init();
    // this.findObjectsCount();
    return this.getObjects();
  }

  createQuery() {
    const skip = (this.current - 1) * this.limit;
    const query = {
      limit: this.limit,
      skip: skip,
      // where: {
      //   ...this.where,
      //   ...this.search,
      //   ...this.filter,
      //   countPoints:
      //     this.filterDate && this.filterDate.start && this.filterDate.end
      //       ? {
      //           $elemMatch: {
      //             ...this.filterDate,
      //           },
      //         }
      //       : undefined,
      //   location: this.filterLocation
      //     ? {
      //         $elemMatch: {
      //           id: this.filterLocation, // Use the filter location if set
      //         },
      //       }
      //     : undefined,
      // },
      where: {
        ...this.where,
        ...this.search,
        ...this.filter,
      },
      include: this.include,
    };

    if (this.filterDate.start && this.filterDate.end) {
      query.where.countPoints = {
        $elemMatch: {
          createdAt: "2024-07-31T22:20:22.325Z",
        },
      };
    }

    if (this.filterLocation) {
      query.where.location = {
        $elemMatch: {
          id: this.filterLocation,
        },
      };
    }
    if (this.sort) {
      query.sort = this.sort;
    }
    // const keys = this.keys || this.view.getKeys() || [];
    // if (keys.length > 0) {
    //   query.keys = keys;
    // }
    return query;
  }

  async findObjectsCount() {
    const collection = "points";
    const query = this.createQueryCount();
    try {
      this.showProgress();
      this.findObjectUseCase.abort();
      const objects = await this.findObjectUseCase.execute(collection, query);
      this.objects = this.objects.concat(objects);
      this.view.setTotal(this.objects.length);
      this.view.setObjects(this.objects);
      this.hideProgress();
    } catch (error) {
      this.hideProgress();
      this.view.showError(error);
    }
    this.progress = false;
  }
  async onClickItemDelete(index) {
    dialog.close();
    const object = this.objects[index].id;
    const collection = this.view.getCollectionName();
    await deleteObjectUseCase().execute(collection, object);
    this.objects.splice(index, 1);

    this.view.setObjects(this.objects);
  }

  onClickAdd() {
    const collection = this.view.getCollectionName();
    this.view.navigateTo("/users-form");
  }

  onClickItem(index) {
    const object = this.objects[index];
    const collection = this.view.getCollectionName();
    this.view.navigateTo("/users-form/" + object.id);
  }

  async onClickStatus(index) {
    const object = this.objects[index];
    console.log("update", object);

    object.status = object.status === "Active" ? "InActive" : "Active";

    const collection = this.view.getCollectionName();
    const newObject = await upsertUseCase().execute(collection, object);
    this.objects[index] = object;
    console.log("asas", this.objects);

    this.view.setObjects(this.objects);
  }
  onChangeDate(where) {
    this.filterDate = where;
    console.log("filter Date : ", this.filterDate);
    this.getObjects();
  }

  filterSubmit(where) {
    const locationId = where.locationP?.id;
    this.reset();
    this.filterLocation = locationId;
    this.getObjects();
  }
}

export default RankingPresenter;
