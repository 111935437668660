// import classNames from "../classNames";
import React from "react";

import classNames from "../../classNames";
import checkCondition from "../../checkCondition";
import hasAccess from "../../hasAccess";

const noop = () => {};
const defaultProps = {
  onSet: noop,
};

function FormTab({ tabs, onSet, object, roles }) {
  if (tabs === undefined) return null;
  return (
    <nav className="col-12 mb-3">
      <div className="nav nav-tabs">
        {Object.keys(tabs).map((key, index) => {
          const { label } = tabs[key];
          const conditions = tabs[key].if || tabs[key].or || tabs[key].and;
          if (conditions && !checkCondition(tabs[key], object)) {
            return null;
          }
          if (!hasAccess(roles, tabs[key].access)) {
            return null;
          }
          return (
            <button
              onClick={() => onSet(key)}
              className={classNames("nav-link", index === 0 ? "active" : "")}
              data-bs-toggle="tab"
              data-bs-target="#nav-moodle"
              type="button"
            >
              {label || key}
            </button>
          );
        })}
      </div>
    </nav>
  );
}

FormTab.defaultProps = defaultProps;
export default FormTab;
