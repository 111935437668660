import React from "react";
import classNames from "../../classNames";

const noop = () => {};
const defaultProps = {
  type: "tel",
  parse: true,
  onChange: noop,
};

function InputNumber({
  parse,
  className,
  maxLength,
  onChange,
  options,
  ...props
}) {
  function onInput(e) {
    const value = e.target.value.replace(/[^\d]/gi, ""); // digit only
    e.target.value = value;
    if (maxLength && parseInt(maxLength) < value.length) {
      e.target.value = value.slice(0, parseInt(maxLength));
      return;
    }
    onChange(parse ? parseInt(value) : value);
  }

  return (
    <input
      className={classNames("form-control", className)}
      onInput={onInput}
      {...props}
    />
  );
}

InputNumber.defaultProps = defaultProps;

export default InputNumber;
