import { logDOM } from "@testing-library/react";
import BaseFormPresenter from "../../base/BaseFormPresenter";
import { findObjectUseCase } from "../../usecases/object";

class EndorsementFormPresenter extends BaseFormPresenter {
  onChangeObject(object) {
    this.change = object;
    this.object = object;
  }

  async getObject() {
    const collection = this.view.getCollectionName();
    const id = this.object.id || this.view.getObjectId();
    if (id) {
      const params = { include: ["all"] };
      try {
        this.view.showProgress();
        this.object = await this.getObjectUseCase.execute(collection, id, {
          params,
        });
        this.view.hideProgress();
        this.view.setObject(this.object);
      } catch (error) {
        this.view.hideProgress();
        this.view.showError(error);
      }
    }
  }

  async save() {
    const collection = this.view.getCollectionName();
    const region = this.view.state.selectedRegions;
    const province = this.view.state.selectedProvinces;
    const city = this.view.state.selectedCity;
    const barangay = this.view.state.selectedBarangay;
    const user = this.view.getCurrentUser();
    const currentDate = new Date().toISOString();
    const query = {
      sort: {
        createdAt: 1,
      },
      include: ["All", "roles.name", "location.name"],
    };

    console.log("pooo", province);
    this.change.region = region;
    // this.change.province = province ? province : "";
    this.change.province =
      Array.isArray(province) && province.length === 0 ? "" : province;

    this.change.city = city;
    this.change.barangay = barangay;

    if (this.object.id) {
      this.change.id = this.object.id;
      console.log("this", this.object);
    }

    try {
      // Fetch all users
      const users = await findObjectUseCase().execute("users", query);
      console.log("All users fetched:", users);

      // Filter users with the role MIS
      const MISUsers = users.filter((user) =>
        user.roles.some((role) => role.name === "MIS")
      );

      // Sort MISUsers by createdAt to establish initial order
      MISUsers.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      console.log("establish", MISUsers);

      // Sort users by the number of endorsements, then by createdAt if endorsements are equal
      const sortedMISUsers = MISUsers.sort((a, b) => {
        const aEndorsementCount = a.endorsement ? a.endorsement.length : 0;
        const bEndorsementCount = b.endorsement ? b.endorsement.length : 0;
        console.log("aE", aEndorsementCount);
        console.log("bE", bEndorsementCount);
        const check = aEndorsementCount === bEndorsementCount ? true : false;
        console.log("check", check);

        if (aEndorsementCount === bEndorsementCount) {
          return new Date(a.createdAt) - new Date(b.createdAt);
        }
        const minus = aEndorsementCount - bEndorsementCount;
        console.log("minus", minus);
        return minus;
      });
      console.log("sorted[0]", sortedMISUsers);
      console.log("sorted[0]", sortedMISUsers[0]);
      console.log("mnmnm", this.object.rType);

      // Select the user with the fewest endorsements, or the earliest created if counts are equal
      const userForEndorsement = sortedMISUsers[0];
      console.log("Next user for endorsement:", userForEndorsement);

      // Format the current date and time in the Philippines time zone
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
        timeZone: "Asia/Manila",
      };
      const formatter = new Intl.DateTimeFormat("en-GB", options);
      const parts = formatter.formatToParts(new Date());
      const timeString = `${parts.find((p) => p.type === "year").value}-${
        parts.find((p) => p.type === "month").value
      }-${parts.find((p) => p.type === "day").value} ${
        parts.find((p) => p.type === "hour").value
      }:${parts.find((p) => p.type === "minute").value}:${
        parts.find((p) => p.type === "second").value
      }`;

      // Process the endorsement
      this.change.createdBy = userForEndorsement.id;
      this.change.status = "For Dispatch";
      this.change.clientName = user.name;
      this.change.borrowerName = this.change.residentName;
      // console.log(
      //   "qwer",
      //   this.view.state.selectedForm.label,
      //   this.object.rType
      // );
      this.change.rType =
        this.object.rType || this.view.state.selectedForm?.label;

      this.change.auditId =
        this.object.auditId || this.view.state.selectedForm?.value;
      this.change.timeline = this.change.timeline || [];
      this.change.timeline.push({
        time: timeString,
        status: "For Dispatch",
      });
      // this.change.region = region;
      // this.change.province = province;
      // this.change.city = city;
      // this.change.barangay = barangay;
      this.change.endorserName = user.name;

      console.log("CHANGED SEND:", this.change);

      const responseEndorse = await this.upsertUseCase.execute(
        collection,
        this.change
      );
      console.log("Endorsement response:", responseEndorse);

      // Update the endorsement array for the user
      userForEndorsement.endorsement = userForEndorsement.endorsement || [];
      userForEndorsement.endorsement.push({
        id: responseEndorse.id,
        createdAt: currentDate,
      });

      // Save the updated user
      // if (this.object.id) {
      //   return;
      // } else {
      //   await this.upsertUseCase.execute("users", userForEndorsement);
      // }

      this.change = {};
    } catch (error) {
      console.error("Error in save function:", error);
      throw error; // Rethrow the error to be caught by the caller
    }
  }

  async submit() {
    if (Object.values(this.change).length === 0) {
      this.view.showSuccessSnackbar("Successfully saved!");

      return;
    }
    try {
      this.view.submitting();
      await this.save();
      this.view.submissionSuccess();
      this.view.showSuccessSnackbar("Successfully saved!");
      this.view.navigateBack();
    } catch (error) {
      this.view.submissionError(error);
      this.view.showError(error);
    }
  }
}

export default EndorsementFormPresenter;
