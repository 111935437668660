import BaseListPresenter from "../../base/BaseListPresenter";

class DispatchedPresenter extends BaseListPresenter {
  init() {
    this.limit = 20;
    this.where = { dispatch: true };
    this.search = {};
    this.filter = {};
    this.filterDate = {};
    this.include = ["all"];
    this.keys = undefined; // if keys are specified, only those keys will be returned
    this.sort = { createdAt: -1 };
    this.progress = true;
    this.reset();
  }

  createQuery() {
    const skip = (this.current - 1) * this.limit;
    const query = {
      limit: this.limit,
      skip: skip,
      where: {
        ...this.where,
        ...this.search,
        ...this.filter,
        ...this.filterDate,
      },
      include: this.include,
    };
    if (this.sort) {
      query.sort = this.sort;
    }
    // const keys = this.keys || this.view.getKeys() || [];
    // if (keys.length > 0) {
    //   query.keys = keys;
    // }
    return query;
  }

  async findObjects() {
    const collection = this.view.getCollectionName();
    const query = this.createQuery();
    try {
      this.showProgress();
      this.findObjectUseCase.abort();
      const objects = await this.findObjectUseCase.execute(collection, query);
      this.objects = this.objects.concat(objects);
      this.view.setTotal(this.objects.length);
      this.view.setObjects(this.objects);
      this.hideProgress();
    } catch (error) {
      this.hideProgress();
      this.view.showError(error);
    }
    this.progress = false;
  }
  onClickAdd() {
    this.view.navigateTo("/endorsement-form");
  }

  onClickItem(index) {
    const object = this.objects[index];
    this.view.navigateTo("/endorsement-form/" + object.id);
  }

  onClickView(index, field) {
    const object = this.objects[index];
    this.view.navigateTo("/endorsement-form-view/" + object.id);
  }

  onChangeDate(where) {
    this.filterDate = where;
    this.getObjects();
  }
}

export default DispatchedPresenter;
