import React from "react";
import Papa from "papaparse";

function TableCI({ objects }) {
  console.log("obb", objects);
  const headerStyle = {
    backgroundColor: "#063a6c",
    color: "white",
  };

  // Function to export CSV
  const exportCSV = () => {
    // Map the objects to the desired CSV format
    const csvData = objects.map((obj) => {
      // Find the time for "For CI" status
      const fciReportReceive =
        obj.timeline.find((item) => item.status === "For CI")?.time || "";

      return {
        "Date Endorsed": obj.dateTimeSubmitted,
        "Area Officer": obj.agent,
        "QA Officer": obj?.qaOfficer || "", // Assuming this field is empty in the object
        Client: obj.clientName,
        Borrower: obj.borrowerName,
        // Address: obj?.region, obj.residentialAddress,
        Address: `${obj?.region || ""}, ${obj?.province || ""}, ${
          obj?.city || ""
        }, ${obj?.barangay || ""}, ${obj?.residentialAddress || ""}`,
        "Loan Info Id": obj.auditId,
        "Request Type": obj.rType,
        Rate: obj.rate,
        "Deadline Date And Time": "", // Assuming this field is empty in the object
        Status: obj.statusQA,
        "Fci Report Receive": fciReportReceive,
        "Report Sent": obj.dateTimeSubmitted,
        Location: obj?.location?.name,
      };
    });

    const csvString = Papa.unparse(csvData);
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", "report.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Process the objects array to count occurrences
  const counts = objects.reduce((acc, obj) => {
    const { agent, rType } = obj;
    if (!acc[agent]) {
      acc[agent] = { BVR: 0, EVR: 0, RVR: 0 };
    }
    if (rType === "BUSINESS VERIFICATION (BVR)") {
      acc[agent].BVR += 1;
    } else if (rType === "EMPLOYMENT VERIFICATION (EVR)") {
      acc[agent].EVR += 1;
    } else if (rType === "RESIDENCE VERIFICATION (RVR)") {
      acc[agent].RVR += 1;
    }
    return acc;
  }, {});

  // Convert the counts object to an array for rendering
  const rows = Object.keys(counts).map((agent) => ({
    agent,
    BVR: counts[agent].BVR,
    EVR: counts[agent].EVR,
    RVR: counts[agent].RVR,
  }));

  return (
    <div className="container mt-3">
      <div className="d-flex justify-content-between align-items-center">
        <h6 className="fw-bold">Credit investigators</h6>

        <div onClick={exportCSV} className="mb-2">
          <i class="bi bi-box-arrow-left" style={{ color: "#fdcb5b" }}></i>
          <span
            className="ms-1"
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              color: "#fdcb5b",
            }}
          >
            Export to CSV
          </span>
        </div>
      </div>

      <table className="table table-striped">
        <thead>
          <tr>
            <th style={headerStyle}>Name</th>
            <th style={headerStyle}>BVR</th>
            <th style={headerStyle}>EVR</th>
            <th style={headerStyle}>RVR</th>
          </tr>
        </thead>
        <tbody>
          {rows.length > 0 ? (
            rows.map((row) => (
              <tr key={row.agent}>
                <td>{row.agent}</td>
                <td>{row.BVR}</td>
                <td>{row.EVR}</td>
                <td>{row.RVR}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" className="text-center">
                No agent data
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default TableCI;
