import React from "react";
import withRouter from "../../withRouter";
import BaseListPage from "../../base/BaseListPage";
import { countObjectUseCase, findObjectUseCase } from "../../usecases/object";
import NavBar from "../../components/navbar";
import { Button, InfiniteScroll, Progress, dialog } from "nq-component";
import InputFactory from "../../components/InputFactory";
import Search from "../../components/Search";
import TableAudit from "../../components/TableAudit";
import ReportManagementPresenter from "./ReportManagementPresenter";
import TableQA from "./components/TableQA";
import DateRange from "../../components/DateRange";
import TableCI from "./components/TableCI";

class ReportManagementPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new ReportManagementPresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase()
    );
    this.state = {
      objects: [],
      selected: [],
      loading: true,
      total: 0,
      count: 0,
      agentForm: [],
    };
  }

  getCollectionName() {
    return "users";
  }

  componentDidMount() {
    this.presenter.componentDidMount();
    // this.getAllEndorsement();
  }

  onClickAdd() {
    this.presenter.onClickAdd();
  }

  onClickItem(index, field) {
    this.presenter.onClickItem(index, field);
  }

  onClickDispatchModal(index) {
    const dispatchObject = this.state.objects[index];
    console.log("obb", dispatchObject);
    const schema = this.getSchema("endorsement");
  }

  // async getAllEndorsement() {
  //   const query = {
  //     include: ["location", "timeline"],
  //   };
  //   const object = await findObjectUseCase().execute("endorsement", query);
  //   console.log("getall", object);
  //   this.setState({ agentForm: object });
  // }

  onCollapse(index, object) {
    return (
      <>
        <div className="d-flex">
          {object.picture && (
            <div
              className="mb-3"
              style={{
                width: "100px",
                height: "100px",
              }}
            >
              <img
                className="w-100 h-100"
                style={{ objectFit: "cover" }}
                src={object.picture}
              />
            </div>
          )}
          <ul className="list-unstyled ms-1">
            <li>
              <span className="ms-2 fw-light">Report Type:</span>
              <span className="fs-sm text-nowrap"> {object.reportType}</span>
            </li>
            <hr />
            <li>
              <span className="ms-2 fw-light">Resident Name:</span>
              <span className="fs-sm text-nowrap"> {object.residentName}</span>
            </li>

            <li>
              <span className="ms-2 fw-light">Residential Address: </span>
              <span className="fs-sm text-nowrap">
                {object.residentialAddress}
              </span>
            </li>
            <li>
              <span className="ms-2 fw-light">Contact Number: </span>
              <span className="fs-sm text-nowrap">
                {object.residentContact}
              </span>
            </li>
            <hr />
            <li>
              <span className="ms-2 fw-light">Employer's Name:</span>
              <span className="fs-sm text-nowrap"> {object.employerName}</span>
            </li>

            <li>
              <span className="ms-2 fw-light">Employer's Address: </span>
              <span className="fs-sm text-nowrap">
                {object.employerAddress}
              </span>
            </li>
            <li>
              <span className="ms-2 fw-light">Contact Number: </span>
              <span className="fs-sm text-nowrap">
                {object.employerContact}
              </span>
            </li>
          </ul>
        </div>
      </>
    );
  }

  onChangeDate2(where) {
    console.log("where date", where);
    this.presenter.onChangeDate2(where);
  }

  onChangeDate(where) {
    console.log("where date", where);
    this.presenter.onChangeDate(where);
  }

  render() {
    const schema = this.getSchema(this.getCollectionName());
    const { objects, selected, count, progress } = this.state;
    console.log("clp", objects);

    if (!schema) return <Progress />;
    const user = this.getCurrentUser();

    return (
      <>
        <NavBar />
        <div className="overflow-auto">
          <InfiniteScroll
            className="h-100"
            loadMore={this.loadMore.bind(this)}
            hasMore={!progress && count > objects.length}
          >
            <div className="p-3 p-lg-4">
              <div className="d-flex justify-content-between align-items-center">
                <h1 className="fw-bold text-capitalize">
                  {schema.label || "Report Management"}
                </h1>
              </div>
              <div>
                <div className="row mt-2"></div>

                <div className="d-flex mt-3">
                  <DateRange
                    onChange={this.onChangeDate2.bind(this)}
                    field="createdAt"
                  />
                </div>

                <TableCI objects={this.state.agentForm} />
              </div>
              <div>
                {/* <div className="row mt-2">
                  <div className="col-6">
                    <h6 className="fw-bold">Quality Assurance</h6>
                  </div>
                  <div className="col-6 text-end">
                    <i
                      class="bi bi-box-arrow-left"
                      style={{ color: "#fdcb5b" }}
                    ></i>
                    <span
                      className="ms-1"
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        color: "#fdcb5b",
                      }}
                    >
                      Export to CSV
                    </span>
                  </div>
                </div> */}

                <div className="d-flex mt-3">
                  <DateRange
                    onChange={this.onChangeDate.bind(this)}
                    field="createdAt"
                  />
                </div>

                <TableQA objects={objects} />
              </div>
            </div>
          </InfiniteScroll>
        </div>
      </>
    );
  }
}

export default withRouter(ReportManagementPage);
