import React, { useState, useRef, useEffect } from "react";
import regionsPh from "../regionsPh.json";
import provincePh from "../provincePh.json";
import municipalityPh from "../municipalityPh.json";
import barangayPh from "../barangayPh.json";
import classNames from "../../classNames";

const LocationSelector = ({ object, onLocationChange }) => {
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedBarangay, setSelectedBarangay] = useState("");
  const [regionsList, setRegionsList] = useState(regionsPh);
  const [provinceList, setProvinceList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [barangayList, setBarangayList] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState({
    region: false,
    province: false,
    city: false,
    barangay: false,
  });
  const inputRef = useRef();
  const wrapperRef = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (object) {
      setSelectedRegion(object.region || []);
      setSelectedProvince(object.province || []);
      setSelectedCity(object.city || []);
      setSelectedBarangay(object.barangay || []);
    }
  }, [object]);

  useEffect(() => {
    onLocationChange(
      selectedRegion,
      selectedProvince,
      selectedCity,
      selectedBarangay
    );
  }, [
    selectedRegion,
    selectedProvince,
    selectedCity,
    selectedBarangay,
    onLocationChange,
  ]);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setDropdownOpen({
        region: false,
        province: false,
        city: false,
        barangay: false,
      });
    }
  };

  const openDropdown = (type) => {
    setDropdownOpen((prevState) => ({
      ...prevState,
      [type]: !prevState[type],
    }));
  };

  const clearSelection = (type) => {
    if (type === "region") {
      setSelectedRegion("");
      setSelectedProvince("");
      setSelectedCity("");
      setSelectedBarangay("");
      setProvinceList([]);
      setCityList([]);
      setBarangayList([]);
    } else if (type === "province") {
      setSelectedProvince("");
      setSelectedCity("");
      setSelectedBarangay("");
      setCityList([]);
      setBarangayList([]);
    } else if (type === "city") {
      setSelectedCity("");
      setSelectedBarangay("");
      setBarangayList([]);
    } else if (type === "barangay") {
      setSelectedBarangay("");
    }
  };

  // const handleRegionsChange = (valueName, valueCode) => {
  //   const selectedRegion = valueCode;
  //   console.log("SR : ", selectedRegion);
  //   setSelectedRegion(valueName);
  //   setSelectedProvince("");
  //   setSelectedCity("");
  //   setSelectedBarangay("");

  //   let provinceLists = provincePh.map((province) => ({
  //     name: province.name,
  //     code: province.code,
  //     region: province.regionCode,
  //   }));

  //   const cityLists = municipalityPh.map((city) => ({
  //     name: city.name,
  //     code: city.code,
  //     province: city.provinceCode,
  //     region: city.regionCode,
  //   }));

  //   // Handle NCR special case
  //   if (selectedRegion === "130000000") {
  //     const filteredCityList = cityLists.filter(
  //       (city) => city.region === "130000000"
  //     );

  //     setCityList(filteredCityList);
  //     return;
  //   }

  //   const filteredProvinceList = provinceLists.filter((province) =>
  //     province.region.startsWith(selectedRegion)
  //   );

  //   setProvinceList(filteredProvinceList);
  //   setCityList([]);
  //   setDropdownOpen((prevState) => ({ ...prevState, region: false }));
  // };

  const handleRegionsChange = (valueName, valueCode) => {
    const selectedRegion = valueCode;
    setSelectedRegion(valueName);
    setSelectedProvince("");
    setSelectedCity("");
    setSelectedBarangay("");

    let provinceLists = provincePh.map((province) => ({
      name: province.name,
      code: province.code,
      region: province.regionCode,
    }));

    const cityLists = municipalityPh.map((city) => ({
      name: city.name,
      code: city.code,
      province: city.provinceCode,
      region: city.regionCode,
    }));

    // Handle NCR special case
    if (selectedRegion === "130000000") {
      const filteredCityList = cityLists.filter(
        (city) => city.region === "130000000"
      );

      setCityList(filteredCityList);
      setDropdownOpen((prevState) => ({
        ...prevState,
        region: false,
        city: true, // Open the city dropdown immediately when NCR is selected
      }));
      return;
    }

    const filteredProvinceList = provinceLists.filter((province) =>
      province.region.startsWith(selectedRegion)
    );

    setProvinceList(filteredProvinceList);
    setCityList([]);
    setDropdownOpen((prevState) => ({ ...prevState, region: false }));
  };

  const handleProvinceChange = (valueName, valueCode) => {
    const selectedProvince = valueCode;
    setSelectedProvince(valueName);
    setSelectedCity("");
    setSelectedBarangay("");

    const cityLists = municipalityPh.map((city) => ({
      name: city.name,
      code: city.code,
      province: city.provinceCode,
    }));

    const filteredCityList = cityLists.filter(
      (city) => city.province === selectedProvince
    );

    setCityList(filteredCityList);
    setDropdownOpen((prevState) => ({ ...prevState, province: false }));
  };

  const handleCityChange = (valueName, valueCode) => {
    const selectedCity = valueCode;
    console.log("SC", selectedCity);
    setSelectedCity(valueName);
    setSelectedBarangay("");

    const barangayLists = barangayPh.map((barangay) => ({
      name: barangay.name,
      code: barangay.code,
      city: barangay.municipalityCode,
      city1: barangay.cityCode,
      city2: barangay.subMunicipalityCode,
    }));

    const filteredBarangayList = barangayLists.filter(
      (barangay) =>
        barangay.city === selectedCity ||
        barangay.city1 === selectedCity ||
        barangay.city2 === selectedCity
    );

    setBarangayList(filteredBarangayList);
    setDropdownOpen((prevState) => ({ ...prevState, city: false }));
  };

  const handleBarangayChange = (valueName, valueCode) => {
    setSelectedBarangay(valueName);
    setDropdownOpen((prevState) => ({ ...prevState, barangay: false }));
  };

  return (
    // <div ref={wrapperRef}>
    //   <div className="row">
    //     <div className="mb-3 col-4">
    //       <label className="mb-2" htmlFor="region">
    //         Regions
    //       </label>
    //       <div className="custom-select-wrapper col-12">
    //         <div className="position-relative">
    //           <input
    //             readOnly
    //             className={classNames("form-control")}
    //             value={selectedRegion}
    //             placeholder="Select Region"
    //             onClick={() => openDropdown("region")} // Open dropdown when input is clicked
    //             ref={inputRef}
    //           />
    //           <span
    //             className="position-absolute end-0 top-50 translate-middle-y me-3 cursor-pointer"
    //             onClick={() =>
    //               selectedRegion
    //                 ? clearSelection("region")
    //                 : openDropdown("region")
    //             }
    //           >
    //             {selectedRegion ? (
    //               <i className="bi bi-x-lg"></i>
    //             ) : (
    //               <i className="bi bi-chevron-down"></i>
    //             )}
    //           </span>
    //         </div>
    //         {dropdownOpen.region && (
    //           <ul className="custom-select-options">
    //             {regionsList.map((region) => (
    //               <li
    //                 key={region.code}
    //                 onClick={() =>
    //                   handleRegionsChange(region.name, region.code)
    //                 }
    //                 className="custom-select-option"
    //               >
    //                 {region.name}
    //               </li>
    //             ))}
    //           </ul>
    //         )}
    //       </div>
    //     </div>

    //     {selectedRegion && selectedRegion !== "NCR" && (
    //       <div className="mb-3 col-4">
    //         <label className="mb-2" htmlFor="province">
    //           Province
    //         </label>
    //         <div className="custom-select-wrapper col-12">
    //           <div className="position-relative">
    //             <input
    //               readOnly
    //               className={classNames("form-control")}
    //               value={selectedProvince}
    //               placeholder="Select Province"
    //               onClick={() => openDropdown("province")} // Open dropdown when input is clicked
    //             />
    //             <span
    //               className="position-absolute end-0 top-50 translate-middle-y me-3 cursor-pointer"
    //               onClick={() =>
    //                 selectedProvince
    //                   ? clearSelection("province")
    //                   : openDropdown("province")
    //               }
    //             >
    //               {selectedProvince ? (
    //                 <i className="bi bi-x-lg"></i>
    //               ) : (
    //                 <i className="bi bi-chevron-down"></i>
    //               )}
    //             </span>
    //           </div>
    //           {dropdownOpen.province && (
    //             <ul className="custom-select-options">
    //               {provinceList.map((province) => (
    //                 <li
    //                   key={province.code}
    //                   onClick={() =>
    //                     handleProvinceChange(province.name, province.code)
    //                   }
    //                   className="custom-select-option"
    //                 >
    //                   {province.name}
    //                 </li>
    //               ))}
    //             </ul>
    //           )}
    //         </div>
    //       </div>
    //     )}

    //     {(selectedProvince || selectedRegion === "NCR") && (
    //       <div className="mb-3 col-4">
    //         <label className="mb-2" htmlFor="city">
    //           City/Municipality
    //         </label>
    //         <div className="custom-select-wrapper col-12">
    //           <div className="position-relative">
    //             <input
    //               readOnly
    //               className={classNames("form-control")}
    //               value={selectedCity}
    //               placeholder="Select City/Municipality"
    //               onClick={() => openDropdown("city")} // Open dropdown when input is clicked
    //             />
    //             <span
    //               className="position-absolute end-0 top-50 translate-middle-y me-3 cursor-pointer"
    //               onClick={() =>
    //                 selectedCity ? clearSelection("city") : openDropdown("city")
    //               }
    //             >
    //               {selectedCity ? (
    //                 <i className="bi bi-x-lg"></i>
    //               ) : (
    //                 <i className="bi bi-chevron-down"></i>
    //               )}
    //             </span>
    //           </div>
    //           {dropdownOpen.city && (
    //             <ul className="custom-select-options">
    //               {cityList.map((city) => (
    //                 <li
    //                   key={city.code}
    //                   onClick={() => handleCityChange(city.name, city.code)}
    //                   className="custom-select-option"
    //                 >
    //                   {city.name}
    //                 </li>
    //               ))}
    //             </ul>
    //           )}
    //         </div>
    //       </div>
    //     )}

    //     {selectedCity && (
    //       <div className="mb-3 col-4">
    //         <label className="mb-2" htmlFor="barangay">
    //           Barangay
    //         </label>
    //         <div className="custom-select-wrapper col-12">
    //           <div className="position-relative">
    //             <input
    //               readOnly
    //               className={classNames("form-control")}
    //               value={selectedBarangay}
    //               placeholder="Select Barangay"
    //               onClick={() => openDropdown("barangay")} // Open dropdown when input is clicked
    //             />
    //             <span
    //               className="position-absolute end-0 top-50 translate-middle-y me-3 cursor-pointer"
    //               onClick={() =>
    //                 selectedBarangay
    //                   ? clearSelection("barangay")
    //                   : openDropdown("barangay")
    //               }
    //             >
    //               {selectedBarangay ? (
    //                 <i className="bi bi-x-lg"></i>
    //               ) : (
    //                 <i className="bi bi-chevron-down"></i>
    //               )}
    //             </span>
    //           </div>
    //           {dropdownOpen.barangay && (
    //             <ul className="custom-select-options">
    //               {barangayList.map((barangay) => (
    //                 <li
    //                   key={barangay.code}
    //                   onClick={() =>
    //                     handleBarangayChange(barangay.name, barangay.code)
    //                   }
    //                   className="custom-select-option"
    //                 >
    //                   {barangay.name}
    //                 </li>
    //               ))}
    //             </ul>
    //           )}
    //         </div>
    //       </div>
    //     )}
    //   </div>
    // </div>
    <div ref={wrapperRef}>
      <div className="row">
        <div className="mb-3 col-12 col-md-4">
          <label className="mb-2" htmlFor="region">
            Regions
          </label>
          <div className="custom-select-wrapper">
            <div className="position-relative">
              <input
                readOnly
                className={classNames("form-control")}
                value={selectedRegion}
                placeholder="Select Region"
                onClick={() => openDropdown("region")} // Open dropdown when input is clicked
                ref={inputRef}
              />
              <span
                className="position-absolute end-0 top-50 translate-middle-y me-3 cursor-pointer"
                onClick={() =>
                  selectedRegion
                    ? clearSelection("region")
                    : openDropdown("region")
                }
              >
                {selectedRegion ? (
                  <i className="bi bi-x-lg"></i>
                ) : (
                  <i className="bi bi-chevron-down"></i>
                )}
              </span>
            </div>
            {dropdownOpen.region && (
              <ul className="custom-select-options">
                {regionsList.map((region) => (
                  <li
                    key={region.code}
                    onClick={() =>
                      handleRegionsChange(region.name, region.code)
                    }
                    className="custom-select-option"
                  >
                    {region.name}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>

        {selectedRegion && selectedRegion !== "NCR" && (
          <div className="mb-3 col-12 col-md-4">
            <label className="mb-2" htmlFor="province">
              Province
            </label>
            <div className="custom-select-wrapper">
              <div className="position-relative">
                <input
                  readOnly
                  className={classNames("form-control")}
                  value={selectedProvince}
                  placeholder="Select Province"
                  onClick={() => openDropdown("province")} // Open dropdown when input is clicked
                />
                <span
                  className="position-absolute end-0 top-50 translate-middle-y me-3 cursor-pointer"
                  onClick={() =>
                    selectedProvince
                      ? clearSelection("province")
                      : openDropdown("province")
                  }
                >
                  {selectedProvince ? (
                    <i className="bi bi-x-lg"></i>
                  ) : (
                    <i className="bi bi-chevron-down"></i>
                  )}
                </span>
              </div>
              {dropdownOpen.province && (
                <ul className="custom-select-options">
                  {provinceList.map((province) => (
                    <li
                      key={province.code}
                      onClick={() =>
                        handleProvinceChange(province.name, province.code)
                      }
                      className="custom-select-option"
                    >
                      {province.name}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        )}

        {(selectedProvince || selectedRegion === "NCR") && (
          <div className="mb-3 col-12 col-md-4">
            <label className="mb-2" htmlFor="city">
              City/Municipality
            </label>
            <div className="custom-select-wrapper">
              <div className="position-relative">
                <input
                  readOnly
                  className={classNames("form-control")}
                  value={selectedCity}
                  placeholder="Select City/Municipality"
                  onClick={() => openDropdown("city")} // Open dropdown when input is clicked
                />
                <span
                  className="position-absolute end-0 top-50 translate-middle-y me-3 cursor-pointer"
                  onClick={() =>
                    selectedCity ? clearSelection("city") : openDropdown("city")
                  }
                >
                  {selectedCity ? (
                    <i className="bi bi-x-lg"></i>
                  ) : (
                    <i className="bi bi-chevron-down"></i>
                  )}
                </span>
              </div>
              {dropdownOpen.city && (
                <ul className="custom-select-options">
                  {cityList.map((city) => (
                    <li
                      key={city.code}
                      onClick={() => handleCityChange(city.name, city.code)}
                      className="custom-select-option"
                    >
                      {city.name}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        )}

        {selectedCity && (
          <div className="mb-3 col-12 col-md-4">
            <label className="mb-2" htmlFor="barangay">
              Barangay
            </label>
            <div className="custom-select-wrapper">
              <div className="position-relative">
                <input
                  readOnly
                  className={classNames("form-control")}
                  value={selectedBarangay}
                  placeholder="Select Barangay"
                  onClick={() => openDropdown("barangay")} // Open dropdown when input is clicked
                />
                <span
                  className="position-absolute end-0 top-50 translate-middle-y me-3 cursor-pointer"
                  onClick={() =>
                    selectedBarangay
                      ? clearSelection("barangay")
                      : openDropdown("barangay")
                  }
                >
                  {selectedBarangay ? (
                    <i className="bi bi-x-lg"></i>
                  ) : (
                    <i className="bi bi-chevron-down"></i>
                  )}
                </span>
              </div>
              {dropdownOpen.barangay && (
                <ul className="custom-select-options">
                  {barangayList.map((barangay) => (
                    <li
                      key={barangay.code}
                      onClick={() =>
                        handleBarangayChange(barangay.name, barangay.code)
                      }
                      className="custom-select-option"
                    >
                      {barangay.name}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LocationSelector;
