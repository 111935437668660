import React from "react";
import classNames from "../../classNames";

const noop = () => {};
const defaultProps = {
  type: "text",
  onChange: noop,
};

function InputJson({ className, defaultValue, onChange, ...props }) {
  function onInput(event) {
    const value = event.target.value;
    try {
      event.target.setCustomValidity("");
      const parse = JSON.parse(value);
      onChange(parse);
    } catch (error) {
      event.target.setCustomValidity(error.message);
    }
  }

  return (
    <textarea
      defaultValue={JSON.stringify(defaultValue, null, 4) || ""}
      className={classNames("form-control", className)}
      onInput={onInput}
      {...props}
    />
  );
}

InputJson.defaultProps = defaultProps;

export default InputJson;
