// import React from "react";

// function Form2({ object, answer, onChange, fieldRefs, validationErrors }) {
//   console.log("ONCHANGE: ", onChange);
//   console.log("Form2 Object:", object);
//   console.log("Form2 Answer:", answer);

//   const handleQuestionChange = (categoryId, questionId, value) => {
//     if (onChange) {
//       onChange(categoryId, questionId, value);
//     }
//   };

//   return (
//     <div className="row g-3 mb-3">
//       {object?.categories?.map((category) => (
//         <div className="col-md-12" key={category.id}>
//           <h4>{category?.title}</h4>
//           {category?.subTitle && <h5>{category.subTitle}</h5>}
//           {category?.questions?.map((question) => (
//             <div key={question.id}>
//               {category?.subTitle ? (
//                 // Render radio inputs for questions under categories with a subTitle
//                 <div className="d-flex align-items-center mb-2">
//                   <input
//                     type="radio"
//                     name={`${category.id}_${question.id}`} // unique group per question
//                     value={question.id}
//                     // checked={answer?.some(
//                     //   (ans) =>
//                     //     ans?.question.id === question.id &&
//                     //     ans?.answer === question.id
//                     // )}
//                     onChange={(e) =>
//                       handleQuestionChange(
//                         category.id,
//                         question.id,
//                         e.target.value
//                       )
//                     }
//                     className="me-2" // Add margin for spacing
//                   />
//                   <h6 className="mb-0">{question.question}</h6>
//                 </div>
//               ) : (
//                 // Render normal input for questions without a subTitle
//                 <div className="mb-3">
//                   <h6>{question.question}</h6>
//                   <input
//                     type="text"
//                     name={question.id}
//                     // value={
//                     //   answer?.find((ans) => ans?.question.id === question.id)
//                     //     ?.answer || ""
//                     // }
//                     onChange={(e) =>
//                       handleQuestionChange(
//                         category.id,
//                         question.id,
//                         e.target.value
//                       )
//                     }
//                     ref={(el) => (fieldRefs[question.id] = el)}
//                     className={`form-control ${
//                       validationErrors?.[category.id]?.[question.id]
//                         ? "is-invalid"
//                         : ""
//                     }`}
//                     placeholder="Your answer"
//                   />
//                   {validationErrors?.[category.id]?.[question.id] && (
//                     <div className="invalid-feedback">
//                       This field is required.
//                     </div>
//                   )}
//                 </div>
//               )}
//             </div>
//           ))}
//         </div>
//       ))}
//     </div>
//   );
// }

// export default Form2;

import React from "react";

function Form2({ object, answer, onChange, fieldRefs, validationErrors }) {
  console.log("ONCHANGE: ", onChange);
  console.log("Form2 Object:", object);
  console.log("Form2 Answer:", answer);
  const handleQuestionChange = (
    categoryId,
    questionId,
    value,
    radioCategory
  ) => {
    console.log("CATEGORY:", radioCategory);
    if (onChange) {
      onChange(categoryId, questionId, value, radioCategory);
    }
  };

  return (
    <div className="row g-3 mb-3">
      {object?.categories?.map((category) => (
        <div className="col-md-12" key={category.id}>
          <h4>{category?.title}</h4>
          {category?.subTitle && <h5>{category.subTitle}</h5>}
          {category?.questions?.map((question) => (
            <div key={question.id}>
              {category?.subTitle ? (
                // Render radio inputs for questions under categories with a subTitle
                <div className="d-flex align-items-center mb-2">
                  <input
                    type="radio"
                    name={`${category.id}`} // Ensure same name for radio buttons within a category
                    value={question.id}
                    // checked={answer?.some(
                    //   (ans) =>
                    //     ans?.question.id === question.id &&
                    //     ans?.answer === question.id
                    // )}
                    onChange={(e) =>
                      handleQuestionChange(
                        category.id,
                        question.id,
                        e.target.value,
                        `${category.id}_${question.id}`
                      )
                    }
                    className="me-2" // Add margin for spacing
                    required
                  />
                  <h6 className="mb-0">{question.question}</h6>
                </div>
              ) : (
                // Render normal input for questions without a subTitle
                <div className="mb-3">
                  <h6>{question.question}</h6>
                  <input
                    type="text"
                    name={question.id}
                    // value={
                    //   answer?.find((ans) => ans?.question.id === question.id)
                    //     ?.answer || ""
                    // }
                    onChange={(e) =>
                      handleQuestionChange(
                        category.id,
                        question.id,
                        e.target.value
                      )
                    }
                    ref={(el) => (fieldRefs[question.id] = el)}
                    className={`form-control ${
                      validationErrors?.[category.id]?.[question.id]
                        ? "is-invalid"
                        : ""
                    }`}
                    placeholder="Your answer"
                    required
                  />
                  {validationErrors?.[category.id]?.[question.id] && (
                    <div className="invalid-feedback">
                      This field is required.
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default Form2;
