import React from "react";
import classNames from "../../classNames";

const noop = () => {};
const defaultProps = {
  type: "text",
  onChange: noop,
};

function InputText({ className, onChange, strict, uppercase, ...props }) {
  function onInput(e) {
    const value = e.target.value;
    const character = value.charAt(value.length - 1);
    // only string
    if (strict && !/^[a-zA-Z]*$/.test(character)) {
      return;
    }
    if (uppercase) {
      e.target.value = value.toUpperCase();
    }
    onChange(e.target.value);
  }

  return (
    <textarea
      className={classNames("form-control", className)}
      onInput={onInput}
      {...props}
    />
  );
}

InputText.defaultProps = defaultProps;

export default InputText;
