import React, { useState } from "react";

const newCategoryData = {};

export default function ExamQuestion({ object, setObjectState }) {
  console.log("object", object);
  function onChangeCategoryTitle(categoryIndex, event) {
    const { value } = event.target;
    const newCategories = [...object.categories];
    newCategories[categoryIndex].title = value;
    const updatedExam = { ...object, categories: newCategories };
    setObjectState(updatedExam);
  }

  function onChangeCategorySubTitle(categoryIndex, event) {
    const { value } = event.target;
    const newCategories = [...object.categories];
    newCategories[categoryIndex].subTitle = value;
    const updatedExam = { ...object, categories: newCategories };
    setObjectState(updatedExam);
  }

  function onAddCategory(e) {
    e.preventDefault();
    const newCategories = Array.isArray(object.categories)
      ? [...object.categories]
      : [];
    const newCategory = {
      ...newCategoryData,
      questions: [],
    };
    newCategories.push(newCategory);
    const updatedExam = { ...object, categories: newCategories };
    setObjectState(updatedExam);
  }

  function onDeleteCategory(categoryIndex) {
    const updatedCategories = [...object.categories];
    if (updatedCategories[categoryIndex].id) {
      updatedCategories[categoryIndex].__operation = "REMOVE";
    } else {
      updatedCategories.splice(categoryIndex, 1);
    }

    const updatedExam = { ...object, categories: updatedCategories };
    setObjectState(updatedExam);
  }

  function onAddQuestion(categoryIndex) {
    const newCategories = [...object.categories];
    console.log("newcategories", newCategories);
    newCategories[categoryIndex].questions?.push({
      question: "",
    });
    const updatedExam = {
      ...object,
      categories: newCategories,
    };

    setObjectState(updatedExam);
  }

  function onDeleteQuestion(categoryIndex, questionIndex) {
    const newCategories = [...object.categories];
    if (newCategories[categoryIndex].questions[questionIndex].id) {
      newCategories[categoryIndex].questions[questionIndex].__operation =
        "REMOVE";
    } else {
      newCategories[categoryIndex].questions.splice(questionIndex, 1);
    }

    const updatedExam = { ...object, categories: newCategories };
    setObjectState(updatedExam);
  }

  function handleQuestionChange(categoryIndex, questionIndex, event) {
    const { value } = event.target;
    const newCategories = [...object.categories];
    newCategories[categoryIndex].questions[questionIndex].question = value;
    const updatedExam = { ...object, categories: newCategories };
    setObjectState(updatedExam);
  }

  function onAddAnswer(categoryIndex, questionIndex) {
    const newCategories = [...object.categories];
    newCategories[categoryIndex].text[questionIndex].answers.push({
      answer: "",
    });
    const updatedExam = {
      ...object,
      categories: newCategories,
    };

    setObjectState(updatedExam);
  }

  function handleAnswerChange(
    categoryIndex,
    questionIndex,
    answerIndex,
    event
  ) {
    const { value } = event.target;
    const newCategories = [...object.categories];
    newCategories[categoryIndex].text[questionIndex].answers[
      answerIndex
    ].answer = value;
    const updatedExam = { ...object, categories: newCategories };
    setObjectState(updatedExam);
  }

  return (
    <div className="row g-3 mb-3">
      <div>
        <hr className="dropdown-divider" />
      </div>

      {object?.categories?.map((category, category_index) => (
        <div key={category_index}>
          <div>
            <p className="small fw-bold mb-0 ms-1">Form Categories</p>
            <hr className="dropdown-divider" />
          </div>
          {!category.__operation && (
            <div className="p-4 border shadow">
              <div className="col-md-12 row">
                <div className="col-md-4 mb-3">
                  <label className="form-label fs-sm">Title</label>{" "}
                  <span
                    className="ms-2"
                    role="button"
                    onClick={() => onDeleteCategory(category_index)}
                  >
                    <i className="bi bi-trash text-danger"></i>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={`Title ${category_index + 1}`}
                    defaultValue={category.title}
                    onChange={(e) => onChangeCategoryTitle(category_index, e)}
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <label className="form-label fs-sm">Subtitle</label>{" "}
                  <input
                    type="text"
                    className="form-control"
                    placeholder={`Subtitle ${category_index + 1}`}
                    defaultValue={category.subTitle}
                    onChange={(e) =>
                      onChangeCategorySubTitle(category_index, e)
                    }
                  />
                </div>
              </div>
              <div>
                {category?.questions?.map((question, question_index) => (
                  <div key={question_index}>
                    {!question.__operation && (
                      <div className="mt-2 py-2">
                        <div className="col-md-12 row">
                          <div className="col-md-4">
                            <label className="form-label d-flex align-items-center gap-2">
                              Text #{question_index + 1}{" "}
                              <span
                                role="button"
                                onClick={() =>
                                  onDeleteQuestion(
                                    category_index,
                                    question_index
                                  )
                                }
                              >
                                <i className="bi bi-trash text-danger"></i>
                              </span>
                            </label>

                            <input
                              type="text"
                              className="form-control"
                              defaultValue={question.question}
                              placeholder={`Text ${question_index + 1}`}
                              onChange={(e) =>
                                handleQuestionChange(
                                  category_index,
                                  question_index,
                                  e
                                )
                              }
                              required
                            />
                          </div>
                        </div>
                        <div>
                          {question?.answers?.map((answer, answer_index) => (
                            <div key={answer_index} className="mt-2 py-2">
                              <div className="col-md-12 row">
                                <div className="col-md-4">
                                  <label className="form-label d-flex align-items-center gap-2">
                                    Answer #{answer_index + 1}
                                  </label>

                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={answer.answer}
                                    placeholder={`BLANK FIELD`}
                                    onChange={(e) =>
                                      handleAnswerChange(
                                        category_index,
                                        question_index,
                                        answer_index,
                                        e
                                      )
                                    }
                                    disabled
                                  />
                                </div>
                              </div>
                              {/* <hr /> */}
                            </div>
                          ))}
                          {/* <div
                            className="btn btn-sm text-white mt-2"
                            onClick={() =>
                              onAddAnswer(category_index, question_index)
                            }
                            style={{ backgroundColor: "#003466" }}
                          >
                            Add Answer
                          </div> */}
                        </div>
                        <hr />
                      </div>
                    )}
                  </div>
                ))}
                <div
                  className="btn btn-sm text-white"
                  onClick={() => onAddQuestion(category_index)}
                  style={{ backgroundColor: "#003466" }}
                >
                  Add Text
                </div>
              </div>
            </div>
          )}
        </div>
      ))}
      <button
        className="btn"
        onClick={onAddCategory}
        style={{ backgroundColor: "#dfbe68" }}
      >
        Add Categories
      </button>
    </div>
  );
}
