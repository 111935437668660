import BaseFormPresenter from "../../base/BaseFormPresenter";
import { findObjectUseCase, saveObjectUseCase } from "../../usecases/object";

const companyName = "MWeeb";
const informant = "John Doe";
const position = "Software Engineer";
const dateHired = "01-01-2020";
const salary = "50000";
const currentDate = new Date().toISOString().split("T")[0];

const remarksTemplate = `RESULT: 
*Verification made with ___________ through a certain ___________ - ___________ relayed that the subject has been employed as ___________ since ___________ with a monthly salary of ___________.

Nature of work: 
Nature of Employer:
With Derogatory Records? (if yes, specify details)

Document Presented (Payslips, COE, ITR, Coverage/Date):

INFORMANTS (If verification thru the field is not allowed, please get the landline number or company email, if available)
Informant:
Position:
Contact No.:
Personally Known: Y/N  / If Yes Since: ? 
Other Remarks/ Comment:

Informant:
Position:
Contact No.: 
Personally Known: Y/N  / If Yes Since: ?
Other Remarks/ Comment:

Other Income (if any, please provide details of information & proof of income):

OTHER REMARKS (if any):

Date of Verification: ()`;

class CreditForm2Presenter extends BaseFormPresenter {
  onChangeObject(object) {
    this.change = object;
    this.object = object;
  }

  async getObject() {
    const collection = this.view.getCollectionName();
    const id = this.object.id || this.view.getObjectId();
    if (id) {
      const params = {
        include: [
          "categories",
          "categories.questions",
          "categories.questions.question",
        ],
      };
      try {
        this.view.showProgress();
        this.object = await this.getObjectUseCase.execute(collection, id, {
          params,
        });
        this.view.hideProgress();
        const answer = { answers: [] };
        answer.form = { id: this.object.id };
        answer.remarks = remarksTemplate;
        console.log("answer", answer);
        this.view.setAnswer(answer);
        console.log("evr form", this.object);
        this.view.setObject(this.object);
      } catch (error) {
        this.view.hideProgress();
        this.view.showError(error);
      }
    }
  }

  async save() {
    const collection = this.view.getCollectionName();
    const objectUpload = this.view.getUpload();
    const objectAnswer = this.view.getAnswer();
    const collectionEndorsement = "endorsement";
    const collectionUsers = "users";
    const currentDate = new Date().toISOString();
    const object = this.view.state.object;
    objectAnswer.uploadRelation = objectUpload;
    console.log("OBJECT SEND", object);
    const url = this.view.getParams();
    const agentUser = this.view.getCurrentUser();

    const parts = url["*"].split("/");
    console.log("parts", parts);
    const secondPart = parts[1];
    const indexPart = parts[2];
    const query = {
      where: {
        id: secondPart,
      },
    };
    const queryUser = {
      sort: {
        createdAt: 1,
      },
      include: ["All", "roles.name", "location.name"],
    };
    const users = await findObjectUseCase().execute("users", queryUser);

    // Filter users with the role QA
    const qaUsers = users.filter((user) =>
      user.roles.some((role) => role.name === "QA")
    );

    // Sort MISUsers by createdAt to establish initial order
    qaUsers.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

    const sortedQAUsers = qaUsers.sort((a, b) => {
      const aEndorsementCount = a.endorsement ? a.endorsement.length : 0;
      const bEndorsementCount = b.endorsement ? b.endorsement.length : 0;
      console.log("aE", aEndorsementCount);
      console.log("bE", bEndorsementCount);
      const check = aEndorsementCount === bEndorsementCount ? true : false;
      console.log("check", check);

      if (aEndorsementCount === bEndorsementCount) {
        return new Date(a.createdAt) - new Date(b.createdAt);
      }
      const minus = aEndorsementCount - bEndorsementCount;
      console.log("minus", minus);
      return minus;
    });

    // Select the user with the fewest endorsements, or the earliest created if counts are equal
    const userForQA = sortedQAUsers[0];
    console.log("Next user for QA:", userForQA);

    const findEndorsement = await findObjectUseCase().execute(
      collectionEndorsement,
      query
    );

    console.warn("COLLECTION: ", findEndorsement);

    let endorsementQA = findEndorsement[0];

    const queryEndorsementQA = {
      where: {
        id: endorsementQA.id,
      },
    };

    const findEndorsementQa = await findObjectUseCase().execute(
      "endorsement",
      queryEndorsementQA
    );

    console.warn("findEndorsement", endorsementQA);

    let options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
      timeZone: "Asia/Manila",
    };
    const formatter = new Intl.DateTimeFormat("en-GB", options);
    let partss = formatter.formatToParts(new Date());
    const timeString = `${partss.find((p) => p.type === "year").value}-${
      partss.find((p) => p.type === "month").value
    }-${partss.find((p) => p.type === "day").value} ${
      partss.find((p) => p.type === "hour").value
    }:${partss.find((p) => p.type === "minute").value}:${
      partss.find((p) => p.type === "second").value
    }`;

    const formatDate = (date) => {
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const year = String(date.getFullYear()); // Get the last two digits of the year
      return `${year}-${month}-${day}`;
    };

    const date = new Date();
    const formattedDate = formatDate(date);

    endorsementQA.status = "On going";
    endorsementQA.statusQA = "Pending";
    endorsementQA.dateTimeSubmitted = timeString;
    endorsementQA.agentSignature = agentUser.signature;
    if (qaUsers.some((qa) => qa.id === findEndorsementQa[0].createdBy)) {
      console.log("hey");
    } else {
      endorsementQA.createdBy = userForQA.id;
    }

    // endorsementQA.timeline?.push({
    //   time: formattedDate + " " + endorsementQA.startTime,
    //   status: "For CI",
    // });
    endorsementQA.timeline = endorsementQA?.timeline || [];
    endorsementQA.timeline?.push({
      time: timeString,
      status: "On going",
    });
    userForQA.endorsement = userForQA.endorsement || [];
    userForQA.endorsement.push({
      id: secondPart,
      createdAt: currentDate,
    });

    try {
      // const allImagesNotEmpty = objectAnswer.uploadRelation.every(
      //   (image) => image.image !== ""
      // );

      // if (!allImagesNotEmpty) {
      //   this.view.setState({ imageRequired: true });
      //   return false;
      // }
      const response = await saveObjectUseCase().execute(
        "evr_forms_answer_mis",
        objectAnswer
      );

      endorsementQA.qaFormId = response.id;
      await this.upsertUseCase.execute(collectionEndorsement, endorsementQA);
      // await this.upsertUseCase.execute(collectionUsers, userForQA);
      if (qaUsers.some((qa) => qa.id === findEndorsementQa[0].createdBy)) {
        return;
      } else {
        await this.upsertUseCase.execute(collectionUsers, userForQA);
      }
      this.change = {};
      return true;
    } catch (error) {
      throw error; // rethrow the error to be caught by the caller
    }
  }

  async submit() {
    // if (Object.values(this.change).length === 0) {
    //   this.view.showSuccessSnackbar("Successfully saved!");
    //   return;
    // }
    try {
      this.view.submitting();
      const saveSuccessful = await this.save();
      if (!saveSuccessful) {
        return;
      }
      this.view.submissionSuccess();
      this.view.showSuccessSnackbar("Successfully saved!");
      this.view.navigateBack();
    } catch (error) {
      this.view.submissionError(error);
      this.view.showError(error);
    }
  }
}

export default CreditForm2Presenter;
