import BaseFormPresenter from "../../base/BaseFormPresenter";

class UserFormPresenter extends BaseFormPresenter {
  onChangeObject(object) {
    this.change = object;
    this.object = object;
  }

  async getObject() {
    const collection = this.view.getCollectionName();
    const id = this.object.id || this.view.getObjectId();
    if (id) {
      const params = { include: ["all"] };
      try {
        this.view.showProgress();
        this.object = await this.getObjectUseCase.execute(collection, id, {
          params,
        });
        this.view.hideProgress();
        this.view.setObject(this.object);
      } catch (error) {
        this.view.hideProgress();
        this.view.showError(error);
      }
    }
  }

  async save() {
    const collection = this.view.getCollectionName();
    const objectRegions = this.view.state.selectedRegions;
    const objectProvinces = this.view.state.selectedProvinces;

    this.change.regions = objectRegions;
    this.change.provinces = objectProvinces;

    if (this.object.id) {
      this.change.id = this.object.id;
      // Only update firstName if it is provided
      if (
        this.change.firstName !== undefined &&
        this.change.firstName !== null
      ) {
        this.object.firstName = this.change.firstName;
      } else {
        this.change.firstName = this.object.firstName;
      }

      // Only update lastName if it is provided
      if (this.change.lastName !== undefined && this.change.lastName !== null) {
        this.object.lastName = this.change.lastName;
      } else {
        this.change.lastName = this.object.lastName;
      }

      // this.change.name = `${this.change.firstName} ${
      //   this.change.lastName
      // } ${"-"} ${this.object.branchName}`;

      console.log("BN", this.object.branchName);
      if (
        this.object.branchName === undefined ||
        this.object.branchName === null
      ) {
        this.change.name = `${this.change.firstName} ${this.change.lastName}`;
      } else {
        this.change.name = `${this.change.firstName} ${this.change.lastName} - ${this.object.branchName}`;
      }
    } else {
      this.change.acl = this.view.getAcl();
      // this.change.name =
      //   this.change.firstName +
      //   " " +
      //   this.change.lastName +
      //   " - " +
      //   this.change.branchName;
      if (
        this.change.branchName === undefined ||
        this.change.branchName === null
      ) {
        this.change.name = this.change.firstName + " " + this.change.lastName;
      } else {
        this.change.name =
          this.change.firstName +
          " " +
          this.change.lastName +
          " - " +
          this.change.branchName;
      }

      this.change.rolesName = this.change.roles[0].name;
      this.change.status = "Active";
      // this.change.regions = objectRegions;
      // this.change.provinces = objectProvinces;
    }
    try {
      await this.upsertUseCase.execute(collection, this.change);
      this.change = {};
    } catch (error) {
      throw error; // rethrow the error to be caught by the caller
    }
  }

  async submit() {
    // if (Object.values(this.change).length === 0) {
    //   this.view.showSuccessSnackbar("Successfully saved!");
    //   return;
    // }
    try {
      this.view.submitting();
      await this.save();
      this.view.submissionSuccess();
      this.view.showSuccessSnackbar("Successfully saved!");
      this.view.navigateBack();
    } catch (error) {
      this.view.submissionError(error);
      this.view.showError(error);
    }
  }
}

export default UserFormPresenter;
