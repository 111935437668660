import React, { useState } from "react";

function DateRange({ field, onChange }) {
  const [start, setStart] = useState();
  const [end, setEnd] = useState();

  function _onChange(startDate, endDate) {
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);
    const where = {};
    where[field] = {
      $gte: startDate.toISOString(),
      $lte: endDate.toISOString(),
    };
    onChange(where);
  }

  function _onChange(startDate, endDate) {
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);
    const where = {};
    where[field] = {
      $gte: startDate.toISOString(),
      $lte: endDate.toISOString(),
    };
    onChange(where);
  }

  function _onChangeStart(date) {
    setStart(date.toISOString().slice(0, 10));
    end && _onChange(date, new Date(end));
  }

  function _onChangeEnd(date) {
    setEnd(date.toISOString().slice(0, 10));
    start && _onChange(new Date(start), date);
  }

  React.useEffect(() => {
    const date = new Date();
    // setStart(date.toISOString().slice(0, 10));
    // setEnd(date.toISOString().slice(0, 10));
  }, []);

  return (
    <>
      <div className="d-flex">
        <input
          className="form-control form-control-md shadow-none w-auto rounded-0 border-end-0 rounded-start"
          value={start}
          onInput={(e) => _onChangeStart(e.target.valueAsDate)}
          type="date"
          placeholder="mm/dd/yyyy"
        />
        {/* <input
        className="form-control form-control-sm shadow-none w-auto rounded-0 rounded-end"
        value={end}
        onInput={(e) => _onChangeEnd(e.target.valueAsDate)}
        type="date"
      /> */}
        <input
          className="form-control form-control-md shadow-none w-auto rounded-0 rounded-end"
          value={end}
          onInput={(e) => _onChangeEnd(e.target.valueAsDate)}
          type="date"
        />
      </div>
    </>
  );
}

export default DateRange;

// import React, { useState } from "react";

// function DateRange({ field, onChange }) {
//   const today = new Date().toISOString().slice(0, 10);
//   const [start, setStart] = useState();
//   const [end, setEnd] = useState();

//   function _onChange(startDate, endDate) {
//     startDate.setHours(0, 0, 0, 0);
//     endDate.setHours(23, 59, 59, 999);
//     const where = {};
//     where[field] = {
//       $gte: startDate.toISOString(),
//       $lte: endDate.toISOString(),
//     };
//     onChange(where);
//   }

//   function _onChangeStart(date) {
//     setStart(date.toISOString().slice(0, 10));
//     end && _onChange(date, new Date(end));
//   }

//   function _onChangeEnd(date) {
//     setEnd(date.toISOString().slice(0, 10));
//     start && _onChange(new Date(start), date);
//   }

//   React.useEffect(() => {
//     const date = new Date();
//     // setStart(date.toISOString().slice(0, 10));
//     // setEnd(date.toISOString().slice(0, 10));
//   }, []);

//   return (
//     <>
//       <div className="d-flex">
//         <input
//           className="form-control form-control-md shadow-none w-auto rounded-0 rounded-start border-end-0"
//           value={start}
//           onInput={(e) => _onChangeStart(e.target.valueAsDate)}
//           type="date"
//         />
//         <input
//           className="form-control form-control-md shadow-none w-auto rounded-0 rounded-end"
//           value={end}
//           onInput={(e) => _onChangeEnd(e.target.valueAsDate)}
//           type="date"
//         />
//       </div>
//     </>
//   );
// }

// export default DateRange;

// import React, { useState } from "react";

// function DateRange({ field, onChange }) {
//   const today = new Date().toISOString().slice(0, 10);
//   const [start, setStart] = useState();
//   const [end, setEnd] = useState();

//   function _onChange(startDate, endDate) {
//     startDate.setHours(5, 0, 0, 0); // Set start time to 5:00 AM
//     endDate.setHours(4, 59, 59, 999); // Set end time to 4:59 AM next day
//     const where = {};
//     where[field] = {
//       $gte: new Date(
//         startDate.getTime() - startDate.getTimezoneOffset() * 60000
//       ).toISOString(),
//       $lte: new Date(
//         endDate.getTime() - endDate.getTimezoneOffset() * 60000
//       ).toISOString(),
//     };
//     onChange(where);
//   }

//   function _onChangeStart(date) {
//     setStart(date.toISOString().slice(0, 10));
//     end && _onChange(date, new Date(end));
//   }

//   function _onChangeEnd(date) {
//     setEnd(date.toISOString().slice(0, 10));
//     start && _onChange(new Date(start), date);
//   }

//   React.useEffect(() => {
//     const date = new Date();
//     // setStart(date.toISOString().slice(0, 10));
//     // setEnd(date.toISOString().slice(0, 10));
//   }, []);

//   return (
//     <>
//       <div className="d-flex">
//         <input
//           className="form-control form-control-md shadow-none w-auto rounded-0 rounded-start border-end-0"
//           value={start}
//           onInput={(e) => _onChangeStart(e.target.valueAsDate)}
//           type="date"
//         />
//         <input
//           className="form-control form-control-md shadow-none w-auto rounded-0 rounded-end"
//           value={end}
//           onInput={(e) => _onChangeEnd(e.target.valueAsDate)}
//           type="date"
//         />
//       </div>
//     </>
//   );
// }

// export default DateRange;

// ITO ANG 5 TO 4 59

// import React, { useState } from "react";

// function DateRange({ field, onChange }) {
//   const today = new Date().toISOString().slice(0, 10);
//   const [start, setStart] = useState();
//   const [end, setEnd] = useState();

//   function _onChange(startDate, endDate) {
//     startDate.setHours(0, 0, 0, 0);
//     //     endDate.setHours(23, 59, 59, 999);

//     const where = {};
//     where[field] = {
//       $gte: new Date(
//         startDate.getTime() - startDate.getTimezoneOffset() * 60000
//       ).toISOString(),
//       $lte: new Date(
//         endDate.getTime() - endDate.getTimezoneOffset() * 60000
//       ).toISOString(),
//     };
//     onChange(where);
//   }

//   function _onChangeStart(date) {
//     setStart(date.toISOString().slice(0, 10));
//     end && _onChange(date, new Date(end));
//   }

//   function _onChangeEnd(date) {
//     setEnd(date.toISOString().slice(0, 10));
//     start && _onChange(new Date(start), date);
//   }

//   React.useEffect(() => {
//     const date = new Date();
//     // setStart(date.toISOString().slice(0, 10));
//     // setEnd(date.toISOString().slice(0, 10));
//   }, []);

//   return (
//     <>
//       <div className="d-flex">
//         <input
//           className="form-control form-control-md shadow-none w-auto rounded-0 rounded-start border-end-0"
//           value={start}
//           onInput={(e) => _onChangeStart(e.target.valueAsDate)}
//           type="date"
//         />
//         <input
//           className="form-control form-control-md shadow-none w-auto rounded-0 rounded-end"
//           value={end}
//           onInput={(e) => _onChangeEnd(e.target.valueAsDate)}
//           type="date"
//         />
//       </div>
//     </>
//   );
// }

// export default DateRange;

// import React, { useState } from "react";

// function DateRange({ field, onChange }) {
//   const [start, setStart] = useState();
//   const [end, setEnd] = useState();

//   function _onChange(startDate, endDate) {
//     // Create start of day and end of day in local time
//     const startOfDay = new Date(
//       startDate.getFullYear(),
//       startDate.getMonth(),
//       startDate.getDate(),
//       0,
//       0,
//       0,
//       0
//     );
//     const endOfDay = new Date(
//       endDate.getFullYear(),
//       endDate.getMonth(),
//       endDate.getDate(),
//       23,
//       59,
//       59,
//       999
//     );

//     const where = {};
//     where[field] = {
//       $gte: startOfDay.toISOString(),
//       $lte: endOfDay.toISOString(),
//     };
//     console.log("LALALALA", where);
//     onChange(where);
//   }

//   function _onChangeStart(date) {
//     setStart(date.toISOString().slice(0, 10));
//     if (end) {
//       _onChange(date, new Date(end));
//     }
//   }

//   function _onChangeEnd(date) {
//     setEnd(date.toISOString().slice(0, 10));
//     if (start) {
//       _onChange(new Date(start), date);
//     }
//   }

//   return (
//     <div className="d-flex">
//       <input
//         className="form-control form-control-md shadow-none w-auto rounded-0 rounded-start border-end-0"
//         value={start}
//         onInput={(e) => _onChangeStart(e.target.valueAsDate)}
//         type="date"
//       />
//       <input
//         className="form-control form-control-md shadow-none w-auto rounded-0 rounded-end"
//         value={end}
//         onInput={(e) => _onChangeEnd(e.target.valueAsDate)}
//         type="date"
//       />
//     </div>
//   );
// }

// export default DateRange;
