import React from "react";
// import InputRelation from "../InputRelation";
// import InputString from "../InputString";
// import InputPassword from "../InputPassword";
// import InputNumber from "../InputNumber";
// import InputText from "../InputText";
// import Checkbox from "../Checkbox";
// import InputJson from "../InputJson";
// import InputImage from "../InputImage";
// import InputFile from "../InputFile";
// import InputSignature from "../InputSignature";
// import InputPointer from "../InputPointer";
// import InputSelect from "../InputSelect";
// import InputIcon from "../InputIcon";
import RelatedFactory from "../RelatedFactory";
import InputString from "../InputString";
import InputPassword from "../InputPassword";
import InputNumber from "../InputNumber";
import InputText from "../InputText";
import InputRelation from "../InputRelation";
import InputPointer from "../InputPointer";
import InputImage from "../InputImage";
import InputFile from "../InputFile";
import InputSignature from "../InputSignature";
import { Checkbox } from "nq-component";
import InputJson from "../InputJson";
import InputSelect from "../InputSelect";
import InputIcon from "../InputIcon";

const defaultProps = {
  schemas: [],
};

function InputFactory2({
  type,
  _type,
  field,
  object,
  schemas,
  onChange,
  required,
  _required,
  ...props
}) {
  const value = object && object[field];

  function _onChange(field, value) {
    if (object) {
      object[field] = value;
    }
    onChange(value, field);
  }

  switch (_type || type) {
    case "Email":
    case "String":
      return (
        <InputString
          defaultValue={value}
          onChange={_onChange.bind(this, field)}
          type={type.toLowerCase()}
          required={_required || required}
          {...props}
        />
      );
    case "Date":
      return (
        <InputString
          defaultValue={value && value.slice(0, 10)}
          onChange={_onChange.bind(this, field)}
          type={type.toLowerCase()}
          required={_required || required}
          {...props}
        />
      );
    case "Password":
      return (
        <InputPassword
          onChange={_onChange.bind(this, field)}
          required={_required || required}
          {...props}
        />
      );
    case "Number":
    case "Tel":
      return (
        <InputNumber
          defaultValue={value}
          onChange={_onChange.bind(this, field)}
          required={_required || required}
          {...props}
        />
      );
    case "Text":
      return (
        <InputText
          defaultValue={value}
          onChange={_onChange.bind(this, field)}
          required={_required || required}
          {...props}
        />
      );
    case "Relation":
      return (
        <InputRelation
          field={field}
          defaultValue={value}
          onChange={_onChange.bind(this, field)}
          schema={
            props.schema || schemas.find((s) => s.collection === props.target)
          }
          schemas={schemas}
          required={_required || required}
          {...props}
        />
      );
    case "Pointer":
      const schema =
        props.schema || schemas.find((s) => s.collection === props.target);
      if (!schema) return null;
      return (
        <InputPointer
          defaultValue={value}
          onChange={_onChange.bind(this, field)}
          schema={
            props.schema || schemas.find((s) => s.collection === props.target)
          }
          schemas={schemas}
          required={_required || required}
          {...props}
        />
      );
    case "Related":
      return (
        <RelatedFactory
          defaultValue={value}
          onChange={_onChange.bind(this, field)}
          schema={
            props.schema || schemas.find((s) => s.collection === props.target)
          }
          schemas={schemas}
          field={field}
          required={_required || required}
          {...props}
        />
      );
    case "Image":
      return (
        <InputImage
          value={value}
          onChange={_onChange.bind(this, field)}
          required={_required || required}
          {...props}
        />
      );
    case "File":
      return (
        <InputFile
          value={value}
          onChange={_onChange.bind(this, field)}
          required={_required || required}
          {...props}
        />
      );
    case "InputSignature":
      return (
        <InputSignature
          value={value}
          onChange={_onChange.bind(this, field)}
          required={_required || required}
          {...props}
        />
      );
    case "Boolean":
      return (
        <Checkbox
          checked={value}
          onChange={_onChange.bind(this, field)}
          id={field}
          required={_required || required}
          {...props}
        />
      );
    case "Object":
    case "Array":
      return (
        <InputJson
          defaultValue={value}
          onChange={_onChange.bind(this, field)}
          required={_required || required}
          {...props}
        />
      );
    case "Enum":
      return (
        <InputSelect
          defaultValue={value}
          onChange={_onChange.bind(this, field)}
          type={type.toLowerCase()}
          placeholder={
            props.placeholder ||
            (props.dynamic ? "Select of type " : "Select ") +
              (props.label || field)
          }
          required={_required || required}
          {...props}
        />
      );
    case "Icon":
      return (
        <InputIcon
          defaultValue={value}
          onChange={_onChange.bind(this, field)}
          required={_required || required}
          {...props}
        />
      );
    default:
      return null;
  }
}

InputFactory2.defaultProps = defaultProps;
export default InputFactory2;
