import BaseListPresenter from "../../../base/BaseListPresenter";
import { findObjectUseCase } from "../../../usecases/object";

class DashboardAdminPresenter extends BaseListPresenter {
  init() {
    this.limit = 20;
    this.where = {};
    this.search = {};
    this.filter = {};
    this.include = ["all"];
    this.keys = undefined; // if keys are specified, only those keys will be returned
    this.sort = { createdAt: -1 };
    this.progress = true;
    this.reset();
    this.userAgent = [];
    this.newEndorseLetter = [];
    this.filterDate = {};
    this.filterLocation = "";
  }

  componentDidMount() {
    this.init();
    return this.getObjects();
  }

  createQuery() {
    const skip = (this.current - 1) * this.limit;
    const query = {
      limit: this.limit,
      skip: skip,
      where: {
        ...this.where,
        ...this.search,
        ...this.filter,
        ...this.filterDate,
      },
      include: this.include,
    };
    if (this.sort) {
      query.sort = this.sort;
    }
    const keys = this.keys || this.view.getKeys() || [];
    if (keys.length > 0) {
      query.keys = keys;
    }
    return query;
  }
  async findObjects() {
    const collection = this.view.getCollectionName();
    const user = this.view.getCurrentUser();
    const endorsementIds = user.endorsement?.map((e) => e.id);
    const skip = (this.current - 1) * this.limit;
    const query = this.createQuery();
    const query1 = {
      limit: this.limit,
      skip: skip,
      include: ["location"],
      where: {
        statusQA: "Done",
        id: { $in: endorsementIds },
        ...this.filterDate,
      },
    };

    try {
      this.showProgress();
      this.findObjectUseCase.abort();
      const objects = await this.findObjectUseCase.execute(
        "endorsement",
        query1
      );
      this.objects = this.objects.concat(objects);
      this.view.setTotal(this.objects.length);
      this.view.setObjects(this.objects);
      this.hideProgress();
    } catch (error) {
      this.hideProgress();
      this.view.showError(error);
    }
    this.progress = false;
  }

  onChangeDate(where) {
    this.filterDate = where;
    this.getObjects();
  }

  async calculateActive() {
    const query = {
      include: ["location"],
      where: {
        rolesName: "AGENTS",
        status: "Active",
        location: this.filterLocation
          ? {
              $elemMatch: {
                id: this.filterLocation, // Use the filter location if set
              },
            }
          : undefined,
      },
    };
    const userAgentActive = await findObjectUseCase().execute("users", query);
    this.userAgent = userAgentActive;
  }

  calculateActiveInfo() {
    const { agentActive } = this.calculateActive();

    return {
      value: this.userAgent?.length,
      percentage: 0,
    };
  }

  calculatePendingInfo() {
    return {
      value: this.objects
        ?.filter((o) => o.statusQA === "Pending")
        .map((o) => o.statusQA)?.length,
      percentage: 0,
    };
  }

  async calculateNew() {
    const user = this.view.getCurrentUser();
    const endorsementIds = user.endorsement?.map((e) => e.id);
    const query = {
      where: {
        status: "For Dispatch",
        id: { $in: endorsementIds },
      },
    };
    const newEndorse = await findObjectUseCase().execute("endorsement", query);
    this.newEndorseLetter = newEndorse;
  }

  calculateNewInfo() {
    const { agentActive } = this.calculateNew();
    return {
      value: this.newEndorseLetter?.length,
      percentage: 0,
    };
  }

  filterSubmit(where) {
    const locationId = where.locationP?.id;
    this.reset();
    this.filterLocation = locationId;
    this.getObjects();
  }
}

export default DashboardAdminPresenter;
