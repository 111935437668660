import React, { useState, useEffect } from "react";

const TimeInput = ({ onChange }) => {
  const [time, setTime] = useState({ hour: "12", minute: "00", period: "AM" });

  const handleTimeChange = (event) => {
    const { name, value } = event.target;
    const newTime = { ...time, [name]: value };
    setTime(newTime);
    if (onChange) {
      onChange(newTime); // Call the onChange prop with the new time
    }
  };

  const selectStyle = {
    margin: "0 2px",
    padding: "6px",
    border: "1px solid #ced4da",
    borderRadius: "4px",
    fontSize: "1rem",
    backgroundColor: "#fff",
    appearance: "none",
    WebkitAppearance: "none",
    MozAppearance: "none",
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <select
        name="hour"
        value={time.hour}
        onChange={handleTimeChange}
        style={selectStyle}
      >
        {[...Array(12)].map((_, i) => {
          const hour = (i + 1).toString().padStart(2, "0");
          return (
            <option key={hour} value={hour}>
              {hour}
            </option>
          );
        })}
      </select>
      :
      <select
        name="minute"
        value={time.minute}
        onChange={handleTimeChange}
        style={selectStyle}
      >
        {[...Array(60)].map((_, i) => {
          const minute = i.toString().padStart(2, "0");
          return (
            <option key={minute} value={minute}>
              {minute}
            </option>
          );
        })}
      </select>
      <select
        name="period"
        value={time.period}
        onChange={handleTimeChange}
        style={selectStyle}
      >
        <option value="AM">AM</option>
        <option value="PM">PM</option>
      </select>
    </div>
  );
};

export default TimeInput;
